import React, { useState } from "react";
import { Box } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { auth } from "@/config/firebase";
import { signOut } from "firebase/auth";
interface Props {
  children?: React.ReactNode;
  navigateTo?: string;
}

const Logout = ({ navigateTo = "/zaloguj", children }: Props) => {
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    setDisabled(true);
    signOut(auth)
      .then(() => {
        navigate(navigateTo);
      })
      .catch((error) => {
        console.error(error);
        setDisabled(false);
      });
  };

  return (
    <Box sx={{ width: "100%" }} onClick={logout}>
      {children}
    </Box>
  );
};

export default Logout;
