import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import MenuIcon from "@mui/icons-material/Menu";
import WidthFullIcon from "@mui/icons-material/WidthFull";
import WidthNormalIcon from "@mui/icons-material/WidthNormal";
import WidthWideIcon from "@mui/icons-material/WidthWide";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import AnimationOutlinedIcon from "@mui/icons-material/AnimationOutlined";
import { AccountMenu } from "@/components/account-menu";
import { TotalScoreCounter } from "@/components/total-score-counter";
import { UserSparkLine } from "@/components/user-spark-chart";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useUsersStore } from "@/stores/users-store";
import { AdditionalUIStoreI, AddStoreI, EntityStoreI, UsersStoreI } from "@/types";
import { Route, Link, Routes, useLocation } from "react-router-dom";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
// import CloudIcon from "@mui/icons-material/Cloud";
const drawerWidth = 240;

// interface AppNavProps {
//   onToggleMobileMenu: () => void;
// }

// eslint-disable-next-line
export const MainAppBar = () => {
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const entityState = useEntityStore((state: EntityStoreI) => state);
  const usersState = useUsersStore((state: UsersStoreI) => state);
  const location = useLocation();
  const addState = useAddStore((state: AddStoreI) => state);

  const theme = useTheme();
  const memoedToggleColorMode = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      UiHelpersState.toggleColorMode();
    },
    [UiHelpersState]
  );

  const widthChangleHandler = useCallback(() => UiHelpersState.toggleMaxWidth(), [UiHelpersState]);
  const basicAppSettingIcons = useMemo(() => {
    return (
      <ButtonGroup variant="text" aria-label="outlined primary button group" sx={{ mr: "auto" }}>
        {/* <Tooltip title={UiHelpersState?.reducedMotion ? "Zmniejsz animacje" : "Zwiększ animacje"}>
          <IconButton onClick={memoedReducedMotionToggler} color="inherit">
            {UiHelpersState?.reducedMotion ? (
              <Box
                sx={{
                  width: "32px",
                  height: "32px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  svg: {
                    // fontSize: "1.1rem",
                  },
                }}
              >
                <AnimationOutlinedIcon />
              </Box>
            ) : (
              <Box
                sx={{
                  width: "32px",
                  height: "32px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  svg: {
                    transform: "rotate(90deg)",
                    // fontSize: "1.3rem",
                  },
                }}
              >
                <AutoAwesomeMotionOutlinedIcon />
              </Box>
            )}
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Zmień tryb kolorów">
          <IconButton onClick={memoedToggleColorMode} color="inherit">
            <Box
              sx={{
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
            </Box>
          </IconButton>
        </Tooltip>
        <Tooltip title="Zmień szerokość aplikacji">
          <IconButton
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "flex",
              },
            }}
            onClick={widthChangleHandler}
            color="inherit"
          >
            <Box
              sx={{
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {UiHelpersState.maxWidth === "full" ? (
                <WidthFullIcon />
              ) : UiHelpersState.maxWidth === "normal" ? (
                <WidthNormalIcon />
              ) : UiHelpersState.maxWidth === "wide" ? (
                <WidthWideIcon />
              ) : (
                <WidthNormalIcon />
              )}
            </Box>
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    );
  }, [UiHelpersState.maxWidth, memoedToggleColorMode, theme.palette.mode, widthChangleHandler]);

  const MemoizedTotalScoreCounter = React.useMemo(() => <TotalScoreCounter />, []);

  const openDrawerHandler = useCallback(
    () => UiHelpersState.setMobileMenuOpen(!UiHelpersState.mobileMenuOpen),
    [UiHelpersState]
  );

  const memoizedTopBarContent = useMemo(() => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          borderRadius: 1,
        }}
      >
        {basicAppSettingIcons}
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
        >
          <UserSparkLine />
          {MemoizedTotalScoreCounter}
        </Box>
        <AccountMenu />
      </Box>
    );
  }, [basicAppSettingIcons, MemoizedTotalScoreCounter]);

  return (
    <AppBar
      color="inherit"
      position="fixed"
      elevation={
        !location.pathname.includes("chmura") &&
        !location.pathname.includes("dodaj") &&
        !location.pathname.includes("edytuj")
          ? 2
          : 0
      }
      sx={{
        // backgroundColor: theme.palette.mode === "dark" ? "primary.dark" : "primary.light",
        color: theme.palette.mode === "dark" ? "#fff" : "text.primary",
        zIndex: (theme) => theme.zIndex.drawer + 2,
        width: { lg: `calc(100% - ${drawerWidth}px)` },
        transition: "0s ease-out",
        ml: { lg: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          id="open-drawer"
          aria-label="open drawer"
          edge="start"
          onClick={openDrawerHandler}
          sx={{ ml: 0, display: { lg: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        {memoizedTopBarContent}
      </Toolbar>
    </AppBar>
  );
};
