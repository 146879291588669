import create from "zustand";
import { persist } from "zustand/middleware";
import { User } from "firebase/auth";
import { AdditionalUIStoreI, EntityI, SortingOptionsI, FilteringPerUserOptionsI } from "@/types";
import React from "react";
// import useMediaQuery from "@mui/material/useMediaQuery";
// const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

export const useUiHelpersStore = create<AdditionalUIStoreI>()(
  persist(
    (set) =>
      ({
        editMode: false,
        globalLoading: true,
        setGlobalLoading: (loading: boolean) => set({ globalLoading: loading }),
        reducedMotion: false,
        setReducedMotion: (value: boolean) => set({ reducedMotion: value }),
        setEditMode: (val: boolean) => set(() => ({ editMode: val })),
        // edittedEntity: null,
        // setEdittedEntity: (entity: EntityI | null) => set(() => ({ edittedEntity: entity })),
        addEditDialogOpen: false,
        lastModifiedEntityId: null,
        setLastModifiedEntityId: (id: string | null) => set(() => ({ lastModifiedEntityId: id })),

        currentPaginationPage: 1,
        setCurrentPaginationPage: (page: number) => set(() => ({ currentPaginationPage: page })),
        setAddEditDialogOpen: (val: boolean) => set(() => ({ addEditDialogOpen: val })),
        colorMode: "light",
        entitySorting: "date",
        searchByTitleOnly: false,
        setSearchByTitleOnly: (val: boolean) => set(() => ({ searchByTitleOnly: val })),
        setEntitySorting: (val: SortingOptionsI) => set(() => ({ entitySorting: val })),
        entityFiltering: "all",
        setEntityFiltering: (val: FilteringPerUserOptionsI) =>
          set(() => ({ entityFiltering: val })),
        maxWidth: "wide",
        reachedBottom: false,
        setReachedBottom: (val: boolean) => set(() => ({ reachedBottom: val })),
        mobileMenuOpen: false,
        setMobileMenuOpen: (mobileMenuOpen: boolean) => set(() => ({ mobileMenuOpen })),
        // scoreBoardRef: null,
        // setScoreBoardRef: (scoreBoardRef: any) => set(() => ({ scoreBoardRef })),
        user: null,
        setUser: (user: User) => set(() => ({ user })),
        toggleMaxWidth: () =>
          set((prev) => ({
            maxWidth:
              prev.maxWidth === "full"
                ? "normal"
                : prev.maxWidth === "normal"
                ? "wide"
                : prev.maxWidth === "wide"
                ? "full"
                : "normal",
          })),
        toggleColorMode: (mode?: "light" | "dark") =>
          set((prev) => ({
            colorMode: mode ? mode : prev.colorMode === "dark" ? "light" : "dark",
          })),
        crawlersActive: true,
        definitionsExpanded: true,
        setDefinitionsExpanded: (val: boolean) => set(() => ({ definitionsExpanded: val })),
        toggleCrawlersActive: () => set((prev) => ({ crawlersActive: !prev.crawlersActive })),
      } as AdditionalUIStoreI),
    {
      name: "uiHelpers-storage",
      getStorage: () => localStorage,
    }
  )
);
