import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  GithubAuthProvider,
  connectAuthEmulator,
} from "firebase/auth";

import { getDatabase, onDisconnect, ref, connectDatabaseEmulator } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// import { getFunctions } from "firebase/functions";
import { firebaseConfig } from "./firebase.config";
const LOCAL_EMULATION_ACTIVE = process.env.REACT_APP_FirebaseEmulatorsOn === "true";
// console.log("LOCAL_EMULATION_ACTIVE", LOCAL_EMULATION_ACTIVE);

export const Firebase = initializeApp(firebaseConfig);
export const perf = getPerformance(Firebase);
export const auth = getAuth();
export const analytics = getAnalytics(Firebase);
export const Providers = { google: new GoogleAuthProvider(), github: new GithubAuthProvider() };
export const functions = getFunctions(Firebase);
LOCAL_EMULATION_ACTIVE && connectFunctionsEmulator(functions, "localhost", 5001);
export const db = getDatabase(Firebase);
LOCAL_EMULATION_ACTIVE && connectDatabaseEmulator(db, "localhost", 9000);
LOCAL_EMULATION_ACTIVE && connectAuthEmulator(auth, "http://localhost:9099");
export const entitiesDbRef = ref(db, "entitiesDocs");
export const usersDbRef = ref(db, "users");
export const entitiesDbRefWithId = (id: string) => ref(db, `entitiesDocs/${id}`);
export const usersDbRefWithId = (id: string | undefined) => ref(db, `users/${id}`);
const presenceRef = ref(db, "disconnectmessage");
onDisconnect(presenceRef).set("The connection with the WorldWideWeb has been lost...  😥");
