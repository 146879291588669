import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Helmet } from "react-helmet";
import { AddEdit } from "@/components/add-edit";
import { AddHeaderActions } from "@/components/add-header-actions";
import Box from "@mui/material/Box";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useTagsStore } from "@/stores/tags-store";
import { AdditionalUIStoreI, AddStoreI, EntityStoreI, TagsStoreI } from "@/types";

// import { generateScore } from "@/components/utils/generateWordsScore";
export const Add = () => {
  const entityState = useEntityStore((state: EntityStoreI) => state);
  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);

  // const MemoizedAddHeaderActions = memo(() => {
  //   return <AddHeaderActions />;
  // });

  // };
  // useEffect(() => {
  //   setTimeout(() => {
  //     addState.setParts([]);
  //     UiHelpersState.setEditMode(false);
  //     tagsState.setDetectedTags([]);
  //   }, 100);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // return <Wrapper mainContent={<AddEdit />} componentNav={<MemoizedAddHeaderActions />} />;
  // return <AddEdit />;

  return (
    <>
      <Helmet>
        <title>Wielosłowie | Dodaj</title>
      </Helmet>
      <AddEdit />
    </>
  );
};
