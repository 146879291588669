import {
  AdditionalUIStoreI,
  AddStoreI,
  PointI,
  EntityI,
  TagsStoreI,
  EntityPartI,
  EntityPartObjectI,
  EntityStoreI,
  EntityObject,
  UsersObjI,
  SortingOptionsI,
} from "@/types";
export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

// function that returns black or white color based on inpur color contrast
// export function getContrastYIQ(hexcolor: string) {
//   let r = parseInt(hexcolor.substr(0, 2), 16);
//   let g = parseInt(hexcolor.substr(2, 2), 16);
//   let b = parseInt(hexcolor.substr(4, 2), 16);
//   let yiq = (r * 299 + g * 587 + b * 114) / 1000;
//   return yiq >= 60 ? "black" : "white";
// }

export const idealTextColor = (bgColor: string) => {
  var nThreshold = 105;
  var components = getRGBComponents(bgColor);
  var bgDelta = components.R * 0.299 + components.G * 0.587 + components.B * 0.114;

  return 255 - bgDelta < nThreshold ? "#000000" : "#ffffff";
};

export const getRGBComponents = (color: string) => {
  var r = color.substring(1, 3);
  var g = color.substring(3, 5);
  var b = color.substring(5, 7);

  return {
    R: parseInt(r, 16),
    G: parseInt(g, 16),
    B: parseInt(b, 16),
  };
};

export function stringAvatar(name: string, darkMode: boolean, bigger: boolean = false) {
  const getBgColor = () => {
    let valToReturn = "";
    if (name.includes("Bursztyka")) {
      !darkMode ? (valToReturn = "#e32714") : (valToReturn = "#bf1503");
    } else if (name.includes("Monika D")) {
      !darkMode ? (valToReturn = "#27bb92") : (valToReturn = "#37856f");
    } else {
      valToReturn = stringToColor(name);
    }
    return valToReturn;
  };
  return {
    sx: {
      bgcolor: getBgColor(),
      color: idealTextColor(getBgColor()),
      fontSize: bigger ? "12px" : "9px",
      width: bigger ? 32 : 24,
      height: bigger ? 32 : 24,
      opacity: 0.6,
      fontWeight: bigger ? "bold" : "normal",
    },
    children:
      name?.split(" ")[0] &&
      name?.split(" ")[0][0] &&
      name?.split(" ")[1] &&
      `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`,
  };
}

export const random = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

//function that scales down array of numbers to selected range
export const scaleArray = (arr: number[], min: number, max: number) => {
  const scaledArray = arr.map((num) => {
    return ((num - Math.min(...arr)) * (max - min)) / (Math.max(...arr) - Math.min(...arr)) + min;
  });
  return scaledArray;
};

export const trimAndClean = (str: string) => {
  return (
    str
      .trim()
      .toLowerCase()
      // eslint-disable-next-line
      .replace(/[-'`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, "")
      .replace(/\s+/g, "")
  );
};
export const trimWhiteSpace = (str: string) => {
  return str.trim().toLowerCase().replace(/\s+/g, "");
};

export const stringToArray = (str: string) => {
  let result: string[] = [];
  str = trimWhiteSpace(str).toLowerCase();
  if (str != null) {
    for (let i = 0; i < str?.length ?? 0; i++) {
      const charAtFound = str.charAt(i);
      if (charAtFound !== "," && !!charAtFound) {
        result.push(str.charAt(i));
      }
    }
  }
  result = result.filter(function (str) {
    return /\S/.test(str);
  });

  return result;
};

// return letter points for each letter in word
export const calcPointsFromText = (word: string): number =>
  stringToArray(trimAndClean(word))
    .map((letter) => points[letter] || 0)
    .reduce((partialSum, a) => partialSum + a, 0);

// based on polish scrabble points
export const points: PointI = {
  a: 1,
  ą: 5,
  b: 3,
  c: 2,
  ć: 6,
  d: 2,
  e: 1,
  ę: 5,
  f: 5,
  g: 3,
  h: 3,
  i: 1,
  j: 3,
  k: 2,
  l: 2,
  ł: 3,
  m: 2,
  n: 1,
  ń: 7,
  o: 1,
  ó: 5,
  p: 2,
  r: 1,
  s: 1,
  ś: 5,
  t: 2,
  u: 3,
  v: 1,
  w: 1,
  x: 1,
  y: 2,
  z: 1,
  ż: 5,
  ź: 9,
};

export const hashify = (str: string, seed = 9918092) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return (4294967296 * (2097151 & h2) + (h1 >>> 0)).toString(16);
};

export const sortEntities = (data: EntityI[], sortBy: SortingOptionsI): EntityI[] => {
  let sortedEntitiesArr: EntityI[] = [];
  const dataCache = [...data];
  // sort data by date
  if (sortBy === "date") {
    sortedEntitiesArr = dataCache.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }
  // sort data by score
  if (sortBy === "score") {
    sortedEntitiesArr = dataCache.sort((a, b) => {
      return b.score - a.score;
    });
  }
  //sort data by title
  if (sortBy === "title") {
    sortedEntitiesArr = dataCache.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
  }

  //sort data by description length
  if (sortBy === "desc-length") {
    sortedEntitiesArr = dataCache.sort((a, b) => {
      return (
        ((a.description && a?.description?.length) || 0) -
        ((b.description && b?.description?.length) || 0)
      );
    });
  }

  return sortedEntitiesArr;
};

export const titleWithoutDotOrComma = (str: string) => {
  if (str.endsWith(".") || str.endsWith(",")) {
    return str.slice(0, -1);
  } else {
    return str;
  }
};

export const tagsMultipliers: { [key: string]: number } = {
  // "2-słów": 1,
  // "3-słów": 1,
  // "4-słów": 1,
  // "5-słów": 1,
  // "6-słów": 1.1,
  // "7-słów": 1.2,
  // "8-słów": 1.3,
  // "9-słów": 1.4,
  // "10-słów": 1.5,
  // "zawiera-kiwi": 1.5,
  // "dłuuugi-wielosłów": 1.2,
  // "2-takie-same": 1.2,
  // "3-takie-same": 1.25,
  // "4-takie-same": 1.3,
  // "5-takie-same": 1.4,
  // "6-takie-same": 1.5,
  // "idealne-do-gier": 1.2,
  // "sjp-fail": 0.8,
  "2-słów": 1.2,
  "3-słów": 1.3,
  "4-słów": 1.4,
  "5-słów": 1.5,
  "6-słów": 1.6,
  "7-słów": 1.7,
  "8-słów": 1.8,
  "9-słów": 1.9,
  "10-słów": 4,
  "zawiera-kiwi": 2,
  "dłuuugi-wielosłów": 1.2,
  "2-takie-same": 2,
  "3-takie-same": 3,
  "4-takie-same": 4,
  "5-takie-same": 5,
  "6-takie-same": 6,
  "idealne-do-gier": 1.2,
  "sjp-fail": 0.8,
};

// export const tagsTooltips: { [key: string]: string } = {
//   "2-słów": `Wielosłowie z 2 wyrazów! - mnożnik ${tagsMultipliers["2-słów"]}`,
//   "3-słów": `Wielosłowie z 3 wyrazów! - mnożnik ${tagsMultipliers["3-słów"]}`,
//   "4-słów": `Wielosłowie z 4 wyrazów! - mnożnik ${tagsMultipliers["4-słów"]}`,
//   "5-słów": `Wielosłowie z 5 wyrazów! - mnożnik ${tagsMultipliers["5-słów"]}`,
//   "6-słów": `Wielosłowie z 6 wyrazów! - mnożnik ${tagsMultipliers["6-słów"]}`,
//   "7-słów": `Wielosłowie z 7 wyrazów! - mnożnik ${tagsMultipliers["7-słów"]}`,
//   "8-słów": `Wielosłowie z 8 wyrazów! - mnożnik ${tagsMultipliers["8-słów"]}`,
//   "9-słów": `Wielosłowie z 9 wyrazów! - mnożnik ${tagsMultipliers["9-słów"]}`,
//   "10-słów": `Wielosłowie z 10 wyrazów! - mnożnik ${tagsMultipliers["10-słów"]}`,
//   "zawiera-kiwi": `Z liter wielosłowia da się ułożyć wyraz 'kiwi'!  - mnożnik ${tagsMultipliers["zawiera-kiwi"]} 🦆`,
//   "dłuuugi-wielosłów": `Powyżej 25 znaków?? - mnożnik ${tagsMultipliers["dłuuugi-wielosłów"]}`,
//   "2-takie-same": `2 takie same słowa, a znaczenia inne. Szacunek oraz i mnożnik ${tagsMultipliers["2-takie-same"]}`,
//   "3-takie-same": `3 takie słowa w jednym wielosłowie! Niesamowite! - mnożnik ${tagsMultipliers["3-takie-same"]}`,
//   "4-takie-same": `4 takie same wyrazy o innych znaczeniach? Wypas! - mnożnik ${tagsMultipliers["4-takie-same"]}`,
//   "5-takie-same": `5 takich samych wyrazów z odmiennymi znaczeniami! Brawo! - mnożnik ${tagsMultipliers["5-takie-same"]}`,
//   "6-takie-same": `6 takich samych wyrazów z rzędy! WOW! - mnożnik ${tagsMultipliers["6-takie-same"]}`,
//   "idealne-do-gier": `Wszystkie słowa dopuszczalne w grach! - mnożnik ${tagsMultipliers["idealne-do-gier"]}`,
//   "sjp-fail": `Conajmniej jedna część wielosłowia zawiera wyraz niedopuszczalny w grach przez sjp.pl. Wynik będzie obniżony. - mnożnik ${tagsMultipliers["sjp-fail"]}`,
// };
export const tagsTooltips: { [key: string]: string } = {
  "2-słów": `Wielosłowie z 2 wyrazów!`,
  "3-słów": `Wielosłowie z 3 wyrazów!`,
  "4-słów": `Wielosłowie z 4 wyrazów!`,
  "5-słów": `Wielosłowie z 5 wyrazów!`,
  "6-słów": `Wielosłowie z 6 wyrazów!`,
  "7-słów": `Wielosłowie z 7 wyrazów!`,
  "8-słów": `Wielosłowie z 8 wyrazów!`,
  "9-słów": `Wielosłowie z 9 wyrazów!`,
  "10-słów": `Wielosłowie z 10 wyrazów!`,
  "zawiera-kiwi": `Z liter wielosłowia da się ułożyć wyraz 'kiwi'!`,
  "dłuuugi-wielosłów": `Powyżej 25 znaków??`,
  "2-takie-same": `2 takie same słowa, a znaczenia inne. Szacunek!`,
  "3-takie-same": `3 takie słowa w jednym wielosłowie! Niesamowite!`,
  "4-takie-same": `4 takie same wyrazy o innych znaczeniach? Wypas!`,
  "5-takie-same": `5 takich samych wyrazów z odmiennymi znaczeniami!`,
  "6-takie-same": `6 takich samych wyrazów z rzędy! WOW!`,
  "idealne-do-gier": `Wszystkie słowa dopuszczalne w grach!`,
  "sjp-fail": `Conajmniej jedna część wielosłowia zawiera wyraz niedopuszczalny w grach przez sjp.pl. Wynik będzie obniżony.`,
};

export const generateTags = (entitiyTitle: string): string[] => {
  let returnedTags = [""];
  if (entitiyTitle?.length) {
    const title = trimWhiteSpace(entitiyTitle);

    // check if string can be generated from passed string
    const checkIfStringCanBeGenerated = (str: string, phrase: string) => {
      let phraseCopy = phrase;
      for (let i = 0; i < str.length; i++) {
        const charAtFound = str.charAt(i);
        if (phraseCopy.includes(charAtFound)) {
          phraseCopy = phraseCopy.replace(charAtFound, "");
        } else {
          return false;
        }
      }
      return true;
    };

    if (title.length > 0) {
      const titleArr = title
        .split(",")
        .map((x) => trimAndClean(x))
        .filter((x) => x.length > 0);

      if (titleArr.length === 2) {
        returnedTags.push("2-słów");
      }
      if (titleArr.length === 3) {
        returnedTags.push("3-słów");
      }
      if (titleArr.length === 4) {
        returnedTags.push("4-słów");
      }
      if (titleArr.length === 5) {
        returnedTags.push("5-słów");
      }
      if (titleArr.length === 6) {
        returnedTags.push("6-słów");
      }
      if (checkIfStringCanBeGenerated("kiwi", title)) {
        returnedTags.push("zawiera-kiwi");
      }
      if (title.length > 25) {
        returnedTags.push("dłuuugi-wielosłów");
      }

      // function verifies ammoutnt of same strings
      const checkIfSameStrings = (arr: string[]) => {
        const unique = [...new Set(arr)];
        const sameStrings = arr.length - unique.length;
        return sameStrings + 1;
      };

      if (checkIfSameStrings(titleArr) === 6) {
        returnedTags.push("6-takie-same");
      }
      if (checkIfSameStrings(titleArr) === 5) {
        returnedTags.push("5-takie-same");
      }
      if (checkIfSameStrings(titleArr) === 4) {
        returnedTags.push("4-takie-same");
      }
      if (checkIfSameStrings(titleArr) === 3) {
        returnedTags.push("3-takie-same");
      }
      if (checkIfSameStrings(titleArr) === 2) {
        returnedTags.push("2-takie-same");
      }
    }
  }

  return returnedTags.filter((x) => x.length > 0);
};
