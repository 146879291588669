import { hyphenateSync } from "hyphen/pl";
import { ulid } from "ulid";

import { hashify } from "@/components/utils/helpers";
import { EntityPartI } from "@/types";
import { calcPointsFromText, trimAndClean } from "@/components/utils/helpers";

export const CreateNewPart = (singleWord: string): EntityPartI => {
  const hyphenedText = hyphenateSync(singleWord, {
    hyphenChar: "•",
    minWordLength: 1,
  });

  const sanitizedString = trimAndClean(hyphenedText);

  return {
    id: hashify(sanitizedString?.replaceAll("•", "")),
    text: sanitizedString?.replaceAll("•", ""),
    syllables: sanitizedString?.split("•").map((x) => ({
      id: ulid(),
      text: x,
      score: calcPointsFromText(x),
    })),
    score: calcPointsFromText(sanitizedString?.replaceAll("•", "")),
  };
};
