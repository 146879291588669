import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import VerifiedIcon from "@mui/icons-material/Verified";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useFunctionsQuery } from "@react-query-firebase/functions";
import { motion, AnimatePresence } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import CountUp from "react-countup";
import { functions } from "@/config/firebase";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import SourceIcon from "@mui/icons-material/Source";
import Skeleton from "@mui/material/Skeleton";
import LinkIcon from "@mui/icons-material/Link";
import {
  EntityPartI,
  AddStoreI,
  CrawlerResponseI,
  DictCheckerResponseI,
  AdditionalUIStoreI,
  CrawlerReturnObjSectionI,
} from "@/types";
import { trimAndClean, hashify } from "@/components/utils/helpers";
import { ulid } from "ulid";
import { db } from "@/config/firebase";
import { onValue, orderByChild, Query, query, ref } from "firebase/database";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import { IconButtonProps } from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";
import { isBoolean } from "lodash";
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface cardResultI {
  wordSyllabizedSlot?: React.ReactNode;
  definitionsSlot?: React.ReactNode;
  avatarSlot?: React.ReactNode;
  titleSlot?: React.ReactNode;
  descriptionSlot?: React.ReactNode;
}

export const CrawlerResultsRenderer = ({ part }: { part: EntityPartI }) => {
  const theme = useTheme();
  const addState = useAddStore((state: AddStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  // const partCache = { ...part };

  const [pwnDataExists, setPwnDataExists] = useState<boolean>(false);
  const [pwnOpis, setPwnOpis] = useState<CrawlerReturnObjSectionI[] | null>(null);
  // const [pwnRecievedObj, setPwnRecievedObj] = useState<CrawlerResponseI[] | null>(null);

  const [sjpStatusExist, setSjpStatusExists] = useState<boolean | null>(null);

  const [partCache, setPartCache] = useState<EntityPartI>({
    text: "",
  });
  useEffect(() => {
    if (part.id !== partCache.id) {
      setPartCache(part);
    }
  }, [part, partCache?.id]);

  const [dbSjpGamePass, setDbSjpGamePass] = React.useState<DictCheckerResponseI | null | boolean>(
    null
  );
  const [dbPwnDef, setDbPwnDef] = React.useState<CrawlerResponseI | null | boolean>(null);
  const formattedSearchPhrase = trimAndClean(partCache?.text ?? "");
  const hashified = hashify(formattedSearchPhrase);

  useEffect(() => {
    if (formattedSearchPhrase?.length > 0) {
      const dbSjpGamePassQuery: Query = query(ref(db, `fnResults/${hashified}/sjpPresent`));
      onValue(dbSjpGamePassQuery, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          if (data.status === 200) {
            setDbSjpGamePass(data);
            setSjpStatusExists(true);
          } else {
            setDbSjpGamePass(false);
            setSjpStatusExists(false);
          }
        } else {
          setDbSjpGamePass(false);
          setSjpStatusExists(false);
        }
      });

      const dbPwnDefQuery: Query = query(
        ref(db, `fnResults/${hashify(formattedSearchPhrase)}/pwnCrawler`)
      );
      onValue(dbPwnDefQuery, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setDbPwnDef(data);
        } else {
          setDbPwnDef(false);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashified]);

  const sjpResult = useFunctionsQuery<string, DictCheckerResponseI>(
    ["findInSjp", formattedSearchPhrase],
    functions,
    "findInSjp",
    formattedSearchPhrase,
    {
      timeout: 15500,
    },
    {
      // onSettled: (data) => {
      //   // console.log(data, "onSettled");
      // },
      retry: false,
      refetchOnWindowFocus: false,
      enabled: formattedSearchPhrase.length > 0 && dbSjpGamePass === false,
    }
  );

  // useEffect(() => {
  //   console.log("dbSjpGamePass", dbSjpGamePass);
  // }, [dbSjpGamePass]);

  // const indexSjpCrawler = useFunctionsQuery<string, CrawlerResponseI>(
  //   ["indexSjp", formattedSearchPhrase],
  //   functions,
  //   "indexSjp",
  //   formattedSearchPhrase,
  //   {
  //     timeout: 15500,
  //   },
  //   {
  //     retry: false,
  //     refetchOnWindowFocus: false,
  //     enabled: formattedSearchPhrase.length > 0 && UiHelpersState.crawlersActive && 3 > 4,
  //   }
  // );

  const indexPwnCrawler = useFunctionsQuery<string, CrawlerResponseI>(
    ["indexPwn", formattedSearchPhrase],
    functions,
    "indexPwn",
    formattedSearchPhrase,
    {
      timeout: 15500,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled:
        formattedSearchPhrase.length > 0 && UiHelpersState.crawlersActive && dbPwnDef === false,
    }
  );

  useEffect(() => {
    if (sjpResult?.data?.status === 200 || dbSjpGamePass) {
      setSjpStatusExists(true);
    } else {
      setSjpStatusExists(false);
    }
  }, [sjpResult.data, dbSjpGamePass]);
  useEffect(() => {
    // sort by importance
    if (indexPwnCrawler?.data?.status === 200) {
      indexPwnCrawler.data.opis.sort((x, y) => {
        if (x.origin.includes("Słownik języka polskiego PWN")) {
          return 1;
        }
        if (x > y) {
          return -1;
        }
        return 0;
      });

      setPwnOpis(indexPwnCrawler.data.opis);
      setPwnDataExists(true);
    } else if (dbPwnDef && !isBoolean(dbPwnDef) && dbPwnDef?.opis && dbPwnDef?.opis?.length > 0) {
      setPwnOpis(dbPwnDef?.opis);
      setPwnDataExists(true);
    } else {
      setPwnOpis(null);
      setPwnDataExists(false);
    }
  }, [indexPwnCrawler?.data?.opis, dbPwnDef, indexPwnCrawler.data?.status]);

  useEffect(() => {
    if (trimAndClean(partCache.text)?.length > 0) {
      // console.log("asd", addState.parts);
      const updatePartCache = { ...partCache };
      updatePartCache.pwnCrawlerDataPresent = pwnDataExists ?? undefined;
      updatePartCache.sjpDictionaryDataPresent = sjpStatusExist ?? undefined;

      addState.updatePart(updatePartCache);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pwnDataExists, sjpStatusExist]);

  const RecipeReviewCard = ({
    wordSyllabizedSlot,
    definitionsSlot,
    avatarSlot,
    titleSlot,
    descriptionSlot,
  }: cardResultI) => {
    const handleExpandClick = useCallback(() => {
      UiHelpersState.setDefinitionsExpanded(!UiHelpersState.definitionsExpanded);
    }, []);

    const sjpLinkButton = useMemo(
      () => (
        <Box sx={{ ml: "auto" }}>
          {sjpStatusExist !== true ? (
            <Link
              rel="noopener"
              target="_blank"
              sx={{
                display: "flex",
                fontSize: "8px",
                textTransform: "none",
                textDecoration: "none",
              }}
              href={`https://sjp.pl/${encodeURIComponent(formattedSearchPhrase)}`}
            >
              <Button
                size="small"
                color="info"
                startIcon={<LinkIcon />}
                variant="outlined"
                disabled
                sx={{
                  borderColor: "info.light",
                }}
              >
                <Typography variant="inherit" sx={{ fontSize: "80%" }}>
                  SJP
                </Typography>
              </Button>
            </Link>
          ) : (
            <Tooltip title="Kliknij żeby odwiedzić stronę słowa na sjp.pl">
              <Link
                rel="noopener"
                target="_blank"
                sx={{
                  display: "flex",
                  fontSize: "8px",
                  textTransform: "none",
                  textDecoration: "none",
                }}
                href={`https://sjp.pl/${encodeURIComponent(formattedSearchPhrase)}`}
              >
                <Button
                  size="small"
                  color="info"
                  startIcon={<LinkIcon />}
                  variant="outlined"
                  disabled={sjpStatusExist !== true}
                  sx={{
                    borderColor: "info.light",
                  }}
                >
                  <Typography variant="inherit" sx={{ fontSize: "80%" }}>
                    SJP
                  </Typography>
                </Button>
              </Link>
            </Tooltip>
          )}
        </Box>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [formattedSearchPhrase]
    );
    const pwnLinkButton = useMemo(
      () => (
        <Box>
          {pwnDataExists !== true ? (
            <Link
              rel="noopener"
              target="_blank"
              href={`https://sjp.pwn.pl/szukaj/${encodeURIComponent(formattedSearchPhrase)}.html`}
              sx={{
                display: "flex",
                fontSize: "8px",
                textTransform: "none",
                textDecoration: "none",
                padding: 0.5,
              }}
            >
              <Button
                size="small"
                color="info"
                disabled
                startIcon={<LinkIcon />}
                variant="outlined"
                sx={{
                  borderColor: "info.light",
                }}
              >
                <Typography variant="inherit" sx={{ fontSize: "80%" }}>
                  PWN
                </Typography>
              </Button>
            </Link>
          ) : (
            <Tooltip title="Kliknij, aby odwiedzić stronę słowa na sjp.pwn.pl">
              <Link
                rel="noopener"
                target="_blank"
                href={`https://sjp.pwn.pl/szukaj/${encodeURIComponent(formattedSearchPhrase)}.html`}
                sx={{
                  display: "flex",
                  fontSize: "8px",
                  textTransform: "none",
                  textDecoration: "none",
                  padding: 0.5,
                }}
              >
                <Button
                  size="small"
                  color="info"
                  startIcon={<LinkIcon />}
                  variant="outlined"
                  sx={{
                    borderColor: "info.light",
                  }}
                >
                  <Typography variant="inherit" sx={{ fontSize: "80%" }}>
                    PWN
                  </Typography>
                </Button>
              </Link>
            </Tooltip>
          )}
        </Box>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [formattedSearchPhrase]
    );
    return (
      <Paper elevation={2} sx={{ height: "100%" }}>
        <Card
          className="add-edit-card"
          sx={{
            width: "100%",
            height: "100%",
            ".MuiCardContent-root": {
              padding: "0 !important",

              " > .MuiBox-root": {
                py: 2,
                pt: 3,
              },
            },
          }}
        >
          <CardHeader avatar={avatarSlot} title={titleSlot} subheader={descriptionSlot} />

          <CardContent>{wordSyllabizedSlot}</CardContent>

          <CardActions
            disableSpacing
            sx={{
              px: 1,
              py: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "42px",
            }}
          >
            <ExpandMore
              disabled={!pwnDataExists || !UiHelpersState.crawlersActive}
              expand={UiHelpersState.definitionsExpanded}
              onClick={handleExpandClick}
              aria-expanded={UiHelpersState.definitionsExpanded}
              aria-label="show more"
              sx={{ display: "inline-flex", alignItems: "center", margin: 0 }}
            >
              <ExpandMoreIcon />
            </ExpandMore>

            {!sjpResult.isLoading && sjpStatusExist !== null ? (
              sjpLinkButton
            ) : (
              <Box sx={{ ml: "auto" }}>
                <Skeleton variant="rounded" width={"66px"} height={"26px"} />
              </Box>
            )}

            {!indexPwnCrawler.isLoading && pwnDataExists !== null ? (
              pwnLinkButton
            ) : dbPwnDef == null || indexPwnCrawler.isLoading ? (
              <Box sx={{ ml: 1 }}>
                <Skeleton variant="rounded" width={"66px"} height={"26px"} />
              </Box>
            ) : (
              <i />
            )}
          </CardActions>
          <Divider />
          <Collapse
            in={UiHelpersState.definitionsExpanded}
            timeout="auto"
            unmountOnExit
            sx={{ height: "100%", py: pwnOpis ? 2 : 0, backgroundColor: "rgba(0, 0, 0, 0.04)" }}
          >
            {definitionsSlot && <CardContent>{definitionsSlot}</CardContent>}
          </Collapse>
        </Card>
      </Paper>
    );
  };

  const WordSyllabized = useMemo(
    () => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexFlow: "row wrap",
          justifyContent: "center",
        }}
      >
        {partCache?.syllables?.map((x, i) => {
          let wordText: string | undefined = x?.text;
          let wordScore: string | undefined = `${x?.score}`;

          return (
            <Box
              key={`${x.text}-${i}`}
              sx={{
                display: "flex",
                alignItems: "center",
                flexFlow: "column nowrap",
                justifyContent: "space-around",
                px: 0.5,
                fontSize: "24px",
              }}
            >
              {/* <motion.div
                initial={{ scale: 3, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  duration: 0.3,
                  delay: i * 0.2,
                  ease: "backInOut",
                }}
                layout
                style={{ display: "inline" }}
              > */}
              <Typography
                component="span"
                variant="body2"
                sx={{
                  color: "success.main",
                  position: "relative",
                  fontWeight: "500",
                }}
              >
                <Chip
                  size="small"
                  label={!isNaN(Number(wordScore)) ? wordScore : "błąd"}
                  color="success"
                  variant="outlined"
                  sx={{ fontSize: "10px", color: "success.dark" }}
                />

                {i + 1 !== partCache?.syllables?.length ? (
                  <span
                    style={{
                      paddingLeft: "4px",
                      color: "transparent",
                      fontSize: "14px",
                      position: "relative",
                      top: "-2px",
                      display: "inline-block",
                    }}
                  >
                    {"•"}
                  </span>
                ) : (
                  " "
                )}
              </Typography>
              {/* </motion.div> */}
              <Typography
                variant="body2"
                component="span"
                sx={{ fontWeight: 600, position: "relative", fontSize: "100%" }}
              >
                {wordText}
                {i + 1 !== partCache?.syllables?.length ? (
                  <span
                    key={i + 1}
                    style={{
                      paddingLeft: "6px",
                      fontSize: "14px",
                      position: "relative",
                      top: "-2px",
                      left: "2px",
                    }}
                  >
                    {"•"}
                  </span>
                ) : (
                  ""
                )}
              </Typography>
            </Box>
          );
        })}
      </Box>
    ),
    [partCache?.syllables]
  );

  const definitionsDataCalc = (crawlerResponse: CrawlerReturnObjSectionI) => {
    // const TitleRender = ({ title }: { title: string }) => {
    //   return (
    //     <Typography
    //       variant="body1"
    //       sx={{
    //         fontWeight: 600,
    //         fontSize: "12px",
    //         textTransform: "uppercase",
    //         color: "text.secondary",
    //         mb: 1,
    //       }}
    //     >
    //       {title}
    //     </Typography>
    //   );
    // };

    return crawlerResponse.items?.map((x, i) => (
      <Box key={`${x}-${i}`} sx={{ fontSize: "12px", pb: 1 }}>
        <Link
          rel="noopener"
          target="_blank"
          href={x.link}
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "text.primary",
            // textTransform: "uppercase",
          }}
        >
          {x?.title}
          <br />
        </Link>

        {x.definition
          .replaceAll("«", "")
          .split("»")
          .map((h, i) => {
            return (
              <Typography
                variant="body2"
                key={`${h}-${i}`}
                component="span"
                sx={{ display: "block" }}
              >
                {h}
              </Typography>
            );
          })}
      </Box>
    ));
  };

  const wordDefinitions = useMemo(() => {
    if (indexPwnCrawler.isLoading || sjpResult.isLoading) {
      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            py: "1",
            px: "24px",
          }}
        >
          <Skeleton variant="rectangular" width="100%" height="16" sx={{ mb: 1, px: 3 }} />
          <Skeleton variant="rectangular" width="100%" height="48px" sx={{ mb: 1, px: 3 }} />
          <Skeleton variant="rectangular" width="100%" height="16" sx={{ mb: 1, px: 3 }} />
          <Skeleton variant="rectangular" width="100%" height="16" sx={{ mb: 1, px: 3 }} />{" "}
          <Skeleton variant="rectangular" width="100%" height="16" sx={{ mb: 1, px: 3 }} />
          <Skeleton variant="rectangular" width="100%" height="48px" sx={{ mb: 1, px: 3 }} />
          <Skeleton variant="rectangular" width="100%" height="16" sx={{ mb: 1, px: 3 }} />
          <Skeleton variant="rectangular" width="100%" height="16" sx={{ mb: 1, px: 3 }} />{" "}
          <Skeleton variant="rectangular" width="100%" height="16" sx={{ mb: 1, px: 3 }} />
          <Skeleton variant="rectangular" width="100%" height="48px" sx={{ mb: 1, px: 3 }} />
          <Skeleton variant="rectangular" width="100%" height="16" sx={{ mb: 1, px: 3 }} />
          <Skeleton variant="rectangular" width="100%" height="16" sx={{ mb: 1, px: 3 }} />
        </Box>
      );
    }

    return pwnOpis ? (
      <Box
        sx={{
          width: "100%",
          pb: "0 !important",
          pt: "0 !important",
        }}
      >
        {pwnOpis?.map((x, i) => {
          return (
            // <AnimatePresence initial={false}>
            // <motion.div

            //   initial={{ scale: 2, opacity: 0 }}
            //   animate={{ scale: 1, opacity: 1 }}
            //   transition={{
            //     delay: 0.2 * i,
            //     duration: 0.3,
            //     repeat: 0,
            //     ease: "backInOut",
            //   }}
            //   layout
            // >
            <Box
              key={`${x}-${i}`}
              sx={{
                mb: 2,
                borderBottom: i === pwnOpis.length - 1 ? "none" : "1px solid rgba(0,0,0,0.2)",
                pl: 3,
                pr: 3,
                py: 1,
              }}
            >
              <Typography
                variant="body1"
                component="div"
                sx={{ fontWeight: 600, fontSize: "14px", pb: 0, mt: 0, textTransform: "uppercase" }}
              >
                {x.origin}
              </Typography>
              {definitionsDataCalc(x)}
            </Box>
            // {/* </motion.div> */}
            // <AnimatePresence
          );
        })}
      </Box>
    ) : (
      <Box sx={{ px: 3, py: 1 }}>
        <Typography
          variant="body1"
          component="span"
          sx={{
            display: "block",
            fontWeight: 400,
            my: 3,
            fontSize: "16px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Nie znaleziono definicji <strong>PWN</strong>
        </Typography>
      </Box>
    );
  }, [indexPwnCrawler.isLoading, pwnOpis, sjpResult.isLoading]);

  const pointCounter = useMemo(() => {
    return (
      <CountUp
        duration={1}
        // preserveValue={true}
        start={(partCache && partCache["score"]) ?? 0}
        end={(partCache && partCache["score"]) ?? 0}
        style={{
          fontWeight: "700",
          textAlign: "right",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        suffix=" pkt."
        prefix="za to słowo: "
      />
    );
  }, [partCache]);

  if (
    (!sjpResult.isLoading && sjpStatusExist === null) ||
    (pwnDataExists === null && !indexPwnCrawler.isLoading)
    // ||
  ) {
    return (
      // <motion.div
      //   initial={{ scale: 3, opacity: 0 }}
      //   animate={{ scale: 1, opacity: 1 }}
      //   transition={{
      //     duration: 0.3,
      //     delay: 0.3,
      //     ease: "backInOut",
      //   }}
      //   layout
      //   style={{ display: "inline" }}
      // >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          minHeight: "217px",
          backgroundColor:
            UiHelpersState.colorMode === "light" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.8)",
        }}
      >
        <CircularProgress size={24} />
        <Box sx={{ display: "none" }}>
          {sjpStatusExist ? (
            <>
              <Typography
                // className={"dopuszczalne"}
                variant="body2"
                sx={{ fontWeight: 600, fontSize: "12px", textAlign: "right" }}
              >
                Dopuszczalne w grach!
              </Typography>
            </>
          ) : (
            <>
              <Typography
                // className={"nie-dopuszczalne"}
                variant="body2"
                sx={{ fontWeight: 600, fontSize: "12px", textAlign: "right" }}
              >
                Nie jest dopuszczalne w grach!
              </Typography>
            </>
          )}
        </Box>
      </Box>
      // </motion.div>
    );
  }

  // const LoadingStateRenderer = () => {
  //   return (
  //     <motion.div
  //       initial={{ minHeight: "0px", height: "0px", opacity: 0.6 }}
  //       animate={{ minHeight: "217px", height: "217px", opacity: 1 }}
  //       exit={{ minHeight: "0px", height: "0px", opacity: 0 }}
  //       transition={{
  //         duration: 1,
  //         delay: 0,
  //         ease: "backInOut",
  //       }}
  //       layout
  //       style={{ display: "block" }}
  //     >
  //       <Box
  //         sx={{
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           width: "100%",
  //           height: "100%",
  //           backgroundColor:
  //             UiHelpersState.colorMode === "light" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.8)",
  //         }}
  //       >
  //         <CircularProgress size={24} />
  //       </Box>
  //     </motion.div>
  //   );
  // };

  return (
    <Box className={sjpStatusExist ? "dopuszczalne" : "nie-dopuszczalne"}>
      <RecipeReviewCard
        wordSyllabizedSlot={
          <>
            <Divider />
            <Box sx={{ height: "100%" }}>{WordSyllabized}</Box>
            {}
            <Divider />
          </>
        }
        definitionsSlot={wordDefinitions}
        avatarSlot={
          !sjpResult.isLoading && dbSjpGamePass !== null ? (
            <>
              {sjpStatusExist ? (
                <>
                  <Tooltip title="Słowo dopuszczalne w grach!">
                    <VerifiedIcon sx={{ color: "success.main" }} />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Słowo NIE jest dopuszczalne w grach!">
                    <WarningIcon sx={{ color: "warning.main" }} />
                  </Tooltip>
                </>
              )}
            </>
          ) : (
            <Skeleton variant="circular" width={24} height={24} />
          )
        }
        titleSlot={
          <Typography variant="inherit" component="span">
            {pointCounter}
          </Typography>
        }
        descriptionSlot={
          !sjpResult.isLoading && dbSjpGamePass !== null ? (
            <>
              {sjpStatusExist ? (
                <>
                  <Typography
                    // className={"dopuszczalne"}
                    variant="body2"
                    sx={{ fontWeight: 600, fontSize: "12px", textAlign: "right" }}
                  >
                    Dopuszczalne w grach!
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    // className={"nie-dopuszczalne"}
                    variant="body2"
                    sx={{ fontWeight: 600, fontSize: "12px", textAlign: "right" }}
                  >
                    Nie jest dopuszczalne w grach!
                  </Typography>
                </>
              )}
            </>
          ) : (
            <Skeleton variant="rectangular" width={120} height={18} sx={{ ml: "auto" }} />
          )
        }
      />
    </Box>
  );
};
