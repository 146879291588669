import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { memo, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  AdditionalUIStoreI,
  AddStoreI,
  SearchStoreI,
  EntityI,
  EntityStoreI,
  TagsStoreI,
  SortingOptionsI,
} from "@/types";
import { Theme, useTheme } from "@mui/material/styles";
import { useUiHelpersStore } from "@/stores/additional-ui-store";

interface StatHeaderI {
  title: any;
  actions?: any;
  transparent?: boolean;
  reverse?: boolean;
}

export const SectionHeader = ({ title, transparent, actions, reverse }: StatHeaderI) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const theme = useTheme();

  const memoizedContent = useMemo(() => {
    return (
      <Paper
        elevation={2}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: isMobile ? "center" : isTablet ? "center" : "flex-start",
          flexFlow: isMobile
            ? reverse
              ? "column-reverse nowrap"
              : "column nowrap"
            : isTablet
            ? reverse
              ? "column-reverse nowrap"
              : "column nowrap"
            : "row nowrap",
          mb: 4,
          mt: 2,
          p: 2,
          backgroundColor: transparent ? "transparent" : "background.paper",
          border:
            UiHelpersState.colorMode === "light"
              ? `3px solid ${theme.palette.primary.light}`
              : "3px solid #fff",

          // backgroundColor: "#e5e5f7",
          // opacity: 0.2,
          backgroundImage:
            UiHelpersState?.colorMode === "light"
              ? `linear-gradient(135deg, #17a7b30d 25%, transparent 25%), linear-gradient(225deg, #17a7b30d 25%, transparent 25%), linear-gradient(45deg, #17a7b30d 25%, transparent 25%), linear-gradient(315deg,#17a7b30d  25%, #fff 25%)`
              : "inherit",
          backgroundPosition: "10px 0, 10px 0, 0 0, 0 0",
          backgroundSize: "20px 20px",
          backgroundRepeat: "repeat",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textTransform: "uppercase",
            fontSize: isMobile ? "inherit" : "auto",
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {title}
        </Typography>
        {actions ? (
          <Box
            sx={{
              ml: isTablet ? "none" : "auto",
              mt: isTablet ? 2 : 0,
              width: isMobile ? "100%" : "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {actions}
          </Box>
        ) : null}
      </Paper>
    );
  }, [
    UiHelpersState.colorMode,
    actions,
    isMobile,
    isTablet,
    reverse,
    theme.palette.primary.light,
    title,
    transparent,
  ]);
  return memoizedContent;
};
