import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TagIcon from "@mui/icons-material/Tag";
import { Button, CardActions } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { blueGrey } from "@mui/material/colors";
import Paper from "@mui/material/Paper";
import Rating from "@mui/material/Rating";

import { WieloTooltip } from "@/components/wielo-tooltip";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useUsersStore } from "@/stores/users-store";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useTagsStore } from "@/stores/tags-store";

import Skeleton from "@mui/material/Skeleton";
import {
  AdditionalUIStoreI,
  EntityI,
  EntityStoreI,
  TagsStoreI,
  AddStoreI,
  UsersObjI,
  UsersStoreI,
} from "@/types";
import { tagsTooltips } from "@/components/utils/helpers";
import RemoveEntityDialog from "./remove-entity-dialog";
import { scaleArray, stringToColor, stringAvatar } from "@/components/utils/helpers";
import Avatar from "@mui/material/Avatar";
// import Avatar from "react-avatar";

interface CardI {
  entity: EntityI;
}

export const ContentCard = ({ entity }: CardI) => {
  const entityStore = useEntityStore((state: EntityStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);
  let navigate = useNavigate();
  const removeEntity = () => {
    setOpenRemoveEntityDialog(true);
    handleClose();
  };
  const theme = useTheme();
  const [tagsVisible] = React.useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [scaledDownRatingValue, setScaledDownRatingValue] = React.useState<number>(1);

  React.useEffect(() => {
    //@ts-ignore next-line
    if (entityStore.entities?.length > 0) {
      let cachedScaled = scaleArray(
        entityStore.entities.map((entity) => entity.score),
        2,
        5
      );
      const foundUnscaled = entityStore.entities.find((x) => x.id === entity?.id);
      //@ts-ignore next-line
      if (foundUnscaled) {
        setScaledDownRatingValue(cachedScaled[entityStore.entities.indexOf(foundUnscaled)]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityStore.entities]);

  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const usersState = useUsersStore((state: UsersStoreI) => state);

  const [openRemoveEntityDialog, setOpenRemoveEntityDialog] = React.useState(false);

  const editEntityHandler = (entity: EntityI) => {
    navigate(`/edytuj/${entity.id}`);
  };
  const cardRef = React.useRef<HTMLInputElement>(null);

  return (
    <Paper
      elevation={1}
      sx={{
        width: "100%",
        height: "100%",
        background: theme.palette.mode === "dark" ? "primary.main" : "#fff",
        userSelect: "none",
        display: "flex",
        flexFlow: "column nowrap",
        position: "relative",
        // boxShadow: "0px 0px 15px 15px rgba(0,0,0,0.2)",
        // position: "relative",
        zIndex: 2,
        ".MuiTypography-root": {
          zIndex: "2 !important",
        },
      }}
    >
      <Card
        ref={cardRef}
        className="result-card"
        sx={{
          width: "100%",
          height: "100%",
          userSelect: "none",
          display: "flex",
          flexFlow: "column nowrap",
          // boxShadow: "none",

          "> canvas": {
            position: "absolute",
            left: "0",
            top: "0",
            width: "500px",
            height: "230px",
            zIndex: -1,
            opacity: theme.palette.mode === "dark" ? 0.25 : 0.35,
          },
        }}
        // raised={true}
      >
        <CardHeader
          title={
            <Typography
              variant="h6"
              id="entity-title"
              sx={{
                zIndex: 9,
                // background: "#ffffff4d",
                display: "block",
                p: 1,
              }}
            >
              {`„${entity?.title?.toLowerCase()}”`}
            </Typography>
          }
          subheader={
            entity?.description &&
            entity?.description?.length > 0 && (
              <Typography
                variant="subtitle2"
                id="entity-description"
                sx={{
                  visibility: entity?.description?.length ? "visible" : "hidden",
                  // backgroundColor: "#00000032",
                  // color: "#fff",
                  fontWeight: "500",
                  display: "inline-block",

                  p: 1,
                }}
              >
                {entity?.description}
              </Typography>
            )
          }
          // subheader={formatRelative(new Date(entity.createdAt), new Date())}
          sx={{
            flex: 1,
            // backgroundColor: "rgb(207 216 220 / 20%)",

            zIndex: 2,
            alignItems: "flex-start",
            ".MuiCardHeader-action": {
              marginTop: "0px",
              marginBottom: "0px",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              paddingTop: "4px",
            },

            ".MuiCardHeader-content h4": {
              textAlign: "left",
              fontWeight: 500,
            },

            // justifyContent: "flex-start",
            // alignItems: "flex-start",
          }}
        ></CardHeader>
        {/* </CardActionArea> */}
        {/* <CardContent>
        
      </CardContent> */}
        {tagsVisible && (
          <>
            <Divider />
            <CardActions
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Stack
                className="card-tags-container"
                direction="row"
                flexWrap={"wrap"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={2}
                whiteSpace={"nowrap"}
                sx={{
                  width: "100%",
                  p: 0.5,
                  pt: 1,
                  "> .MuiBox-root": {
                    marginLeft: "0 !important",
                  },
                  "> .MuiBox-root:not(:first-of-type)": {
                    marginLeft: "4px",
                  },
                }}
              >
                {entity?.tags &&
                  entity?.tags.map((tag) => {
                    return (
                      <WieloTooltip
                        key={tag}
                        tag={tag}
                        placement="top"
                        children={
                          <Button
                            color="info"
                            size="small"
                            component="button"
                            // href={`/tags/${tag}`}
                            variant="outlined"
                            // onClick={() => {
                            //   tagsState.setTagAsActive(tag);
                            // }}
                            disableRipple={true}
                            sx={{
                              // fontWeight: "400",
                              zIndex: 2,
                              mb: "4px !important",
                              ml: "8px !important",
                              mr: "0px !important",
                              cursor: "help",
                              flexGrow: 0,
                              fontSize: "70%",
                              svg: {
                                fontSize: "120% !important",
                              },
                            }}
                            startIcon={<TagIcon />}
                          >
                            <Typography noWrap={true} variant="inherit" className="single-card-tag">
                              {tag}
                            </Typography>
                          </Button>
                        }
                      />
                    );
                  })}
              </Stack>
            </CardActions>
          </>
        )}

        <>
          <Divider />
          <CardActions
            sx={{
              alignItems: "center",
              px: 2,
              // backgroundColor: "#8d8d8d75",
              justifyContent: "space-between",
            }}
          >
            {/* <AvatarGroup
          max={2}
          sx={{
            position: "relative",
            zIndex: 0,
            left: 0,
            bottom: 0,
            // transform: "rotate(22deg)",
          }}
        >
          {entity.author.map((singleAuthor, i) => (
            <Avatar
              key={singleAuthor + "-" + i}
              {...stringAvatar(
                `${singleAuthor.toUpperCase()} ${singleAuthor.toUpperCase()}`,
                theme.palette.mode === "dark"
              )}
            />
          ))}
        </AvatarGroup> */}
            {/* <Button color="primary" variant="text" size="small">
            <Typography
              variant="h6"
              component="span"
              sx={{
                textTransform: "none",
                color: blueGrey[900],
              }}
            >
              {entity.score} pkt
            </Typography>
          </Button> */}
            {/* scaleBetween */}
            {/* [-4, 0, 5, 6, 9].scaleBetween(0, 100);
             */}
            <Tooltip
              title={`${entity?.score?.toFixed(1)} pkt. - ${scaledDownRatingValue?.toFixed(1)} / 5`}
              placement="top"
            >
              <Box sx={{ display: "flex", zIndex: 99, mr: 1 }}>
                {/* <IconButton aria-label="delete" size="small">
                  <InsightsIcon fontSize="small" sx={{ color: "secondary.dark" }} />
                </IconButton> */}

                <motion.div
                  initial={{ scale: 0.3, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  style={{ transformOrigin: "center" }}
                  // layout
                  transition={{
                    delay: 0.3,
                  }}
                >
                  <Rating
                    name="half-rating-read"
                    id="entity-score"
                    value={Number(scaledDownRatingValue?.toFixed(1))}
                    precision={0.1}
                    readOnly
                    sx={{
                      top: "2px",
                      transition: "600ms",
                      opacity: 0.6,
                      scale: 0.2,
                    }}
                  />
                </motion.div>
              </Box>
            </Tooltip>
            {/* {formatRelative(new Date(entity.createdAt), new Date())} */}
            <Tooltip
              title={`${new Date(entity?.createdAt).toLocaleString("pl")} ${
                entity.editedAt
                  ? ` (edytowany: ${new Date(entity?.editedAt).toLocaleString("pl")})`
                  : ``
              }`}
              placement="top"
            >
              <Box
                sx={{
                  display: "flex",
                  svg: {
                    fontSize: "100%",
                  },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ScheduleIcon sx={{ mr: 0.5 }} />
                <Typography
                  variant="subtitle2"
                  noWrap={true}
                  sx={{
                    cursor: "default",
                    fontWeight: 500,
                    // fontSize: "100%",
                    color: theme.palette.mode === "dark" ? "#fff" : blueGrey[900],
                  }}
                >
                  <ReactTimeAgo date={new Date(entity?.createdAt)} tooltip={false} />
                </Typography>
              </Box>
            </Tooltip>

            {/* <Box
            sx={{
              paddingLeft: "24px",
              height: "auto",
              ".MuiSvgIcon-root": {
                // color: "primary.light",
              },
            }}
          >
            <IconButton
              aria-label="card actions"
              aria-controls={menuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              onClick={handleClick}
              size="small"
              color="secondary"
              sx={{
                // color: blueGrey[900],
                // background: orange[200],
                borderRadius: "50%",

                // "&:hover": {
                //   background: orange[500],
                //   color: blueGrey[900],
                // },
              }}
            >
              <Typography variant="body2" sx={{ display: "flex" }}>
                <MoreVertIcon />
              </Typography>
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleClose}
              onClick={handleClose}
              variant="selectedMenu"
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{ textTransform: "uppercase" }}
            >
              <MenuItem onClick={() => removeEntity()}>
                <Typography variant="inherit" color={"error"} sx={{ textAlign: "center" }}>
                  Usuń
                </Typography>
              </MenuItem>
            </Menu>
          </Box> */}
            {/* <Button size="large" color="info" variant="outlined">
          Details
        </Button> */}
            {/* // details powinno byc nie widoczne, czysta interakcja klik */}
            <RemoveEntityDialog
              entity={entity}
              state={openRemoveEntityDialog}
              onSucess={() => setOpenRemoveEntityDialog(false)}
              onClose={() => setOpenRemoveEntityDialog(false)}
            />
          </CardActions>
        </>
        <>
          <Divider />
          <CardActions
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              px: 2,
              py: 1,
              "svg.MuiSvgIcon-root": {
                fontSize: "100%",
              },
            }}
          >
            <Button
              size="small"
              color="error"
              variant="outlined"
              id="remove-entity"
              startIcon={<RemoveCircleIcon />}
              onClick={() => removeEntity()}
            >
              <Typography variant="inherit" sx={{ fontSize: "80%" }}>
                Usuń
              </Typography>
            </Button>
            <Button
              onClick={() => editEntityHandler(entity)}
              size="small"
              color="success"
              variant="outlined"
              id="edit-entity"
              startIcon={<EditIcon />}
            >
              <Typography variant="inherit" sx={{ fontSize: "80%" }}>
                Edytuj
              </Typography>
            </Button>
            {/* <Button
            size="small"
            color="info"
            variant="outlined"
            onClick={() => alert("Kiwi, jeeeszcze nie...")}
            startIcon={<CenterFocusStrongIcon />}
          >
            <Typography variant="inherit">Szczegóły</Typography>
          </Button> */}

            {entity?.author[0]?.length ? (
              <Tooltip title={usersState.getUserNameById(entity.author[0])} placement="top">
                <Box
                  sx={{
                    ml: "auto",
                    // ".sb-avatar": {
                    //   opacity: "0.52",
                    // },
                    // ".sb-avatar__text > div": {
                    //   fontSize: "8px !important",
                    //   fontFamily: theme.typography.fontFamily,
                    // },
                  }}
                >
                  <Avatar
                    {...stringAvatar(
                      usersState.getUserNameById(entity.author[0]),
                      UiHelpersState.colorMode === "dark"
                    )}
                  />
                  {/* <Avatar size="24" round name={usersState.getUserNameById(entity.author[0])} /> */}
                </Box>
              </Tooltip>
            ) : (
              <Box
                sx={{
                  ml: "auto",
                }}
              >
                <Skeleton variant="circular" width={24} height={24} />
              </Box>
            )}
          </CardActions>
        </>
      </Card>
    </Paper>
  );
};
