import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import { Helmet } from "react-helmet";
import { AddEdit } from "@/components/add-edit";
import { AddHeaderActions } from "@/components/add-header-actions";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useTagsStore } from "@/stores/tags-store";
import { linearGradientDef } from "@nivo/core";
import { useUsersStore } from "@/stores/users-store";
import { ResponsiveFunnel } from "@nivo/funnel";
import Tooltip from "@mui/material/Tooltip";
import { animated } from "@react-spring/web";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  AdditionalUIStoreI,
  AddStoreI,
  EntityI,
  EntityPartI,
  EntityStoreI,
  TagsStoreI,
  PieDatumI,
  UsersStoreI,
} from "@/types";
import GroupIcon from "@mui/icons-material/Group";
import { ResponsiveAreaBump } from "@nivo/bump";
import { ResponsiveLine, Serie, Datum } from "@nivo/line";
import { AxisProps } from "@nivo/axes";
import { ResponsiveTimeRange, CalendarDatum } from "@nivo/calendar";
// import { generateScore } from "@/components/utils/generateWordsScore";
import { useChartHelpers } from "@/components/charts/useChartData";
import { ResponsiveBump, BumpSerie, AreaBumpSerie } from "@nivo/bump";
import { Theme, useTheme } from "@mui/material/styles";
import {
  ResponsiveRadialBar,
  RadialBarTooltipComponent,
  RadialBarTooltipProps,
} from "@nivo/radial-bar";
// import { ResponsiveRadar } from "@nivo/radar";
import { ResponsivePie } from "@nivo/pie";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SectionHeader } from "@/components/section-header";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import IconButton from "@mui/material/IconButton";
export const Stats = () => {
  const {
    entitiesAddedPerDay,
    entitiesTotalPerDay,
    pieDatum,
    tagsRadialDatum,
    entitiesAddedPerWeek,
    entitiesTotalPerWeek,
    entitiesAddedPerMonth,
    entitiesTotalPerMonth,
    // entityCalendarDatumArrFiltered,
    // earliestDate,
    // latestDate,
  } = useChartHelpers();

  const usersState = useUsersStore((state: UsersStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);

  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const entityState = useEntityStore((state: EntityStoreI) => state);

  //   return (
  //     <ResponsiveTimeRange
  //       data={entityCalendarDatumArrFiltered}
  //       from={earliestDate}
  // colors={["#61cdbb", "#37e3d5", "#97e3d5", "#e8c1a0", "#f47560"].reverse()}
  // to={"2022-12-31"}
  // emptyColor="#eeeeee"
  // margin={undefined}
  // dayBorderWidth={2}
  // dayBorderColor="#ffffff"
  // // direction="vertical"
  // // tooltip={function (t) {}}
  // legends={undefined}
  //     />
  //   );
  // };

  // const AreaChart = () => {
  //   const data = [
  //     {
  //       id: "Kiwi",
  //       data: [
  //         {
  //           x: 2000,
  //           y: 23,
  //         },
  //         {
  //           x: 2001,
  //           y: 26,
  //         },
  //         {
  //           x: 2002,
  //           y: 17,
  //         },
  //         {
  //           x: 2003,
  //           y: 23,
  //         },
  //         {
  //           x: 2004,
  //           y: 20,
  //         },
  //         {
  //           x: 2005,
  //           y: 28,
  //         },
  //       ],
  //     },
  //     {
  //       id: "Orzeł",
  //       data: [
  //         {
  //           x: 2000,
  //           y: 27,
  //         },
  //         {
  //           x: 2001,
  //           y: 30,
  //         },
  //         {
  //           x: 2002,
  //           y: 10,
  //         },
  //         {
  //           x: 2003,
  //           y: 26,
  //         },
  //         {
  //           x: 2004,
  //           y: 11,
  //         },
  //         {
  //           x: 2005,
  //           y: 11,
  //         },
  //       ],
  //     },
  //   ];

  //   return (
  //     <ResponsiveAreaBump
  //       data={data}
  //       margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
  //       spacing={8}
  //       colors={{ scheme: "nivo" }}
  //       blendMode="multiply"
  //       defs={[
  //         {
  //           id: "dots",
  //           type: "patternDots",
  //           background: "inherit",
  //           color: "#38bcb2",
  //           size: 4,
  //           padding: 1,
  //           stagger: true,
  //         },
  //         {
  //           id: "lines",
  //           type: "patternLines",
  //           background: "inherit",
  //           color: "#eed312",
  //           rotation: -45,
  //           lineWidth: 6,
  //           spacing: 10,
  //         },
  //       ]}
  //       fill={[
  //         {
  //           match: {
  //             id: "CoffeeScript",
  //           },
  //           id: "dots",
  //         },
  //         {
  //           match: {
  //             id: "TypeScript",
  //           },
  //           id: "lines",
  //         },
  //       ]}
  //       axisTop={{
  //         tickSize: 5,
  //         tickPadding: 5,
  //         tickRotation: 0,
  //         legend: "",
  //         legendPosition: "middle",
  //         legendOffset: -36,
  //       }}
  //       axisBottom={{
  //         tickSize: 5,
  //         tickPadding: 5,
  //         tickRotation: 0,
  //         legend: "",
  //         legendPosition: "middle",
  //         legendOffset: 32,
  //       }}
  //     />
  //   );
  // };
  // const EnititiesAddedOverTimeArea = () => {
  //   // console.log(datumEntityArr);
  //   const data = [
  //     {
  //       id: "dodane",
  //       data: entitiesAddedPerDay,
  //     },
  //     {
  //       id: "wszystkie",
  //       data: entitiesTotalPerDay,
  //     },
  //   ] as AreaBumpSerie<any, any>[];

  //   return (
  //     <ResponsiveAreaBump
  //       data={data}
  //       margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
  //       spacing={8}
  //       colors={{ scheme: "nivo" }}
  //       blendMode="multiply"
  //       defs={[
  //         {
  //           id: "dots",
  //           type: "patternDots",
  //           background: "inherit",
  //           color: "#38bcb2",
  //           size: 4,
  //           padding: 1,
  //           stagger: true,
  //         },
  //         {
  //           id: "lines",
  //           type: "patternLines",
  //           background: "inherit",
  //           color: "#eed312",
  //           rotation: -45,
  //           lineWidth: 6,
  //           spacing: 10,
  //         },
  //       ]}
  //       fill={[
  //         {
  //           match: {
  //             id: "dodane",
  //           },
  //           id: "dodane",
  //         },
  //         {
  //           match: {
  //             id: "wszystkie",
  //           },
  //           id: "wszystkie",
  //         },
  //       ]}
  //       startLabel={"Dodane" as any}
  //       endLabel={"Wszystkie" as any}
  //       axisTop={{
  //         tickSize: 5,
  //         tickPadding: 5,
  //         tickRotation: 0,
  //         legend: "",
  //         legendPosition: "middle",
  //         legendOffset: -36,
  //       }}
  //       axisBottom={{
  //         tickSize: 5,
  //         tickPadding: 5,
  //         tickRotation: 0,
  //         legend: "",
  //         legendPosition: "middle",
  //         legendOffset: 32,
  //       }}
  //     />
  //   );
  // };
  // const EnititiesAddedAndTotalOverTimeBump = () => {
  //   // console.log(datumEntityArr);
  //   const data = [
  //     {
  //       id: "total",
  //       data: entitiesTotalPerDay,
  //     },
  //     {
  //       id: "added",
  //       data: entitiesAddedPerDay,
  //     },
  //   ] as BumpSerie<any, any>[];

  //   console.log(data);
  //   return (
  //     <ResponsiveBump
  //       data={data}
  //       defaultActiveSerieIds={["total"]}
  //       colors={{ scheme: "spectral" }}
  //       lineWidth={3}
  //       activeLineWidth={5}
  //       inactiveLineWidth={3}
  //       inactiveOpacity={0.15}
  //       pointSize={8}
  //       activePointSize={15}
  //       inactivePointSize={0}
  //       pointColor={{ theme: "background" }}
  //       pointBorderWidth={3}
  //       activePointBorderWidth={3}
  //       pointBorderColor={{ from: "serie.color" }}
  //       // axisTop={{
  //       //   tickSize: 5,
  //       //   tickPadding: 5,
  //       //   tickRotation: 0,
  //       //   legend: "",
  //       //   legendPosition: "middle",
  //       //   legendOffset: -36,
  //       // }}

  //       // axisBottom={{
  //       //   tickSize: 5,
  //       //   tickPadding: 5,
  //       //   tickRotation: 0,
  //       //   legend: "added",
  //       //   legendPosition: "middle",
  //       //   legendOffset: 32,
  //       // }}
  //       // axisLeft={{
  //       //   tickSize: 5,
  //       //   tickPadding: 5,
  //       //   tickRotation: 0,
  //       //   legend: "total",
  //       //   legendPosition: "middle",
  //       //   legendOffset: -40,
  //       // }}
  //       margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
  //       axisRight={null}
  //     />
  //   );
  // };

  //calc percentage of number based on value
  const calcPercentage = (value: number, number: number) => {
    return Number((value / number) * 100).toFixed(1);
  };

  const PointsPerUserPie = useMemo(() => {
    return pieDatum ? (
      <ResponsivePie
        data={pieDatum}
        margin={
          isMobile
            ? { top: 40, right: 16, bottom: 32, left: 16 }
            : { top: 40, right: 80, bottom: 32, left: 80 }
        }
        innerRadius={0.2}
        padAngle={2}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        startAngle={-180}
        borderWidth={2}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1]],
        }}
        arcLinkLabel={(d) => `${d.id} - ${d.value} pkt.`}
        enableArcLinkLabels={isTablet ? false : true}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor={UiHelpersState.colorMode === "dark" ? "#fff" : "#000"}
        arcLinkLabelsThickness={3}
        arcLinkLabelsColor={UiHelpersState.colorMode === "dark" ? "#fff" : "#000"}
        arcLabelsSkipAngle={120}
        arcLabelsTextColor={UiHelpersState.colorMode === "dark" ? "#fff" : "#000"}
        arcLabelsComponent={({ datum, label, style }) => (
          <animated.g transform={style.transform as any} style={{ pointerEvents: "none" }}>
            <circle fill={style.textColor} r={25} />
            <circle fill="#ffffff" stroke={datum.color} strokeWidth={5} r={55} />

            <text
              x="-35"
              y="-5"
              textAnchor="center"
              dominantBaseline="text-after-edge"
              alignmentBaseline="baseline"
              fill={theme.palette.tetriary.dark}
              style={{
                fontSize: 13,
                fontWeight: 700,
              }}
            >
              {label}
            </text>

            <text
              x="-25"
              y="20"
              textAnchor="center"
              dominantBaseline="text-after-edge"
              alignmentBaseline="baseline"
              style={{
                fontSize: 12,
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              {datum.value} pkt.
            </text>
          </animated.g>
        )}
        arcLabel={(d) =>
          `${d.id} -
          ${calcPercentage(d.value, Number(pieDatum[0].value + pieDatum[1].value))}%`
        }
        // arcLinkLabelComponent={ArcLinkLabel}
        // arcLabelsComponent={({ datum }) => {
        //   console.log(datum);
        //   return (
        //     <text
        //       style={{
        //         // position: "absolute",
        //         fontSize: "0.8rem",
        //         fontWeight: "bold",
        //         fill: "#fff",
        //       }}
        //     >
        //       {datum.label} ({datum.value})
        //     </text>
        //   );
        // }}
        colors={{ datum: "data.color" }}
        defs={[
          {
            id: "pieDots",
            type: "patternDots",
            background: "rgb(218 132 61 / 59%)",
            color: "rgba(255, 11, 255, 0.3)",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "pieLines",
            type: "patternLines",
            background: "rgb(76 179 149 / 60%)",
            color: "rgba(22, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: pieDatum[0].id,
            },
            id: "pieDots",
          },

          {
            match: {
              id: pieDatum[1].id,
            },
            id: "pieLines",
          },
        ]}
        legends={[
          {
            anchor: "top-left",
            direction: "row",
            justify: false,
            translateX: isMobile ? -10 : -50,
            translateY: -40,
            itemsSpacing: 0,
            itemWidth: 140,
            itemHeight: 18,
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: "circle",

            itemTextColor: UiHelpersState.colorMode === "dark" ? "#fff" : "#000",
            // effects: [
            //   {
            //     on: "hover",
            //     style: {
            //       itemTextColor: "#000",
            //     },
            //   },
            // ],
          },
        ]}
        transitionMode="outerRadius"
        animate={true}
        motionConfig="wobbly"
      />
    ) : (
      <></>
    );
  }, [UiHelpersState.colorMode, isMobile, isTablet, pieDatum, theme.palette.tetriary.dark]);

  const TagsRadial = useMemo(() => {
    const renderRadialBarTooltip: RadialBarTooltipComponent = (
      tooltipProps: RadialBarTooltipProps
    ) => {
      let tooltipToReturn = <></>;

      if (tagsRadialDatum) {
        const { bar } = tooltipProps;
        const remainingDatumIndex = bar.groupId === "Kiwi" ? 0 : 1;
        const currentCategoryIndex = tagsRadialDatum[0].data.findIndex((x) => x.x === bar.category);

        tooltipToReturn = (
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{
              background: "#fff",
              color: "white",
              border: `2px solid ${bar.color}`,
              borderSpacing: 0,
              borderCollapse: "collapse",
              borderRadius: "3px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: bar.color,
                    padding: "4px",
                    width: "auto",
                    border: 0,
                    margin: 0,
                  }}
                >
                  <span>#{bar.category}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    margin: 0,
                    border: "1px solid #eee",
                    fontSize: "14px",
                  }}
                >
                  <span
                    style={{
                      minWidth: "50px",
                      display: "inline-block",
                    }}
                  >
                    {bar.groupId} :
                  </span>
                  <strong>{bar.value}</strong>x
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    margin: 0,
                    border: "1px solid #eee",
                    fontSize: "14px",
                  }}
                >
                  <span
                    style={{
                      minWidth: "50px",
                      display: "inline-block",
                    }}
                  >
                    {tagsRadialDatum[remainingDatumIndex].id} :
                  </span>
                  {""}
                  <span>
                    <strong>
                      {tagsRadialDatum[remainingDatumIndex].data[currentCategoryIndex].y}
                    </strong>
                    x
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        );
      } else {
        tooltipToReturn = <div></div>;
      }

      return tooltipToReturn;
    };

    return tagsRadialDatum && tagsRadialDatum.length > 0 ? (
      <ResponsiveRadialBar
        data={tagsRadialDatum.filter((x) => x.id !== "all-users")}
        valueFormat=">-.0f"
        // maxValue={100}
        // colors={["#38bcb2", "#eed312"]}
        labelsTextColor={UiHelpersState.colorMode === "dark" ? "#fff" : "#000"}
        padding={0.2}
        cornerRadius={0}
        margin={{ top: 32, right: 24, bottom: 32, left: 24 }}
        radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
        circularAxisOuter={isMobile ? {} : { tickSize: 5, tickPadding: 12, tickRotation: 0 }}
        enableTracks={isMobile ? false : true}
        enableLabels={isMobile ? false : true}
        endAngle={335}
        enableCircularGrid={isMobile ? false : true}
        tracksColor={"transparent"}
        borderWidth={3}
        innerRadius={0.3}
        tooltip={renderRadialBarTooltip}
        // labelsRadiusOffset={0.5}
        defs={[
          linearGradientDef("gradientA", [
            { offset: 0, color: "green" },
            { offset: 4, color: "red", opacity: 0 },
          ]),
          linearGradientDef("gradientB", [
            { offset: 0, color: UiHelpersState.colorMode === "dark" ? "#fff" : "#000" },
            { offset: 100, color: "blue" },
          ]),
          {
            id: "radialDots",
            type: "patternDots",
            background: "rgb(218 132 61 / 19%)",
            color: "rgba(255, 255, 44, 0.3)",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "radialLines",
            type: "patternLines",
            background: "rgb(76 179 149 / 10%)",
            color: "rgba(66, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: tagsRadialDatum[0].id,
            },
            id: "gradientA",
          },

          {
            match: {
              id: tagsRadialDatum[1].id,
            },
            id: "gradientB",
          },
        ]}
        legends={
          isTablet
            ? undefined
            : [
                {
                  anchor: "top-left",
                  direction: "column",
                  justify: false,
                  translateX: 0,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  padding: {
                    top: 8,
                    left: 8,
                    bottom: 8,
                    right: 8,
                  },
                  itemWidth: 120,
                  symbolBorderColor: UiHelpersState.colorMode === "dark" ? "#fff" : "#000",

                  itemTextColor: UiHelpersState.colorMode === "dark" ? "#fff" : "#000",
                  itemHeight: 24,
                  symbolSize: 18,
                  symbolShape: "circle",
                  symbolSpacing: 24,
                },
              ]
        }
      />
    ) : (
      <></>
    );
  }, [UiHelpersState.colorMode, isMobile, isTablet, tagsRadialDatum]);

  const [linePerDayVisible, setLinePerDayVisible] = useState(true);
  const [lineTotalVisible, setLineTotalVisible] = useState(true);

  const [totalSummaryChartType, setTotalSummaryChartType] = useState<"days" | "weeks" | "months">(
    "weeks"
  );

  const totalSumamryChartTypeChangeHandler = useCallback((_event: any, newAlignment: string) => {
    if (newAlignment === "days") {
      setTotalSummaryChartType("days");
    } else if (newAlignment === "weeks") {
      setTotalSummaryChartType("weeks");
    } else if (newAlignment === "months") {
      setTotalSummaryChartType("months");
    }
  }, []);

  const TotalSummaryChart = useMemo(() => {
    const addedPerDay: Serie[] = [
      {
        id: "Dodane w tym dniu",
        data: entitiesAddedPerDay ? entitiesAddedPerDay : [],
      },
    ];

    const totalPerDay: Serie[] = [
      {
        id: "Rozmiar wielosłowia",
        data: entitiesTotalPerDay ? entitiesTotalPerDay : [],
      },
    ];

    const addedPerWeek: Serie[] = [
      {
        id: "Dodane w tym tygodniu",
        data: entitiesAddedPerWeek ? entitiesAddedPerWeek : [],
      },
    ];

    const totalPerWeek: Serie[] = [
      {
        id: "Rozmiar wielosłowia",
        data: entitiesTotalPerWeek ? entitiesTotalPerWeek : [],
      },
    ];

    const addedPerMonth: Serie[] = [
      {
        id: "Dodane w tym miesiącu",
        data: entitiesAddedPerMonth ? entitiesAddedPerMonth : [],
      },
    ];

    const totalPerMonth: Serie[] = [
      {
        id: "Rozmiar wielosłowia",
        data: entitiesTotalPerMonth ? entitiesTotalPerMonth : [],
      },
    ];

    const computedData = (): Serie[] => {
      return totalSummaryChartType === "days"
        ? [...addedPerDay, ...totalPerDay]
        : totalSummaryChartType === "weeks"
        ? [...addedPerWeek, ...totalPerWeek]
        : totalSummaryChartType === "months"
        ? [...addedPerMonth, ...totalPerMonth]
        : [];
    };

    // function thats adds one week to the date
    const subtractWeek = (date: Date) => {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() - 7);
      return newDate;
    };

    return entitiesTotalPerDay &&
      entitiesAddedPerDay &&
      entitiesTotalPerDay?.length > 0 &&
      entitiesAddedPerDay?.length > 0 ? (
      <ResponsiveLine
        animate={false}
        motionConfig="wobbly"
        legends={[
          {
            // onClick: (d) => {
            //   if (d.id === "Dodane w tym dniu") {
            //     console.log("Dodane w tym dniu");
            //     setLinePerDayVisible(!linePerDayVisible);
            //   } else if (d.id === "Rozmiar wielosłowia") {
            //     console.log("Rozmiar wielosłowia");
            //     setLineTotalVisible(!lineTotalVisible);
            //   }

            //   // const colors = data.map(series => (series.id === d.id ? '#ff3344' : '#DDD'));
            // },
            anchor: "top-left",
            itemWidth: 140,
            translateX: 0,
            translateY: -32,
            direction: "row",
            justify: false,
            itemsSpacing: 0,
            itemTextColor: UiHelpersState.colorMode === "dark" ? "#fff" : "#000",
            itemDirection: "left-to-right",
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 16,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            // effects: [
            //   {
            //     on: "hover",
            //     style: {
            //       itemBackground: "rgba(0, 0, 0, .03)",
            //       itemOpacity: 1,
            //     },
            //   },
            // ],
          },
        ]}
        data={computedData()}
        margin={
          isMobile
            ? { top: 32, right: 8, bottom: 0, left: 8 }
            : { top: 32, right: 16, bottom: 0, left: 16 }
        }
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        // yFormat=" >-.2f"
        curve="monotoneX"
        axisTop={null}
        axisRight={null}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#ffff0070",
            size: 25,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#f5310470",
            rotation: -45,
            lineWidth: 3,
            spacing: 15,
          },
        ]}
        fill={[
          {
            match: {
              id:
                totalSummaryChartType === "days"
                  ? "Dodane w tym dniu"
                  : totalSummaryChartType === "weeks"
                  ? "Dodane w tym tygodniu"
                  : totalSummaryChartType === "months"
                  ? "Dodane w tym miesiącu"
                  : "",
            },
            id: "dots",
          },

          {
            match: {
              id: "Rozmiar wielosłowia",
            },
            id: "lines",
          },
        ]}
        axisBottom={null}
        axisLeft={null}
        colors={{ scheme: "dark2" }}
        pointSize={
          totalSummaryChartType === "days" ? 5 : totalSummaryChartType === "weeks" ? 8 : 12
        }
        pointColor={{ theme: "background" }}
        pointBorderWidth={
          totalSummaryChartType === "days" ? 2 : totalSummaryChartType === "weeks" ? 5 : 8
        }
        pointBorderColor={{ from: "serieColor" }}
        // pointLabelYOffset={-12}
        enableArea={true}
        areaBlendMode="hard-light"
        useMesh={true}
        enableSlices="x"
        enableCrosshair={true}
        theme={{
          crosshair: {
            line: {
              stroke: UiHelpersState.colorMode === "dark" ? "#fff" : "#000",
              strokeWidth: 2,
              strokeOpacity: 0.75,
              strokeDasharray: "6 6",
            },
          },
        }}
        crosshairType="cross"
        lineWidth={totalSummaryChartType === "days" ? 3 : totalSummaryChartType === "weeks" ? 4 : 5}
        enableGridX={UiHelpersState.colorMode === "dark" ? false : true}
        enableGridY={UiHelpersState.colorMode === "dark" ? false : true}
        // gridXValues={[1, 2, 3, 4, 5]}
        sliceTooltip={({ slice }) => {
          return (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              <div>
                {slice.points.map((point, i) => (
                  <div key={point.id}>
                    {totalSummaryChartType === "weeks" && 5 > 7 ? (
                      <strong
                        style={{
                          color: theme.palette.primary.main,
                          padding: "3px 0",
                        }}
                      >
                        {i === 0 && (
                          <>
                            <span>
                              {new Intl.DateTimeFormat("pl-PL", {
                                weekday: "short",
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                              }).format(subtractWeek(new Date(point.data.xFormatted)))}
                              {" - "}
                            </span>
                            <span>
                              {new Intl.DateTimeFormat("pl-PL", {
                                weekday: "short",
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                              }).format(new Date(point.data.xFormatted))}
                            </span>
                          </>
                        )}
                        {/* Week: {getWeekNumber(new Date(point.data.x))} */}
                      </strong>
                    ) : (
                      <strong
                        style={{
                          color: theme.palette.primary.main,
                          padding: "3px 0",
                        }}
                      >
                        {i === 0 &&
                          new Intl.DateTimeFormat("pl-PL", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }).format(new Date(point.data.xFormatted))}
                        {/* Week: {getWeekNumber(new Date(point.data.x))} */}
                      </strong>
                    )}

                    <div
                      style={{
                        color: point.serieColor,
                        padding: "3px 0",
                      }}
                    >
                      <strong>{point.serieId}:</strong> {Number(point.data.yFormatted).toFixed(0)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        }}
      />
    ) : (
      <i />
    );
  }, [
    UiHelpersState.colorMode,
    entitiesAddedPerDay,
    entitiesAddedPerMonth,
    entitiesAddedPerWeek,
    entitiesTotalPerDay,
    entitiesTotalPerMonth,
    entitiesTotalPerWeek,
    isMobile,
    theme.palette.primary.main,
    totalSummaryChartType,
  ]);

  const [isTotalChartUnleashed, setIsTotalChartUnleashed] = useState(false);

  const handleTotalChartResize = useCallback(() => {
    setIsTotalChartUnleashed(!isTotalChartUnleashed);
  }, [isTotalChartUnleashed]);

  const [tagsDispensationType, setTagsDispensationType] = useState<"per-user" | "all">("per-user");

  const tagsDispensationChangeHandler = useCallback((_event: any, newAlignment: string) => {
    if (newAlignment === "per-user") {
      setTagsDispensationType("per-user");
    } else if (newAlignment === "all") {
      setTagsDispensationType("all");
    }
  }, []);

  const MemoedFunnel = useMemo(() => {
    const dataset = tagsRadialDatum?.filter((d) => d.id === "all-users")[0]?.data || [];

    // sort dataset based on y property
    const sorted = dataset.sort((a, b) => b.y - a.y).filter((d) => d.y > 0);

    const allValuesReduced = sorted.reduce((acc, curr) => acc + curr.y, 0);

    // function with param: number that calculates percentage of allValuesReduced
    const percentage = (value: number) => {
      return ((value / allValuesReduced) * 100).toFixed(1);
    };

    const funnelData = sorted.map((freq) => {
      return {
        id: freq.x,
        label: freq.x,
        value: freq.y,
      };
    });

    return (
      <ResponsiveFunnel
        data={funnelData}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        // valueFormat=">-.3s"
        colors={{ scheme: "dark2" }}
        // valueFormat={(value) => `${value}`}
        borderWidth={20}
        shapeBlending={1}
        spacing={0}
        layers={["separators", "parts", "labels", "annotations"]}
        labelColor={UiHelpersState.colorMode === "dark" ? "white" : "black"}
        // labelColor={UiHelpersState.colorMode === "dark" ? "white" : "black"}
        beforeSeparatorLength={50}
        fillOpacity={0.4}
        beforeSeparatorOffset={20}
        afterSeparatorLength={50}
        afterSeparatorOffset={0}
        currentPartSizeExtension={60}
        currentBorderWidth={30}
        motionConfig="wobbly"
        tooltip={({ part }) => (
          <div
            style={{
              padding: 12,
              color: "#fff",
              background: "#222222",
            }}
          >
            <strong>
              {part.data.id}:{" "}
              <strong style={{ fontWeight: 300, fontSize: "100%" }}>
                {part.formattedValue} razy {" - "}
              </strong>
              <strong style={{ fontWeight: 400, fontSize: "120%" }}>
                {percentage(Number(part.formattedValue))}%
              </strong>
            </strong>
          </div>
        )}
      />
    );
  }, [UiHelpersState.colorMode, tagsRadialDatum]);

  return (
    <>
      <Helmet>
        <title>Wielosłowie | Statystyki</title>
      </Helmet>
      {/* <Box sx={{ width: "100%", height: "400px", display: "flex", mb: 4 }}>
        <AreaChart />
      </Box> */}
      {/* <Box sx={{ width: "100%", height: "400px", display: "flex", mb: 4 }}>
        <EnititiesAddedAndTotalOverTimeBump />
      </Box> */}
      {/* <Box sx={{ width: "100%", height: "400px", display: "flex", mb: 4 }}>
        <EnititiesAddedOverTimeArea />
      </Box> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexFlow: "column nowrap",
          mb: 0,
          pb: 0,
          px: isMobile ? 2 : 3,
          pt: isMobile ? 0 : 1,
        }}
      >
        <SectionHeader
          title={`Rozmiar wielosłowia: ${Object.keys(entityState.entities).length}`}
          actions={
            <>
              <Tooltip
                title={isTotalChartUnleashed ? "Wyłącz pełną szerokosć" : "Włącz pełną szerokość"}
              >
                <IconButton sx={{ mr: 2 }} onClick={handleTotalChartResize}>
                  <Box
                    sx={{
                      ".MuiSvgIcon-root": {
                        transform: "rotate(90deg)",
                        display: "flex",
                      },
                    }}
                  >
                    {isTotalChartUnleashed ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                  </Box>
                </IconButton>
              </Tooltip>
              <ToggleButtonGroup
                color="success"
                value={totalSummaryChartType}
                exclusive
                onChange={totalSumamryChartTypeChangeHandler}
                aria-label="Platform"
                sx={{
                  background:
                    UiHelpersState.colorMode === "dark"
                      ? "primary.dark"
                      : theme.palette.common.white,
                  height: "36.5px",
                  ".MuiToggleButton-root.Mui-selected": {
                    fontWeight: "bold",
                    borderColor: theme.palette.secondary.dark,
                    // color: theme.palette.common.white,
                  },
                }}
              >
                <ToggleButton value="days">{isMobile ? "D" : "Dni"}</ToggleButton>
                <ToggleButton value="weeks">{isMobile ? "T" : "Tygodnie"}</ToggleButton>
                <ToggleButton value="months">{isMobile ? "M" : "Miesiące"}</ToggleButton>
              </ToggleButtonGroup>
            </>
          }
        />
        <Box
          sx={{
            width: "100%",
            overflowX: isTotalChartUnleashed ? "auto" : "hidden",
            overflowY: "hidden",
          }}
        >
          <Box
            className="chart-container"
            sx={{
              width: isTotalChartUnleashed ? "5000px" : "100%",
              height: {
                xl: "500px",
                lg: "500px",
                md: "500px",
                sm: "500px",
                xs: "500px",
              },
              position: "relative",
            }}
          >
            {TotalSummaryChart}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexFlow: "column nowrap",
          mb: 0,
          pb: 0,
          px: 3,
        }}
      >
        <SectionHeader title="Punkty użytkowników" />
        <Box
          className="chart-container"
          sx={{
            width: "100%",
            height: "500px",
            mb: 4,
            position: "relative",
          }}
        >
          {PointsPerUserPie}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexFlow: "column nowrap",
          mb: 0,
          pb: 0,
          px: 3,
        }}
      >
        <SectionHeader
          title="Rozkład tagów"
          actions={
            <ToggleButtonGroup
              color="success"
              value={tagsDispensationType}
              exclusive
              onChange={tagsDispensationChangeHandler}
              aria-label="Platform"
              sx={{
                height: "36.5px",
                ".MuiToggleButton-root.Mui-selected": {
                  fontWeight: "bold",
                  borderColor: theme.palette.secondary.dark,
                  // color: theme.palette.common.white,
                },
              }}
            >
              <ToggleButton value="per-user">
                {isMobile ? <GroupIcon /> : "Tagi użytkowników"}
              </ToggleButton>
              <ToggleButton value="all">
                {isMobile ? <FilterAltIcon /> : "Wszystkie tagi"}
              </ToggleButton>
            </ToggleButtonGroup>
          }
        />
        <Box
          className="chart-container"
          sx={{
            width: "100%",
            height: "600px",
            mb: 8,
          }}
        >
          {tagsDispensationType === "per-user" ? TagsRadial : MemoedFunnel}
        </Box>
      </Box>
      {/* <Box sx={{ width: "100%", height: "400px", display: "flex", mb: 4, p: 4 }}>
        <TimeLineChart />
      </Box> */}
    </>
  );
};
