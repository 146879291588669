import "tippy.js/animations/scale.css";
import "tippy.js/dist/tippy.css";

import { Helmet } from "react-helmet";
import WindPowerIcon from "@mui/icons-material/WindPower";
import { debounce } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import { Theme, useTheme } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ReactWordcloud, { Word } from "react-wordcloud";
import { CloudAlt } from "@/components/CloudAlt";
import { useNotifications } from "@/components/utils/useNotifications";
import Wrapper from "@/screens/Wrapper";
import { useEntityStore } from "@/stores/entities-store";
import { EntityPartI, EntityStoreI, AdditionalUIStoreI } from "@/types";
import { useChartHelpers } from "@/components/charts/useChartData";
import chroma from "chroma-js";

import { useUiHelpersStore } from "@/stores/additional-ui-store";
import useMediaQuery from "@mui/material/useMediaQuery";
export const WordCloud = () => {
  const [trigger, setTrigger] = useState(false);
  const toggleTrigger = useCallback(() => setTrigger(!trigger), [trigger]);
  const { cloudWords, cloudSyllables } = useChartHelpers();
  const theme = useTheme();
  const debouncedToggleTrigger = debounce(toggleTrigger, 1500);
  const debouncedToggleTriggerMounted = debounce(toggleTrigger, 300);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { info } = useNotifications();

  const [wordType, setWordType] = useState<"words" | "syllables">("words");
  const [sliderValues, setSliderValues] = useState<number[]>([14, 180]);

  const [words, setWords] = useState<Word[]>([]);

  const sliderChangeHandler = useCallback(
    (_event: Event, newValue: number | number[], activeThumb: number) => {
      newValue = newValue as number[];

      if (activeThumb === 0) {
        setSliderValues([newValue[0], sliderValues[1]]);
      } else {
        setSliderValues([sliderValues[0], newValue[1]]);
      }

      debouncedToggleTrigger();
    },
    [debouncedToggleTrigger, sliderValues]
  );

  const dmuchnijCallback = useCallback(() => {
    toggleTrigger();
  }, [toggleTrigger]);

  useEffect(() => {
    calcNewWordCloud();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (cloudWords && cloudWords?.length > 0) {
      debouncedToggleTriggerMounted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudWords]);

  const HeaderActions = useMemo(() => {
    const handleToggleChangeHandler = (event: any, newAlignment: string) => {
      if (newAlignment === "words") {
        setWordType("words");
      } else if (newAlignment === "syllables") {
        setWordType("syllables");
      }
      toggleTrigger();
    };

    const sliderMarks = [
      {
        value: 10,
        label: "10px",
      },
      {
        value: 180,
        label: "180px",
      },
      {
        value: 200,
        label: "200px",
      },
    ];
    return (
      <Paper
        elevation={4}
        square={true}
        sx={{
          width: { xs: "100%", lg: `calc(100% - 240px)` },
          ml: { lg: "240px" },

          height: "72px",
          position: "absolute",
          top: {
            xs: "56px",
            sm: "64px",
          },
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
          left: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
            justifyContent: "space-between",
            // width: "100%",
            maxWidth:
              UiHelpersState.maxWidth === "full"
                ? theme.breakpoints.values.xl + "px"
                : UiHelpersState.maxWidth === "wide"
                ? theme.breakpoints.values.lg + "px"
                : UiHelpersState.maxWidth === "normal"
                ? theme.breakpoints.values.md + "px"
                : theme.breakpoints.values.lg + "px",
            height: "100%",
            position: "relative",
            px: 4,
            py: 0,
            mt: 0,
          }}
        >
          <ToggleButtonGroup
            color="success"
            value={wordType}
            exclusive
            onChange={handleToggleChangeHandler}
            aria-label="Platform"
            sx={{
              height: "36.5px",
              ".MuiToggleButton-root.Mui-selected": {
                fontWeight: "bold",
                borderColor: theme.palette.secondary.dark,
                // color: theme.palette.common.white,
              },
            }}
          >
            <ToggleButton size="medium" value="words">
              Słowa
            </ToggleButton>
            <ToggleButton size="medium" value="syllables">
              Sylaby
            </ToggleButton>
          </ToggleButtonGroup>

          <Stack
            sx={{
              width: "25%",
              display: {
                xs: "none",
                sm: "flex",
                position: "relative",
                top: "-2px",
              },
            }}
          >
            <Slider
              getAriaLabel={() => "Min. / Max. rozmiar"}
              sx={{
                ".MuiSlider-thumb": {
                  zIndex: theme.zIndex.drawer + 2,
                },
              }}
              value={sliderValues}
              defaultValue={[14, 180]}
              disableSwap={false}
              min={10}
              max={200}
              step={1}
              // marks={sliderMarks}
              onChange={sliderChangeHandler}
              valueLabelDisplay="auto"
              valueLabelFormat={(val: number, index: number) =>
                index === 0 ? `Min.: ${val} px` : `Max.: ${val} px`
              }
            />
          </Stack>
          <Button
            variant="outlined"
            startIcon={<WindPowerIcon />}
            color="success"
            size="medium"
            onClick={dmuchnijCallback}
            sx={{
              pl: isMobile ? 3 : "1",
            }}
          >
            {isMobile ? "" : "Dmuchnij!"}
          </Button>
        </Box>
      </Paper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    wordType,
    theme.zIndex.drawer,
    dmuchnijCallback,
    toggleTrigger,
    sliderValues,
    UiHelpersState.maxWidth,
  ]);

  const calcNewWordCloud = useCallback(() => {
    if (wordType === "words" && cloudWords && cloudWords?.length > 0) {
      info(`Chmura stworzona za pomocą ${cloudWords.length} słów.`, {
        autoClose: 1200,
      });
      setWords(cloudWords);
    } else if (wordType === "syllables" && cloudSyllables && cloudSyllables?.length > 0) {
      info(`Chmura stworzona za pomocą ${cloudSyllables.length} sylab.`, {
        autoClose: 1200,
      });
      setWords(cloudSyllables);
    }
  }, [wordType, cloudWords, cloudSyllables, info]);

  const memoizedCloud = useMemo(() => {
    // const cloudColorScales = chroma.scale(["#b3b7ff", "#0c14a3"]);

    const iC = chroma.random();
    const cloudColorScales =
      UiHelpersState.colorMode === "dark"
        ? chroma.scale([chroma(iC), chroma(iC).brighten(2.5)])
        : chroma.scale([chroma(iC).brighten(0.4), chroma(iC).darken(3)]);
    return (
      <ReactWordcloud
        // fontWeights={[100, 300, 500, 700, 900]}
        options={{
          transitionDuration: isTablet ? 0 : 300,
          colors: cloudColorScales.colors(15),
          enableOptimizations: true,
          fontSizes: sliderValues as [number, number],
          spiral: "archimedean",
          // fontStyle: "oblique",
          fontFamily: "Lato, Impact",
          fontWeight: "bold",
          textAttributes: {
            // fontFamily: "Impact",
            // fontWeight: "bold",
            // fill: "#3f51b5",
            // stroke: "#3f51b5",
            // strokeWidth: 1,
          },
        }}
        words={words}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [words, wordType, trigger, UiHelpersState.colorMode]);

  return (
    <>
      <Helmet>
        <title>Wielosłowie | Chumra</title>
      </Helmet>
      {HeaderActions}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexFlow: "column nowrap",
          height: "100%",
          width: "100%",
          mt: 9,
          p: 3,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            "g text": {
              fontFamily: '"Lato"',
            },
          }}
        >
          {memoizedCloud}
        </Box>
        {/* <Box
          sx={{
            width: "100%",
            height: "100%",
            mb: 4,
          }}
        >
          <CloudAlt />
        </Box> */}
      </Box>
    </>
  );
  // return <Wrapper mainContent={} componentNav={HeaderActions}></Wrapper>;
};
