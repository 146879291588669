// import "./index.css";
// import "./assets/scss/lato.css";
// import "./assets/scss/ubuntu.css";
import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { register as registerServiceWorker } from "./serviceWorkerRegistration";

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker();
