import React, { useMemo } from "react";
import CountUp from "react-countup";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { SectionHeader } from "./section-header";
import { entitiesDbRef } from "@/config/firebase";
import { useEntityStore } from "@/stores/entities-store";
import { useSearchStore } from "@/stores/search-store";
import { useTagsStore } from "@/stores/tags-store";
import {
  AdditionalUIStoreI,
  EntityI,
  EntityStoreI,
  SearchStoreI,
  UsersStoreI,
  FilteringPerUserOptionsI,
  TagsStoreI,
} from "@/types";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { Theme, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
export const Header = () => {
  const entityState = useEntityStore((state: EntityStoreI) => state);
  const localBookmarksLength = entityState.entities.length ?? 0;
  const filteredLocalBookmarksLength = entityState?.filteredEntities?.length ?? 0;
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const searchState = useSearchStore((state: SearchStoreI) => state);
  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const smallMobile = useMediaQuery("(max-width:400px)");
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const matchesMobileOrTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  // const [numberSetOnMount, setNumberSetOnMount] = React.useState<number>(0);
  // const memoizedCurrentItemsToDisplay = useMemo(() => {
  //   const currentItemsToDisplay = chunkedEntities[UiHelpersState.currentPaginationPage - 1];
  //   return currentItemsToDisplay;
  // }, [chunkedEntities, UiHelpersState.currentPaginationPage]);
  const handleFilteringChange = React.useCallback(
    (event: SelectChangeEvent) => {
      UiHelpersState.setEntityFiltering(event.target.value as FilteringPerUserOptionsI);
      // tagsState.set(sorted);
    },
    [UiHelpersState]
  );

  const resetListToDefaultsHandler = React.useCallback(() => {
    UiHelpersState.setEntityFiltering("all");
    searchState.setSearchValue("");
    // UiHelpersState.setEntitySorting("date");
    UiHelpersState.setEntitySorting("date");
    tagsState.removeAllActiveTags();
    UiHelpersState.setSearchByTitleOnly(false);
    UiHelpersState.setCurrentPaginationPage(1);
  }, [UiHelpersState, searchState, tagsState]);

  const areEntityOptionsNotDefault = React.useCallback(() => {
    const defaultFiltering = UiHelpersState.entityFiltering === "all";
    const defaultSearchValue = searchState.searchValue === "";
    const defaultSorting = UiHelpersState.entitySorting === "date";
    const defaultActiveTags = tagsState.activeTags.length === 0;
    const defautPaginationPage = UiHelpersState.currentPaginationPage === 1;
    const defaultSearchScope = UiHelpersState.searchByTitleOnly === false;
    return (
      defaultFiltering &&
      defaultActiveTags &&
      defaultSearchValue &&
      defaultSearchScope &&
      defaultSorting &&
      defautPaginationPage
    );
  }, [
    UiHelpersState.currentPaginationPage,
    UiHelpersState.entityFiltering,
    UiHelpersState.entitySorting,
    UiHelpersState.searchByTitleOnly,
    searchState.searchValue,
    tagsState.activeTags.length,
  ]);
  const tooltipTitleRenderer = React.useCallback(() => {
    const textToReturn =
      areEntityOptionsNotDefault() === true
        ? "Ustawienia są domyślne"
        : "Przywróć domyślne ustawienia";

    return <>{textToReturn}</>;
  }, [areEntityOptionsNotDefault]);

  const memoizedFiltrerSelect = useMemo(() => {
    return (
      <FormControl
        size="medium"
        variant="outlined"
        sx={{
          width: "100%",
          ".MuiSelect-select": {
            width: "100%",
            minWidth: isMobile ? "auto" : "126px",
            // maxWidth: isMobile ? "50px" : "inherit",
          },
        }}
      >
        <InputLabel id="filtering-select">Źródło</InputLabel>
        <Select
          labelId="filtering-select"
          id="filtering-select"
          value={UiHelpersState.entityFiltering}
          label="Źródło"
          onChange={handleFilteringChange}
          sx={{
            backgroundColor:
              UiHelpersState.colorMode === "light" ? theme.palette.common.white : "transparent",
            // m: 0,
            // pr: 2,
          }}
        >
          <MenuItem value="all">Całe wielosłowie</MenuItem>
          <MenuItem value="user-kiwi">Użykownik: kiwi</MenuItem>
          <MenuItem value="user-orzeł">Użykownik: orzeł</MenuItem>
        </Select>
      </FormControl>
    );
  }, [
    UiHelpersState.colorMode,
    UiHelpersState.entityFiltering,
    handleFilteringChange,
    isMobile,
    theme.palette.common.white,
  ]);

  const memoizedClearButton = useMemo(() => {
    return (
      <Tooltip title={tooltipTitleRenderer()}>
        <FormControl
          size="medium"
          variant="outlined"
          sx={{
            height: "100%",
            mr: 2,
          }}
        >
          <IconButton
            onClick={resetListToDefaultsHandler}
            sx={{
              transition: "all 2s linear",
              width: "52px",
              height: "52px",
              border: areEntityOptionsNotDefault()
                ? UiHelpersState.colorMode === "light"
                  ? "3px solid #eee9"
                  : `3px solid ${theme.palette.secondary.dark}`
                : `3px solid ${theme.palette.error.main}`,
              mr: 4,
              color: areEntityOptionsNotDefault() ? "#eee" : "error.dark",
              "&:hover": {
                color: "error.light",
              },
            }}
            disabled={areEntityOptionsNotDefault()}
          >
            <RestartAltIcon />
          </IconButton>
        </FormControl>
      </Tooltip>
    );
  }, [
    UiHelpersState.colorMode,
    areEntityOptionsNotDefault,
    resetListToDefaultsHandler,
    theme.palette.error.main,
    theme.palette.secondary.dark,
    tooltipTitleRenderer,
  ]);

  const memoizedCounter = useMemo(() => {
    const chunkSize = matchesMobileOrTablet ? 9 : 18;

    return (
      <Box
        sx={{
          width: "100%",
          mt: matchesMobileOrTablet ? 3 : 0,
          height: "100%",
          mr: isMobile ? 0 : matchesMobileOrTablet ? 3 : 0,
          backgroundColor:
            UiHelpersState.colorMode === "light" ? theme.palette.common.white : "transparent",

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CountUp
          duration={1}
          prefix={`widoczne: ${
            UiHelpersState.currentPaginationPage * chunkSize - (chunkSize - 1)
          } - ${
            UiHelpersState.currentPaginationPage * chunkSize >= filteredLocalBookmarksLength
              ? filteredLocalBookmarksLength
              : UiHelpersState.currentPaginationPage * chunkSize
          } z `}
          preserveValue={true}
          // start={filteredLocalBookmarksLength}
          end={filteredLocalBookmarksLength}
          style={{ textTransform: "lowercase" }}
          // delay={1}
          suffix={matchesMobileOrTablet ? "" : " dostępnych"}
        />
      </Box>
    );
  }, [
    UiHelpersState.colorMode,
    UiHelpersState.currentPaginationPage,
    filteredLocalBookmarksLength,
    isMobile,
    matchesMobileOrTablet,
    theme.palette.common.white,
  ]);

  const noDataHeader = React.useMemo(() => {
    return (
      <SectionHeader
        reverse={true}
        title={
          <>
            <CountUp
              duration={0.45}
              preserveValue={false}
              start={localBookmarksLength}
              end={filteredLocalBookmarksLength}
              style={{
                fontSize: "1.5rem",
                backgroundColor:
                  UiHelpersState.colorMode === "light" ? theme.palette.common.white : "transparent",
              }}
              suffix=" widoczne. "
            />
          </>
        }
      />
    );
  }, [
    UiHelpersState.colorMode,
    filteredLocalBookmarksLength,
    localBookmarksLength,
    theme.palette.common.white,
  ]);

  const memoizedHeader = useMemo(() => {
    return Object.keys(entityState.entities).length > 0 || filteredLocalBookmarksLength > 0 ? (
      <SectionHeader
        reverse={true}
        title={memoizedCounter}
        actions={
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              backgroundColor:
                UiHelpersState.colorMode === "light" ? theme.palette.common.white : "transparent",
            }}
          >
            {memoizedClearButton}
            {memoizedFiltrerSelect}
          </Box>
        }
      />
    ) : (
      noDataHeader
    );
  }, [
    UiHelpersState.colorMode,
    entityState.entities,
    filteredLocalBookmarksLength,
    memoizedClearButton,
    memoizedCounter,
    memoizedFiltrerSelect,
    noDataHeader,
    theme.palette.common.white,
  ]);

  return <Box id="list-header">{memoizedHeader}</Box>;
};
