import LogoutIcon from "@mui/icons-material/Logout";
import Person4Icon from "@mui/icons-material/Person4";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import React, { useContext, useMemo } from "react";
// import Avatar from "react-avatar";

import { scaleArray, stringToColor, stringAvatar } from "@/components/utils/helpers";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";

import { useUsersStore } from "@/stores/users-store";
import Logout from "@/components/auth/Logout";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import {
  AdditionalUIStoreI,
  EntityI,
  EntityStoreI,
  TagsStoreI,
  AddStoreI,
  UsersObjI,
  UsersStoreI,
} from "@/types";

// import CloudIcon from "@mui/icons-material/Cloud";
interface WrapperProps {
  children?: JSX.Element;
}

export const AccountMenu = ({ children }: WrapperProps) => {
  // const { user } = useUserData();

  const usersState = useUsersStore((state: UsersStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <Box>
      <Tooltip title="Twoje konto">
        <IconButton
          onClick={handleClick}
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          color="inherit"
        >
          <Avatar
            {...stringAvatar(
              usersState.getUserNameById(UiHelpersState?.user?.uid ?? "USER NAME"),
              UiHelpersState.colorMode === "dark",
              true
            )}
          />
          {/* <Avatar size="32" round name={UiHelpersState?.user?.displayName ?? "D"} /> */}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        // anchorPosition={{ top: 48, left: -140 }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          elevation: 2,
          sx: {
            py: 0.5,
            mr: 8,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem onClick={() => navigate(`/profil/${UiHelpersState.user?.uid}`)}>
          <ListItemIcon>
            <Person4Icon fontSize="small" />
          </ListItemIcon>
          Profil
        </MenuItem>
        <Logout>
          <MenuItem>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Wyloguj
          </MenuItem>
        </Logout>
      </Menu>
    </Box>
  );
};
