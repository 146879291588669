import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ScoreBoard } from "@/components/score-board";
import { AddForm } from "./add-form";
import { useNotifications } from "@/components/utils/useNotifications";
import Wrapper from "@/screens/Wrapper";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useTagsStore } from "@/stores/tags-store";
import { useUsersStore } from "@/stores/users-store";
import {
  generateTags,
  hashify,
  tagsMultipliers,
  trimWhiteSpace,
  titleWithoutDotOrComma,
} from "@/components/utils/helpers";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Route, Link, Routes, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { AddHeaderActions } from "@/components/add-header-actions";
import { Theme } from "@mui/material/styles";
import {
  AdditionalUIStoreI,
  AddStoreI,
  EntityI,
  EntityPartI,
  UsersStoreI,
  EntityStoreI,
  TagsStoreI,
} from "@/types";

export const AddEdit = () => {
  const entityState = useEntityStore((state: EntityStoreI) => state);
  // const { success, error } = useNotifications();
  const location = useLocation();
  const [editedEntityId] = useState<string>(location.pathname.split("/")[2]);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  // const entityState = useEntityStore((state: EntityStoreI) => state);
  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);
  const usersStore = useUsersStore((state: UsersStoreI) => state);
  const scoreBoardRef = React.useRef<HTMLDivElement>(null);

  const debouncedSetDetectedTagsInTagsStore = debounce(addState.setDetectedTagsInTagsStore, 250);

  useEffect(() => {
    if (addState.parts?.length > 0 || (addState?.entityToAddOrEdit && addState.parts?.length > 0)) {
      debouncedSetDetectedTagsInTagsStore();

      // addState.setDetectedTagsInTagsStore();

      const totalScoreFromParts = addState.parts.reduce(
        (partialSum, a) => partialSum + (a?.score ?? 0),
        0
      );

      addState.setTotalScore(Number(totalScoreFromParts.toFixed(2)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addState.parts]);

  useEffect(() => {
    if (addState.title.endsWith(".") || addState.title.endsWith(",")) {
      if (scoreBoardRef && scoreBoardRef.current) {
        const countOfDopuszczalne =
          scoreBoardRef?.current.getElementsByClassName("dopuszczalne").length;
        addState.setDopuszczalneLength(countOfDopuszczalne);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addState.parts]);

  const calcualteTotalPoints = (
    detectedTags: string[],
    tagsMultipliersCalcSheet: { [key: string]: number },
    totalScore: number = 0
  ) => {
    const activeMultipliers = detectedTags.map((tag) => tagsMultipliersCalcSheet[tag]);

    // totalScoreFromParts mutlipied by all active multipliers
    const totalScoreWithMultipliers = activeMultipliers.reduce(
      (partialSum, a) => partialSum * (a ?? 1),
      totalScore || 0
    );

    const endValue = Number(totalScoreWithMultipliers.toFixed(2));

    return endValue;
  };
  useEffect(() => {
    if (addState.parts?.length > 0) {
      const currentTotalScoreWithMultipliers = calcualteTotalPoints(
        tagsState.detectedTags,
        tagsMultipliers,
        addState.totalScore
      );
      addState.setTotalScoreWithMultipliers(currentTotalScoreWithMultipliers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addState.totalScore, tagsState.detectedTags]);

  const triggerWordScoreCalculation = (): EntityI => {
    const entityToAddOrEdit: EntityI = {
      ...addState.entityToAddOrEdit,
      id: editedEntityId ? editedEntityId : hashify(addState.title),
      title: titleWithoutDotOrComma(addState.title),
      // title: "addState.title",
      author:
        Array.isArray(addState?.entityToAddOrEdit?.author) &&
        UiHelpersState.editMode &&
        addState?.entityToAddOrEdit?.author
          ? addState?.entityToAddOrEdit?.author
          : UiHelpersState.user?.uid
          ? [UiHelpersState.user?.uid]
          : [""],

      createdAt:
        UiHelpersState.editMode && addState.entityToAddOrEdit?.createdAt
          ? addState.entityToAddOrEdit?.createdAt
          : new Date().toISOString(),
      score: addState.totalScoreWithMultipliers,
      description: addState.description,
      parts: [...addState.parts],
      tags: tagsState.detectedTags,
    };

    return entityToAddOrEdit;
  };

  return (
    <>
      <AddHeaderActions addOrEditEntityBuilder={triggerWordScoreCalculation} />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "center",
          justifyContent: "flex-start",
          px: {
            xs: 2,
            sm: 4,
          },
          mt: 9,
          p: 3,
          pt: isMobile ? 3 : 4,
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12}>
            <AddForm />
          </Grid>
          <Grid item xs={12} sm={12} ref={scoreBoardRef}>
            <ScoreBoard />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
