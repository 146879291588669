// import "@/App.css";

import { Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { motion, AnimatePresence, LayoutGroup, useScroll } from "framer-motion";
import React, { useEffect, useMemo, useCallback } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet";
import { ContentCard } from "@/components/card";
import { Header } from "@/components/header";
import { SearchBar } from "@/components/search-bar";
import { sortEntities } from "@/components/utils/helpers";
import { entitiesDbRef } from "@/config/firebase";
import Wrapper from "@/screens/Wrapper";
import { useEntityStore } from "@/stores/entities-store";
import { useSearchStore } from "@/stores/search-store";
import { useUsersStore } from "@/stores/users-store";
import { useTagsStore } from "@/stores/tags-store";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import {
  EntityI,
  EntityObject,
  AdditionalUIStoreI,
  EntityStoreI,
  SearchStoreI,
  TagsStoreI,
  UsersStoreI,
} from "@/types";

import { Theme, useTheme } from "@mui/material/styles";
interface CustomIconsPaginationI {
  pages: number;
}
interface ResultsListI {
  items: EntityI[];
}

const MemoizedContentCard = React.memo(ContentCard);

//function that collects all available tags from all bookmarks
const getTags = (entities: EntityI[]) => {
  const tags: string[] = [];
  if (entities?.length > 0) {
    entities.forEach((entity) => {
      if (entity.tags) {
        entity.tags.forEach((tag) => {
          if (!tags.includes(tag)) {
            tags.push(tag);
          }
        });
      }
    });
  }
  return tags;
};

// const LSvalue = JSON.parse(localStorage.getItem("entity-storage") as string)?.state ?? {};

export const WordList = () => {
  // const { user } = useUserData();

  // console.log("scrollYProgress", scrollYProgress);
  const matchesMobileOrTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const UsersState = useUsersStore((state: UsersStoreI) => state);
  const entityState = useEntityStore((state: EntityStoreI) => state);
  const [entitiesFilteredByTags, setEntitiesFilteredByTags] = React.useState<EntityI[]>([]);
  // const [numberSetOnMount, setNumberSetOnMount] = React.useState<number>(Math.random());
  const theme = useTheme();
  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const searchState = useSearchStore((state: SearchStoreI) => state);

  // initial data fetch on component mount and pass the data to the store
  useEffect(() => {
    if (entityState?.entities && entityState?.entities?.length > 0) {
      const cache = [...entityState.entities];
      let returnedEntityBase: EntityI[] = [];
      if (UiHelpersState.entityFiltering === "user-kiwi") {
        returnedEntityBase = cache.filter((entity) => {
          return entity.author && entity.author[0] === UsersState.relevantUsers[0].id;
        });
      } else if (UiHelpersState.entityFiltering === "user-orzeł") {
        returnedEntityBase = cache.filter((entity) => {
          return entity.author && entity.author[0] === UsersState.relevantUsers[1].id;
        });
      } else if (UiHelpersState.entityFiltering === "all") {
        returnedEntityBase = [...cache];
      }

      entityState.setFilteredEntities(returnedEntityBase);
      tagsState.setAvailableTags(getTags(returnedEntityBase));

      setEntitiesFilteredByTags(sortEntities(returnedEntityBase, UiHelpersState.entitySorting));
      // setEntitiesFilteredByTags(entityState.entities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UiHelpersState.entitySorting, UiHelpersState.entityFiltering, entityState.entities]);

  useEffect(() => {
    let filteredBookmarksBase: EntityI[] = [];
    if (entityState.entities?.length > 0) {
      if (tagsState?.activeTags?.length > 0) {
        const values = entityState.entities.filter((entity) => {
          return entity.tags && entity.tags.some((tag) => tagsState.activeTags.includes(tag));
        });
        values.forEach((entity) => {
          filteredBookmarksBase.push(entity);
        });
        filteredBookmarksBase = [...values];
      } else {
        filteredBookmarksBase = [...entityState.entities];
      }
      if (filteredBookmarksBase?.length > 0) {
        if (UiHelpersState.entityFiltering === "user-kiwi") {
          const values = filteredBookmarksBase.filter((entity) => {
            return entity.author && entity.author[0] === UsersState.relevantUsers[0].id;
          });
          values.forEach((entity) => {
            if (!filteredBookmarksBase.includes(entity)) {
              filteredBookmarksBase.push(entity);
            }
          });
          filteredBookmarksBase = [...values];
        } else if (UiHelpersState.entityFiltering === "user-orzeł") {
          const values = filteredBookmarksBase.filter((entity) => {
            return entity.author && entity.author[0] === UsersState.relevantUsers[1].id;
          });
          values.forEach((entity) => {
            if (!filteredBookmarksBase.includes(entity)) {
              filteredBookmarksBase.push(entity);
            }
          });
          filteredBookmarksBase = [...values];
        }
      }
    }

    setEntitiesFilteredByTags(filteredBookmarksBase);
    entityState.setFilteredEntities(filteredBookmarksBase);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UiHelpersState.entityFiltering, UsersState.relevantUsers, tagsState.activeTags]);

  // useEffect(() => {
  //   let filteredBookmarksBase: EntityI[] = [];
  //   if (entityState.entities?.length > 0) {
  //     if (UiHelpersState.entityFiltering === "user-kiwi") {
  //       const values = entityState.entities.filter((entity) => {
  //         return entity.author && entity.author[0] === UsersState.relevantUsers[0].id;
  //       });
  //       values.forEach((entity) => {
  //         filteredBookmarksBase.push(entity);
  //       });
  //     } else if (UiHelpersState.entityFiltering === "user-orzeł") {
  //       const values = entityState.entities.filter((entity) => {
  //         return entity.author && entity.author[0] === UsersState.relevantUsers[1].id;
  //       });
  //       values.forEach((entity) => {
  //         filteredBookmarksBase.push(entity);
  //       });
  //     } else {
  //       filteredBookmarksBase = entityState.entities;
  //     }
  //   }

  //   setEntitiesFilteredByTags(filteredBookmarksBase);
  //   entityState.setFilteredEntities(filteredBookmarksBase);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [UiHelpersState.entityFiltering]);

  // filter bookmarks on search phrase change, based on active tags
  useEffect(() => {
    if (searchState.searchValue?.length > 0) {
      console.log("search start");
      // let searchResult: EntityI[] = [];

      const searchPhrase = searchState.searchValue.toLowerCase();
      const filteredBySearch = entitiesFilteredByTags?.filter((entity: EntityI) =>
        UiHelpersState.searchByTitleOnly
          ? entity?.title?.toLowerCase().includes(searchPhrase)
          : entity?.title?.toLowerCase().includes(searchPhrase) ||
            entity?.description?.toLowerCase().includes(searchPhrase)
      );

      entityState.setFilteredEntities(filteredBySearch);
      UiHelpersState.setCurrentPaginationPage(1);
    } else if (searchState.searchValue?.length === 0) {
      if (entitiesFilteredByTags?.length > 0) {
        entityState.setFilteredEntities(entitiesFilteredByTags);
        UiHelpersState.setCurrentPaginationPage(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchState.searchValue,
    entitiesFilteredByTags,
    tagsState.activeTags,
    UiHelpersState.searchByTitleOnly,
  ]);

  // divide entityState.entities into chunks of 15
  const chunkedEntities: EntityI[][] = useMemo(() => {
    // GRID SIZE !
    const chunkSize = matchesMobileOrTablet ? 9 : 18;
    // GRID SIZE !
    const entities = sortEntities([...entityState?.filteredEntities], UiHelpersState.entitySorting);
    const chunkedEntities = [];
    for (let i = 0; i < entities?.length; i += chunkSize) {
      chunkedEntities.push(entities?.slice(i, i + chunkSize));
    }
    return chunkedEntities;
  }, [UiHelpersState.entitySorting, entityState?.filteredEntities, matchesMobileOrTablet]);

  const bArrow = () => <ArrowBackIcon sx={{ color: theme.palette.secondary.dark }} />;
  const fArrow = () => <ArrowForwardIcon sx={{ color: theme.palette.secondary.dark }} />;

  useEffect(() => {
    return () => {
      UiHelpersState.setLastModifiedEntityId(null);
      UiHelpersState.setReachedBottom(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const { scrollYProgress } = useScroll();
  const paginationChangeHandler = useCallback(
    (e: React.ChangeEvent<unknown>, page: number) => {
      const scrollEl = document.querySelector("#app-scroll-container");
      // scrollEl && scrollEl.scrollTo(0, 0);
      UiHelpersState.setCurrentPaginationPage(page);
      if (scrollEl) {
        setTimeout(() => {
          scrollEl.scrollTo({
            top: scrollEl.scrollHeight,
            left: 0,
            behavior: "instant" as ScrollBehavior,
          });
        }, 0);
      }
    },
    [UiHelpersState]
  );

  const memoizedCurrentItemsToDisplay = useMemo(() => {
    const currentItemsToDisplay = chunkedEntities[UiHelpersState.currentPaginationPage - 1];
    return currentItemsToDisplay;
  }, [chunkedEntities, UiHelpersState.currentPaginationPage]);

  const resultsList = useMemo(() => {
    // const theme = useTheme();
    return (
      <Box
        sx={{
          width: "100%",
          alignItems: "inherit",
          justifyContent: "inherit",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
        }}
        id="content-container"
      >
        <Grid container spacing={2} paddingBottom={6}>
          {/* <AnimatePresence initial={false}> */}
          {/* <AnimatePresence> */}
          {/* transition={
                          isMobile
                            ? {
                                duration: 0,
                                ease: "linear",
                              }
                            : { type: "spring", stiffness: 50 }
                        } */}
          {memoizedCurrentItemsToDisplay?.length > 0 &&
            memoizedCurrentItemsToDisplay?.map(
              (entity: EntityI | undefined, i: number) =>
                entity && (
                  <Grid
                    className={`result-card-container ${
                      UiHelpersState.lastModifiedEntityId === entity.id && "last-modified"
                    }`}
                    key={`${entity.id}`}
                    item
                    xs={12}
                    sm={12}
                    lg={6}
                    xl={4}
                    sx={
                      {
                        // "&.last-modified": {
                        //   border: "1px solid #17a7b3",
                        //   borderRadius: "5px",
                        //   boxShadow: "0 0 0 2px #17a7b3",
                        // },
                      }
                    }
                  >
                    {/* <motion.div
                      initial={{ translateX: -50, translateY: -10, scale: 0.74, opacity: 0 }}
                      animate={{ translateX: 0, translateY: 0, scale: 1, opacity: 1 }}
                      // exit={{ translateX: 50, translateY: 10, scale: 0.95, opacity: 0.1 }}
                      transition={{
                        duration: 0.5,
                        ease: "easeInOut",
                      }}
                      // layout
                      style={{ height: "100%" }}
                      key={`${entity.id}`}
                    > */}
                    {/* to ten  */}

                    <MemoizedContentCard entity={entity} />
                    {/* </motion.div> */}
                  </Grid>
                )
            )}
          {/* </AnimatePresence> */}
        </Grid>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, memoizedCurrentItemsToDisplay]);

  const wordListInner = useMemo(() => {
    const CustomIconsPagination = ({ pages }: CustomIconsPaginationI) => {
      return (
        <Stack spacing={1}>
          <Helmet>
            <title>Wielosłowie | Lista</title>
          </Helmet>
          <Pagination
            sx={{
              button: {
                fontWeight: "600",
              },

              // white color from pallete

              "button.Mui-selected": {
                backgroundColor: "secondary.dark",
                color: "common.white",
                fontWeight: "900",

                "&:hover": {
                  backgroundColor: "secondary.main",
                },
              },
            }}
            siblingCount={isTablet ? 0 : 3}
            size={isTablet ? "medium" : "large"}
            onChange={paginationChangeHandler}
            page={UiHelpersState.currentPaginationPage}
            count={pages}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: bArrow,
                  next: fArrow,
                }}
                {...item}
              />
            )}
          />
        </Stack>
      );
    };

    return (
      <Box
        sx={{
          px: {
            xs: 2,
            sm: 4,
          },
          pt: isMobile ? 3 : 4,
          pb: 0,
          position: "relative",
        }}
      >
        <SearchBar />
        <Header />
        <Box sx={{ zIndex: 2, minHeight: !isMobile && !isTablet ? "70vh" : "auto" }}>
          {entityState?.filteredEntities?.length === 0 ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" component="h5" sx={{ mt: 8 }}>
                <motion.div
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.97, opacity: 0 }}
                >
                  Nic tu nie ma... Jeszcze!
                </motion.div>
              </Typography>
            </Box>
          ) : (
            resultsList
          )}
        </Box>
        {chunkedEntities?.length > 1 && (
          //    <Container

          //    maxWidth={
          //      isInLoginArea
          //        ? false
          //        : UiHelpersState.maxWidth === "full"
          //        ? "xl"
          //        : UiHelpersState.maxWidth === "wide"
          //        ? "lg"
          //        : UiHelpersState.maxWidth === "normal"
          //        ? "md"
          //        : "lg"
          //    }
          //  >
          <Box
            sx={{
              overflow: "hidden",
              // backgroundColor: "background.paper",
              display: "flex",
              ml: "0",
              borderRadius: "3px",
              backgroundColor: UiHelpersState?.colorMode === "dark" ? "#00000051" : "#fffffe91",
              mr: 0,
              left: "-32px",
              position: "relative",
              width: isMobile
                ? "calc( 100% + 48px)"
                : downLg
                ? "calc( 100% + 64px)"
                : "calc( 100% + 32px)",
              // right: isTablet ? "-32px" : "0",
            }}
          >
            {/* <motion.div
                animate={{ opacity: UiHelpersState.reachedBottom ? 1 : 0 }}
                transition={{
                  duration: 1,
                  ease: "backInOut",
                }}
                layout
              > */}
            <Box
              className="MuiContainer-root"
              sx={{
                width: {
                  lg: "calc(100% )",
                  md: "calc(100%)",
                  sm: "calc(100%)",
                  xs: "calc(100%)",
                },

                left: {
                  lg: "0px",
                  md: "0px",
                  sm: "0px",
                  xs: "0px",
                },
                mr: 2,
                height: isMobile ? "64px" : "82px",
                zIndex: 22,
                pr: 0,
                position: "relative",
                bottom: 0,
                // backgroundColor: "success.light",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",

                  "nav.pagination": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                }}
              >
                <CustomIconsPagination pages={chunkedEntities?.length} />
              </Box>
            </Box>
            {/* </motion.div> */}
          </Box>
        )}
      </Box>
    );
  }, [
    UiHelpersState?.colorMode,
    UiHelpersState.currentPaginationPage,
    bArrow,
    chunkedEntities?.length,
    downLg,
    entityState?.filteredEntities?.length,
    fArrow,
    isMobile,
    isTablet,
    paginationChangeHandler,
    resultsList,
  ]);

  return wordListInner;
  // return <Wrapper mainContent={wordListInner()} componentNav={<WordListHeader />} />;
};
