import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, Grid, Typography, TextField } from "@mui/material";
import { AddEdit } from "@/components/add-edit";
import Wrapper from "./Wrapper";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useTagsStore } from "@/stores/tags-store";
import { useNavigate } from "react-router-dom";
import { useUsersStore } from "@/stores/users-store";
import Checkbox from "@mui/material/Checkbox";
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  AdditionalUIStoreI,
  EntityStoreI,
  EntityObject,
  UsersObjI,
  TagsStoreI,
  AddStoreI,
  UsersStoreI,
  EntityI,
} from "@/types";
import kiwi from "@/assets/kiwi-2.gif";
import {
  ResponsiveCalendar,
  CalendarDatum,
  ResponsiveTimeRange,
  CalendarTooltipProps,
} from "@nivo/calendar";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import { blueGrey } from "@mui/material/colors";
import Tilt from "react-parallax-tilt";
import { useChartHelpers } from "@/components/charts/useChartData";
import { grey } from "@mui/material/colors";
import { SectionHeader } from "@/components/section-header";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
interface TimeRangeI {
  calendarDatumEarliest: string | null;
  calendarDatumLatest: string | null;
  calendarDatum: CalendarDatum[] | null;
}
const MyResponsiveTimeRange = ({
  calendarDatumEarliest,
  calendarDatumLatest,
  calendarDatum,
}: TimeRangeI) => {
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);

  const handleMonthLegend = useCallback((y: number, m: number, d: Date): string | number => {
    return new Date(d).toLocaleDateString("pl-PL", { month: "long" });
  }, []);

  const tooltipHandler = useCallback((t: CalendarTooltipProps) => {
    return (
      <div
        style={{
          background: "white",
          padding: "9px 12px",
          zIndex: 9999,
          border: "1px solid #ccc",
          position: "relative",
          left: "70px",
        }}
      >
        <div>
          <div>
            <strong
              style={{
                padding: "3px 0",
              }}
            >
              {new Intl.DateTimeFormat("pl-PL", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              }).format(new Date(t.day))}
            </strong>
            <div
              style={{
                color: "#000",
                padding: "3px 0",
              }}
            >
              <span>Dodane wielosłowy: </span>
              <strong>{Number(t.value).toFixed(0)}</strong>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const timeRange = useMemo(() => {
    return (
      <ResponsiveTimeRange
        data={calendarDatum ?? []}
        from={calendarDatumEarliest ?? new Date()}
        // to={calendarDatumLatest ?? new Date()}

        to={"2022-12-31"}
        align="left"
        colors={["#adffd0", "#8edeb0", "#17a7b3", "#0f6d75"]}
        emptyColor={UiHelpersState.colorMode === "dark" ? "#828282" : "#ebebeb"}
        margin={{ top: 24, left: 0, bottom: 24, right: 24 }}
        dayBorderWidth={4}
        dayRadius={4}
        dayBorderColor={UiHelpersState.colorMode === "dark" ? "#ffffff" : "#00000011"}
        weekdayTicks={[]}
        weekdayLegendOffset={12}
        monthLegend={handleMonthLegend}
        // legendFormat={(value: number) => {
        //   return `${value} słów`;
        // }}
        // direction="vertical"
        tooltip={tooltipHandler}
        legends={undefined}
      />
    );
  }, [
    UiHelpersState.colorMode,
    calendarDatum,
    calendarDatumEarliest,
    handleMonthLegend,
    tooltipHandler,
  ]);

  return timeRange;
};

export const Profile = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  // const { success, error } = useNotifications();
  const { calendarDatumEarliest, calendarDatumLatest, calendarDatum } = useChartHelpers();

  const location = useLocation();
  const theme = useTheme();
  const headerRef = React.useRef<HTMLInputElement>(null);
  const entityState = useEntityStore((state: EntityStoreI) => state);
  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);

  const usersState = useUsersStore((state: UsersStoreI) => state);
  const [editedEntityId] = useState<string>(location.pathname.split("/")[2]);
  const memoedReducedMotionToggler = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        UiHelpersState.setReducedMotion(true);
      } else {
        UiHelpersState.setReducedMotion(false);
      }
    },
    [UiHelpersState]
  );

  const ProfilePage = () => {
    // const searchOnlyByTitleHandler = React.useCallback(
    //   (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    //     if (checked) {
    //       UiHelpersState.setSearchByTitleOnly(true);
    //     } else {
    //       UiHelpersState.setSearchByTitleOnly(false);
    //     }
    //   },
    //   []
    // );

    return (
      <>
        <Helmet>
          <title>Wielosłowie | Mój Profil</title>
        </Helmet>
        <Box
          sx={{
            px: {
              xs: 2,
              sm: 4,
            },
            my: 3,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  position: "relative",
                  height: "300px",
                  display: "flex",
                  flexFlow: "column wrap",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "uppercase",
                  left: isMobile ? "-16px" : "-32px",
                  top: isMobile ? "-40px" : "-24px",
                  width: isMobile ? "calc( 100% + 32px)" : "calc( 100% + 64px)",
                  background: `linear-gradient(45deg, ${theme.palette.secondary.light} 30%, ${theme.palette.secondary.dark} 90%)`,
                  zIndex: 1,
                  svg: {
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    opacity: 1,
                    zIndex: -1,
                  },
                }}
                ref={headerRef}
              >
                <Tilt
                  glareEnable={false}
                  glareColor="#fff"
                  tiltReverse={true}
                  gyroscope={false}
                  trackOnWindow={false}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexFlow: "column wrap",
                      alignItems: "center",
                      justifyContent: "center",
                      background: UiHelpersState.colorMode === "light" ? "#fff" : blueGrey[900],
                      px: 4,
                      py: 2,
                      boxShadow: "0 0 20px 10px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 800,
                        mb: 3,
                      }}
                    >
                      Mój profil
                    </Typography>
                    {UiHelpersState?.user &&
                      UiHelpersState?.user?.displayName &&
                      UiHelpersState?.user?.displayName?.length > 0 && (
                        <Typography variant="h6">{UiHelpersState.user?.displayName}</Typography>
                      )}
                  </Box>
                </Tilt>
              </Box>

              <SectionHeader title="Informacje o koncie" />
              <Box
                sx={{
                  display: "flex",
                  alignContent: "flex-stat",
                  justifyContent: "center",
                  flexFlow: "column nowrap",
                  mt: 3,
                }}
              >
                <TextField
                  sx={{ width: { xs: "100%", sm: "380px" }, mb: 3 }}
                  label="nazwa użytkownika"
                  id="standard-size-normal"
                  defaultValue={UiHelpersState.user?.displayName}
                  disabled
                  variant="outlined"
                />
                <TextField
                  sx={{ width: { xs: "100%", sm: "380px" }, mb: 3 }}
                  label="adres-email"
                  id="standard-size-normal"
                  defaultValue={UiHelpersState.user?.email}
                  disabled
                  variant="outlined"
                />
                <TextField
                  sx={{ width: { xs: "100%", sm: "380px" }, mb: 3 }}
                  label="id użytkownika"
                  id="standard-size-normal"
                  defaultValue={UiHelpersState.user?.uid}
                  disabled
                  variant="outlined"
                />
              </Box>
              <Box
                sx={{
                  mt: 0,
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexFlow: "column nowrap",
                }}
              >
                <SectionHeader title="Ustawienia aplikacji" />
                {/* <FormGroup aria-label="Wyszukuj tylko po tytule" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={searchOnlyByTitleHandler}
                        checked={UiHelpersState.searchByTitleOnly}
                      />
                    }
                    label="Wyszukuj tylko po tytule"
                    name="searchByTitleOnly"
                    labelPlacement="end"
                  />
                </FormGroup> */}
                <FormGroup aria-label="Wyłącz animacje" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={memoedReducedMotionToggler}
                        checked={UiHelpersState.reducedMotion}
                      />
                    }
                    label="Ogranicz ilość animacji (reduced motion)"
                    name="reduced motion"
                    labelPlacement="end"
                  />
                </FormGroup>
                <SectionHeader title="Aktywność użytkownika" />

                <Box
                  sx={{
                    width: {
                      xl: "50%",
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                      xs: "100%",
                    },
                    height: "230px",
                    "svg g text": {
                      fill:
                        UiHelpersState.colorMode === "dark" ? "#fff !important" : "#000 !important",
                    },
                  }}
                >
                  <MyResponsiveTimeRange
                    calendarDatum={calendarDatum}
                    calendarDatumEarliest={calendarDatumEarliest}
                    calendarDatumLatest={calendarDatumLatest}
                  />
                </Box>
              </Box>
              {/* <Box
                sx={{
                  width: "100%",
                  height: "500px",
                  background:
                    UiHelpersState.colorMode === "light"
                      ? "linear-gradient(0deg, #fff 60%, #ffffff10 99%)"
                      : "linear-gradient(0deg, #fff 60%, #121212 94%)",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  mt: 14,
                  img: {
                    aspectRatio: "16/12",
                    height: "200px",
                  },
                }}
              >
                <img src={kiwi} alt="kiwi" />
              </Box> */}
              {/* <Typography variant="h6" sx={{ mt: 2 }}>
                {UiHelpersState.user?.displayName}
              </Typography> */}
              {/* <Typography variant="h2" sx={{ mt: 2 }}>
                In progress, Kiwi. :)
              </Typography> */}
            </Grid>
          </Grid>
        </Box>

        {/* <Box
          sx={{
            mt: 2,
            p: 2,
            width: "100%",
            height: "460px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              mt: 2,
              p: 2,
              width: "60%",
              height: "460px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
           <MyResponsiveTimeRange data={entitiesMapedToChartByDateFiltered as CalendarDatum[]} />
          </Box>
        </Box> */}
      </>
    );
  };

  // const ProfileHeader = () => {
  //   return (
  //     <Box
  //       sx={{
  //         height: "100%",
  //         display: "flex",
  //         flexFlow: "row nowrap",
  //         alignItems: "center",
  //         justifyContent: "space-between",
  //       }}
  //     >
  //       {/* <Button
  //         variant="outlined"
  //         startIcon={<ChangeHistoryIcon />}
  //         color="success"
  //         size="medium"
  //       >
  //         Rysuj tło!
  //       </Button> */}
  //     </Box>
  //   );
  // };

  return <ProfilePage />;
  // return <Wrapper mainContent={<ProfilePage />} componentNav={<ProfileHeader />} />;
};

// Profile.whyDidYouRender = true;
