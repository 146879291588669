import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
// import { Sparklines, SparklinesLine, SparklinesCurve } from "react-sparklines";
import { motion } from "framer-motion";
import { useChartHelpers } from "@/components/charts/useChartData";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useUsersStore } from "@/stores/users-store";
import { AdditionalUIStoreI, EntityI, EntityStoreI, UsersStoreI } from "@/types";
import { Defs, linearGradientDef } from "@nivo/core";
import { Line, Serie } from "@nivo/line";
export const UserSparkLine = () => {
  // const entities = useEntityStore((state: EntityStoreI) => state.entities);
  // const usersState = useUsersStore((state: UsersStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const { last7daysScore } = useChartHelpers();

  const memmoizedSparkLine = React.useMemo(() => {
    return last7daysScore ? (
      <Line
        width={120}
        height={24}
        layers={["areas", "lines"]}
        data={last7daysScore}
        colors={{ scheme: UiHelpersState.colorMode === "light" ? "dark2" : "pastel2" }}
        enableArea={true}
        margin={{ top: 4, right: 0, bottom: 4, left: 0 }}
        enablePoints={false}
        yScale={{
          type: "linear",
          stacked: false,
        }}
        curve={"natural"}
        lineWidth={2}
        defs={[
          linearGradientDef("gradientB", [
            { offset: 75, color: "inherit" },
            { offset: 100, color: "inherit", opacity: 0 },
          ]),
        ]}
        enableGridX={false}
        enableGridY={false}
        isInteractive={false}
        fill={[{ match: "*", id: "gradientB" }]}
      />
    ) : (
      <i />
    );
  }, [last7daysScore, UiHelpersState.colorMode]);

  const renderer = React.useMemo(() => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "help",
          height: "100%",
          svg: {
            height: "100%",
            circle: {
              display: "none",
            },
          },
        }}
      >
        <Tooltip title="Twoje punkty z ostatnich 7 dni">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 1,
              duration: 0.5,
              repeat: 0,
              ease: "backInOut",
            }}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                height: "40px",
                position: "relative",
                top: "6px",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                mr: 2,
              }}
            >
              {memmoizedSparkLine}
            </Box>
          </motion.div>
        </Tooltip>
      </Box>
    );
  }, [memmoizedSparkLine]);

  return renderer;
};
