export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FirebaseApiKey,
  databaseURL: process.env.REACT_APP_FirebaseDatabaseURL,
  authDomain: process.env.REACT_APP_FirebaseAuthDomain,
  projectId: process.env.REACT_APP_FirebaseProjectId,
  storageBucket: process.env.REACT_APP_FirebaseStorageBucket,
  messagingSenderId: process.env.REACT_APP_FirebaseMessagingSenderId,
  appId: process.env.REACT_APP_FirebaseAppId,
  measurementId: process.env.REACT_APP_FirebaseMeasurementId,
};
