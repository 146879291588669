import "./wdyr";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { Box, CircularProgress } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import TimeAgo from "javascript-time-ago";
import pl from "javascript-time-ago/locale/pl.json";
import { createContext, useEffect, useMemo, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import {
  AdditionalUIStoreI,
  AddStoreI,
  EntityI,
  TagsStoreI,
  EntityPartI,
  EntityPartObjectI,
  EntityStoreI,
  EntityObject,
  UsersObjI,
} from "@/types";
import Center from "@/components/utils/Center";
import { appThemeDark, appThemeLight } from "@/components/utils/theme";
import { db, auth } from "./config/firebase";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { User } from "firebase/auth";
import { getDatabase, ref, set, remove, child, push, update } from "firebase/database";
import Wrapper from "./screens/Wrapper";
import { MotionConfig, useReducedMotion } from "framer-motion";
import WebFont from "webfontloader";
// import CssLato from "./assets/scss/lato.css";
// import CssUbuntu from "./assets/scss/ubuntu.css";
// import "./assets/scss/ubuntu.css";
const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    WebFont.load({
      custom: {
        families: ["Lato", "Ubuntu"],
        urls: ["pub_assets/scss/lato.css", "pub_assets/scss/ubuntu.css"],
      },
      // google: {
      //   families: ["Lato:n1,i1,n3,i3,n4,i4,n7,i7,n9,i9", "Ubuntu:n3,i3,n4,i4,n5,i5, n6, i6,n7,i7"],
      //   // urls: ["/pub_assets/lato.css", "/pub_assets/ubuntu.css"],
      // },
    });
  }, []);

  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);

  const shouldReduceMotion = useReducedMotion();
  const normalAddNewUser = (user: User) => {
    set(ref(db, "users/" + user?.uid), {
      displayName: user?.displayName ?? user?.email,
      email: user?.email ?? "",
      photoURL: user?.photoURL,
      uid: user?.uid ?? "",
      metadata: user?.metadata ?? {},
    })
      .then(() => {
        // console.log("user succefully saved");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    TimeAgo.addDefaultLocale(pl);
  }, []);

  const [apploading, setAppLoading] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        UiHelpersState.setUser(user);
        normalAddNewUser(user);
      }
      setTimeout(() => {
        setAppLoading(false);
        UiHelpersState.setGlobalLoading(false);
      }, 0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (UiHelpersState.globalLoading || apploading)
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#eeeeee87",
          zIndex: 999,
        }}
      >
        {/* <React.Suspense fallback={<Box></Box>}> */}
        {/* <MotionConfig
            reducedMotion={UiHelpersState.reducedMotion || shouldReduceMotion ? "always" : "user"}
            // reducedMotion="never"
          >
            <QueryClientProvider client={queryClient}>
              <ThemeProvider
                theme={UiHelpersState.colorMode === "dark" ? appThemeDark : appThemeLight}
              >
                <CssBaseline />
                <Box
                  sx={{
                    height: "100%",
                    ".Toastify__toast-icon .MuiSvgIcon-root": {
                      color: "info.main",
                    },
                  }}
                >
                  <ToastContainer
                    position="bottom-right"
                    autoClose={3200}
                    hideProgressBar={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    newestOnTop={true}
                    transition={Zoom}
                    pauseOnHover={false}
                    style={{
                      zIndex: 9999999,
                    }}
                  /> */}
        {/* <BrowserRouter basename={process.env.PUBLIC_URL}> */}
        {/* <Wrapper /> */}
        {/* </BrowserRouter> */}

        <Center>
          <CircularProgress disableShrink={true} thickness={4} />
        </Center>
        {/* </Box> */}
        {/* </ThemeProvider>
            </QueryClientProvider>
          </MotionConfig>

          </React.Suspense>

        </React.Suspense> */}
        {/* </React.Suspense> */}
      </Box>
    );

  return (
    <React.Suspense fallback={<Box></Box>}>
      <MotionConfig
        reducedMotion={UiHelpersState.reducedMotion || shouldReduceMotion ? "always" : "user"}
        // reducedMotion="never"
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={UiHelpersState.colorMode === "dark" ? appThemeDark : appThemeLight}>
            <CssBaseline />
            <Box
              sx={{
                height: "100%",
                ".Toastify__toast-icon .MuiSvgIcon-root": {
                  color: "info.main",
                },
              }}
            >
              <ToastContainer
                position="bottom-right"
                autoClose={3200}
                hideProgressBar={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                newestOnTop={true}
                transition={Zoom}
                pauseOnHover={false}
                style={{
                  zIndex: 9999999,
                }}
              />
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Wrapper />
              </BrowserRouter>
            </Box>
          </ThemeProvider>
        </QueryClientProvider>
      </MotionConfig>
    </React.Suspense>
  );
}

export default App;
