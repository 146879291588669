import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import { motion, AnimatePresence } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { blueGrey, green, orange, grey } from "@mui/material/colors";
import { AddTags } from "./add-tags";
import { useNotifications } from "@/components/utils/useNotifications";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useTagsStore } from "@/stores/tags-store";
import { Theme, useTheme } from "@mui/material/styles";
import { CrawlerResultsRenderer } from "./crawler-results-renderer";
import {
  AdditionalUIStoreI,
  AddStoreI,
  EntityPartI,
  EntityStoreI,
  TagsStoreI,
  PossibleDuplicate,
  UsersStoreI,
} from "@/types";
import { useUsersStore } from "@/stores/users-store";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import {
  generateTags,
  tagsTooltips,
  trimWhiteSpace,
  tagsMultipliers,
  hashify,
  trimAndClean,
} from "@/components/utils/helpers";
import stringSimilarity from "string-similarity";

import useMediaQuery from "@mui/material/useMediaQuery";
interface ScoreBoardI {
  addEntityFormData: any;
}

export const ScoreBoard = () => {
  // const { user } = useUserData();
  const queryClient = useQueryClient();
  const usersState = useUsersStore((state: UsersStoreI) => state);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { success, error } = useNotifications();
  const [localMainValue, setLocalMainValue] = useState<string>("");
  const entityState = useEntityStore((state: EntityStoreI) => state);
  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);

  const [localMpVals, setLocalMpVals] = useState<string>("");

  const toNumberDecimal = (val: number) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "decimal",
    });

    return formatter.format(val);
  };
  useEffect(() => {
    setLocalMpVals(
      tagsState.detectedTags
        .map((x) => {
          if (tagsMultipliers[x] > 0) return ` x ${toNumberDecimal(Number(tagsMultipliers[x]))} `;
          else return "";
        })
        .join("")
    );
  }, [tagsState.detectedTags, addState.parts.length]);

  const mainScoreRenderer = useMemo(() => {
    return (
      <motion.div
        initial={{ zoom: 1.5, opacity: 0 }}
        animate={{ zoom: 1, opacity: 1 }}
        exit={{ zoom: 0, opacity: 0 }}
        transition={{
          duration: 0.3,
          repeat: 0,
          delay: 0.7,
          ease: "backInOut",
        }}
        style={{ marginRight: "auto", display: "flex", height: "100%", position: "relative" }}
      >
        <Box sx={{ position: "relative" }}>
          {/* <CountUp
          duration={0.3}
          decimals={1}
          preserveValue={true}
          end={addState.totalScore ?? 0}
          suffix={localMpVals.length > 0 ? ` (${localMpVals})` : ""}
          prefix="Punkty: "
        /> */}
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: "bold",
              mr: 2,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            noWrap
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontWeight: "300",
                fontSize: "14px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 4,
                position: "relative",
                top: "-16px",
                color: "#fff",
                // mr: 13,
              }}
              noWrap
            >
              <CountUp
                duration={0.7}
                decimals={0}
                preserveValue={true}
                end={addState.totalScore ?? 0}
                suffix=" pkt.) ="
                prefix="( "
              />
            </Typography>
            <Box
              sx={{
                backgroundColor:
                  UiHelpersState.colorMode === "dark" ? "primary.light" : "primary.main",
                width: "125px",
                height: "125px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "calc(50% - 62px)",
                right: "-16px",
                border: "3px solid",
                borderColor: "secondary.light",
                transition: "300ms",

                "&:hover": {
                  borderColor: "info.light",
                },
              }}
            >
              <CountUp
                duration={0.7}
                preserveValue={true}
                end={Number(addState.totalScoreWithMultipliers.toFixed(2))}
                suffix={` pkt.`}
                decimals={1}
                style={{
                  display: "flex",
                  position: "relative",
                  top: "8px",
                  color: UiHelpersState.colorMode === "dark" ? "#fff" : "#eee",
                }}
              />
            </Box>
          </Typography>
        </Box>
      </motion.div>
    );
  }, [UiHelpersState.colorMode, addState.totalScore, addState.totalScoreWithMultipliers]);

  const addPageTitle = useMemo(
    () => (
      <Typography variant="h6" component="div" sx={{ fontWeight: "normal", mb: isTablet ? 3 : 0 }}>
        {tagsState.detectedTags.length > 0 && isTablet ? (
          <motion.div
            initial={{ translateY: -50, opacity: 0 }}
            animate={{ translateY: 0, opacity: 1 }}
            exit={{ translateY: -50, opacity: 0 }}
            transition={{
              duration: 0.3,
              repeat: 0,
              ease: "backInOut",
            }}
          >
            <>
              <CountUp
                duration={0.7}
                decimals={1}
                preserveValue={true}
                end={addState.totalScore ?? 0}
                suffix={localMpVals.length > 0 ? ` (${localMpVals})` : ""}
                prefix="Punkty: "
              />
              <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
                <CountUp
                  duration={0.7}
                  preserveValue={true}
                  end={Number(addState.totalScoreWithMultipliers.toFixed(2))}
                  suffix={` pkt.`}
                  decimals={1}
                  prefix=" = "
                />
              </Typography>
            </>
          </motion.div>
        ) : (
          mainScoreRenderer
        )}
      </Typography>
    ),
    [
      addState.totalScore,
      addState.totalScoreWithMultipliers,
      isTablet,
      localMpVals,
      mainScoreRenderer,
      tagsState.detectedTags.length,
    ]
  );

  useEffect(() => {
    // convert entityState.entities to array
    if (addState.parts?.length > 1) {
      let entitiesArray = [...entityState.entities];
      if (UiHelpersState.editMode && addState.entityToAddOrEdit?.id) {
        entitiesArray = entitiesArray.filter((x) => x.id !== addState.entityToAddOrEdit?.id);
      }

      //get all titiles from entitiesArray
      const titles = entitiesArray.map((x) =>
        x.title.toLowerCase().replaceAll(",", "").replaceAll(".", "")
      );
      if (addState.title?.length && titles.length) {
        // check if title exists in titles array
        const titleExists = titles.some(
          (x) =>
            trimWhiteSpace(x).toLowerCase().replaceAll(",", "").replaceAll(".", "") ===
            trimWhiteSpace(addState.title.toLowerCase().replaceAll(",", "").replaceAll(".", ""))
        );

        if (titleExists) {
          addState.setAddingDisabled(true);
        } else {
          addState.setAddingDisabled(false);
        }

        const comparedToOtherTitles: stringSimilarity.BestMatch = stringSimilarity.findBestMatch(
          addState.title.toLowerCase().replaceAll(",", "").replaceAll(".", ""),
          titles
        );
        if (comparedToOtherTitles.ratings?.length > 0) {
          if (comparedToOtherTitles.ratings?.some((x: stringSimilarity.Rating) => x.rating > 0.6)) {
            const duplicates = comparedToOtherTitles.ratings.filter((x) => x.rating > 0.6);

            // add entity id and author to duplicates
            const duplicatesWithIds: PossibleDuplicate[] = duplicates.map((x) => {
              const entity = entitiesArray.find(
                (y) =>
                  y.title.toLowerCase().replaceAll(",", "").replaceAll(".", "") ===
                  x.target.toLowerCase().replaceAll(",", "").replaceAll(".", "")
              );
              return {
                ...x,
                id: entity?.id,
                author: entity?.author,
                createdAt: entity?.createdAt,
              };
            });

            addState.setPossibleDuplicates(duplicatesWithIds);
          } else {
            addState.setPossibleDuplicates([]);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityState.entities, addState.parts]);

  const DuplicatesWarningBoard = () => {
    return (
      <Paper
        elevation={tagsState.detectedTags.length > 0 ? 3 : 0}
        sx={{
          backgroundColor: UiHelpersState.colorMode === "light" ? "warning.light" : "primary.dark",
          px: 3,
          py: 2,
          width: "100%",
          height: "100%",
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: isTablet ? "inherit" : "space-between",
        }}
      >
        <Typography variant="h6" component="span" sx={{ fontWeight: "bold", mb: 1 }}>
          {addState.addingDisabled
            ? "Uwaga! W bazie znaleziono już TEN SAM wielosłow (zapisywanie nie jest możliwe):"
            : "Uwaga! W bazie znaleziono już coś podobnego:"}
        </Typography>
        <Stack direction="row" flexWrap="nowrap" spacing={2} sx={{ overflowX: "auto" }}>
          {addState.possibleDuplicates?.map((x: PossibleDuplicate, i: number) => (
            <Tooltip
              followCursor
              title={`${
                x?.author &&
                `Autor: ${usersState.getUserNameById(x?.author[0])} ${
                  x?.createdAt && `- Dodane: ${new Date(x?.createdAt).toLocaleString("pl")}`
                }`
              }`}
              placement="top"
              key={`${x.target}-${i}`}
            >
              <Chip
                label={`${x.target.split(" ").join(", ")}.`}
                color="primary"
                variant="filled"
                size="medium"
                sx={{
                  mb: 1,
                  fontSize: "0.8rem",
                  border: "3px solid #fff",
                  backgroundColor:
                    UiHelpersState.colorMode === "light" ? "primary.light" : "warning.dark",
                }}
              />
            </Tooltip>
          ))}
        </Stack>
      </Paper>
    );
  };

  const memoizedAddTags = useMemo(
    () => (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexFlow: isTablet ? "column nowrap" : "inherit",
          overflow: "hidden",
        }}
      >
        <AddTags />
      </motion.div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTablet, tagsState.detectedTags]
  );

  const addCardOrSkeletonsMemoized = useMemo(() => {
    const addSkeletonsArr = isMobile ? [1] : isTablet ? [1, 2, 3, 4] : [1, 2, 3, 4, 5, 6];
    return (
      <Grid container spacing={2}>
        {addState?.parts?.length > 0 ? (
          <>
            <AnimatePresence initial={true}>
              {addState?.parts?.map((x: EntityPartI, i: number) => {
                return (
                  <Grid
                    key={`${x.text}-${i}`}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                    sx={{ height: "auto" }}
                  >
                    {/* <motion.div
                    initial={{ scale: 0.1, opacity: 0.1 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 1, opacity: 0.1 }}
                    transition={{
                      duration: 0.1,
                      ease: "backInOut",
                    }}
                    // layout
                  > */}
                    <CrawlerResultsRenderer part={x} />
                    {/* </motion.div> */}
                  </Grid>
                );
              })}
            </AnimatePresence>
          </>
        ) : (
          <>
            {addSkeletonsArr.map((x, i) => {
              return (
                <Grid key={`${x}-${i}`} item xs={12} sm={6} md={6} lg={4} sx={{ height: "auto" }}>
                  <AnimatePresence initial={true}>
                    <motion.div
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0, opacity: 0.4 }}
                      transition={{
                        duration: 0.5,
                        ease: "backInOut",
                      }}
                      layout
                      key={`${x}-${i}`}
                    >
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="216px"
                        sx={{
                          borderRadius: "3px",
                        }}
                      />
                    </motion.div>
                  </AnimatePresence>
                </Grid>
              );
            })}
          </>
        )}
        {/* </AnimatePresence> */}

        {/* : (
          <>
            {addSkeletonsArr.map((x, i) => {
              return (
                <Grid key={`${x}-${i}`} item xs={12} sm={6} md={6} lg={4} sx={{ height: "auto" }}>
                  <motion.div
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0, opacity: 0 }}
                    transition={{
                      duration: 0.5,
                      ease: "backInOut",
                    }}
                    layout
                  >
                    <Skeleton variant="rectangular" width="100%" height="216px" />
                  </motion.div>
                </Grid>
              );
            })}
          </>
        )

         */}
      </Grid>
    );
  }, [addState?.parts, isMobile, isTablet]);

  return (
    <Box
      sx={{
        width: "100%",
        mb: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexFlow: "column nowrap",
      }}
    >
      {addState?.parts && (
        <>
          <Grid container spacing={2}>
            {addState.possibleDuplicates.length > 0 && (
              <Grid item xs={12} md={12} sx={{ mb: 3 }}>
                <motion.div
                  initial={{ scale: 0.4, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0, opacity: 0 }}
                  transition={{
                    duration: 0.6,
                    ease: "backInOut",
                  }}
                  style={{
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                  }}
                  layout
                >
                  <DuplicatesWarningBoard />
                </motion.div>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              md={12}
              id="add-score-board"
              sx={{
                mb: 3,
                display: isMobile && tagsState.detectedTags.length === 0 ? "none" : "flex",
              }}
            >
              {tagsState.detectedTags.length > 0 ? (
                <Paper
                  elevation={3}
                  sx={{
                    // backgroundColor:
                    //   tagsState.detectedTags.length > 0 && UiHelpersState.colorMode === "light"
                    //     ? grey[100]
                    //     : grey[900],
                    px: 3,
                    py: 2,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexFlow: isTablet ? "column nowrap" : "inherit",
                    overflow: "hidden",
                  }}
                >
                  <>
                    {isTablet && addPageTitle}
                    {memoizedAddTags}
                    {!isTablet && addPageTitle}
                  </>
                </Paper>
              ) : (
                <Paper
                  elevation={3}
                  sx={{
                    // backgroundColor:
                    //   tagsState.detectedTags.length > 0 && UiHelpersState.colorMode === "light"
                    //     ? grey[100]
                    //     : grey[900],
                    px: 3,
                    py: 2,
                    width: "100%",
                    height: "100%",
                    minHeight: "86.5px",
                    display: "flex",
                    flexFlow: isTablet ? "column nowrap" : "inherit",
                    overflow: "hidden",
                  }}
                >
                  <>
                    {isTablet || (isMobile && addPageTitle)}
                    {memoizedAddTags}
                    {!isTablet && !isMobile && addPageTitle}
                  </>
                </Paper>
              )}
            </Grid>
            {/* <AnimatePresence exitBeforeEnter> */}
          </Grid>
          {addCardOrSkeletonsMemoized}
        </>
      )}
    </Box>
  );
};
