import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { Theme, useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ref, set } from "firebase/database";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { useNotifications } from "@/components/utils/useNotifications";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { db } from "@/config/firebase";
import { AdditionalUIStoreI, AddStoreI, EntityI, EntityStoreI } from "@/types";
import WarningIcon from "@mui/icons-material/Warning";

// import { generateScore } from "@/components/utils/generateWordsScore";

interface AddHeaderActionsI {
  addOrEditEntityBuilder: () => EntityI;
}

export const AddHeaderActions = ({ addOrEditEntityBuilder }: AddHeaderActionsI) => {
  const { success, error } = useNotifications();

  // const [localMainValue, setLocalMainValue] = useState<string>("");
  const entityState = useEntityStore((state: EntityStoreI) => state);
  // const tagsState = useTagsStore((state: TagsStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);
  const matchesMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  let navigate = useNavigate();

  const theme = useTheme();

  // const addNewEntity = (entity: EntityI) => {
  //   normalAddNewEntity(entity);
  // };

  const handleSaveMultiWord = useCallback(() => {
    const normalAddNewEntity = (entity: EntityI) => {
      set(ref(db, "entitiesDocs/" + entity.id), entity)
        .then(() => {
          console.log("data succefully saved");
          UiHelpersState.editMode
            ? success("Wielosłów pomyślnie wyedytowany.")
            : success("Wielosłów dodany.");
        })
        .catch((error) => {
          console.log(error);
          UiHelpersState.editMode
            ? error("Bład przy edytowaniu wielosłowia.")
            : error("Bład przy dodowaniu wielosłowia.");
        });
      navigate("/");
    };

    let newObj = addOrEditEntityBuilder();
    if (UiHelpersState.editMode) newObj = { ...newObj, editedAt: new Date().toISOString() };
    UiHelpersState.setLastModifiedEntityId(newObj.id);
    normalAddNewEntity(newObj);
  }, [UiHelpersState, addOrEditEntityBuilder, navigate, success]);

  const definitionsToggleHandler = useCallback(
    (_e: any, p: boolean) => {
      UiHelpersState.setDefinitionsExpanded(p);
      UiHelpersState.toggleCrawlersActive();
    },
    [UiHelpersState]
  );

  const definitionsToggleRenderer = useMemo(() => {
    return (
      <FormGroup sx={{ m: 0 }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {!matchesMobile && <Typography variant="inherit">Definicje</Typography>}
          <FormControlLabel
            control={<Switch onChange={definitionsToggleHandler} />}
            label={matchesMobile ? "" : UiHelpersState.crawlersActive ? `włączone` : `wyłączone`}
            checked={UiHelpersState.crawlersActive}
            sx={{ m: 0, width: "58px" }}
          />
        </Box>
      </FormGroup>
    );
  }, [UiHelpersState.crawlersActive, definitionsToggleHandler, matchesMobile]);

  const memoizedActiveSaveButton = useMemo(() => {
    return (
      <Button
        variant="outlined"
        startIcon={<DataSaverOnIcon />}
        color="success"
        size={matchesMobile ? "small" : "medium"}
        id="save-entity"
        onClick={handleSaveMultiWord}
      >
        Zapisz
      </Button>
    );
  }, [handleSaveMultiWord, matchesMobile]);
  return (
    <Paper
      elevation={4}
      square={true}
      sx={{
        width: { xs: "100%", lg: `calc(100% - 240px)` },
        ml: { lg: "240px" },
        height: "72px",
        position: "absolute",
        top: {
          xs: "56px",
          sm: "64px",
        },
        backgroundColor: theme.palette.background.paper,
        zIndex: 2,
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        left: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexFlow: "row wrap",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          maxWidth:
            UiHelpersState.maxWidth === "full"
              ? theme.breakpoints.values.xl + "px"
              : UiHelpersState.maxWidth === "wide"
              ? theme.breakpoints.values.lg + "px"
              : UiHelpersState.maxWidth === "normal"
              ? theme.breakpoints.values.md + "px"
              : theme.breakpoints.values.lg + "px",
          height: "100%",
          position: "relative",
          px: 4,
          py: 0,
          mt: 0,
        }}
      >
        <Button
          startIcon={<HighlightOffIcon />}
          variant="outlined"
          size={matchesMobile ? "small" : "medium"}
          color={"warning"}
          id="cancel-adding"
          onClick={() => navigate("/")}
        >
          Anuluj
        </Button>
        <>{definitionsToggleRenderer}</>

        {addState.addingDisabled ? (
          <Tooltip
            title={"Zapisanie nie jest możliwe - znaleziono dokładny duplikat."}
            placement="bottom"
          >
            <Box>
              <Button
                variant="outlined"
                startIcon={<WarningIcon />}
                color="success"
                size={matchesMobile ? "small" : "medium"}
                disabled
                id="save-entity"
              >
                Zapisz
              </Button>
            </Box>
          </Tooltip>
        ) : (
          memoizedActiveSaveButton
        )}
      </Box>
    </Paper>
  );
};
