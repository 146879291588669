import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React from "react";
import { useDebounce } from "use-debounce";
import { blueGrey, green, orange } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import { useSearchStore } from "@/stores/search-store";

import Center from "@/components/utils/Center";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { useNavigate } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import {
  AdditionalUIStoreI,
  AddStoreI,
  SearchStoreI,
  EntityI,
  EntityStoreI,
  TagsStoreI,
  SortingOptionsI,
} from "@/types";
import { Theme, useTheme } from "@mui/material/styles";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
export const SearchBar = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const smallMobile = useMediaQuery("(max-width:400px)");
  // const t = useTheme();
  // console.log(t);
  const searchState = useSearchStore((state: SearchStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const [localSearchValue, setLocalSearchValue] = React.useState(searchState.searchValue);
  const [debouncedLocalSearchValue] = useDebounce(localSearchValue, 300);
  const navigate = useNavigate();
  React.useEffect(() => {
    searchState.setSearchValue(debouncedLocalSearchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLocalSearchValue]);

  function setSearchPhrase(searchPhrase: string) {
    setLocalSearchValue(searchPhrase);
  }

  React.useEffect(() => {
    if (searchState?.searchValue.length === 0) {
      setLocalSearchValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.searchValue]);

  const SearchInputRender = (
    <TextField
      id="main-search"
      label={UiHelpersState.searchByTitleOnly ? "Szukaj po tytule" : "Szukaj po tytule lub opisie"}
      value={localSearchValue}
      fullWidth={true}
      color="primary"
      autoFocus={false}
      size="medium"
      placeholder="Szukaj..."
      autoComplete="off"
      sx={{
        height: "100%",
        // minWidth: "250px",
        "&:hover": {
          fieldset: {
            borderColor: `${orange[700]} !important`,
          },
        },
        fieldset: {
          transition: "300ms",
          borderWidth: "3px !important",
          borderColor: `${orange[400]} !important`,
        },
      }}
      onChange={(e) => setSearchPhrase(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            color="info"
            disablePointerEvents={localSearchValue.length === 0}
            sx={{ opacity: localSearchValue.length === 0 ? 0.3 : 1 }}
          >
            <IconButton
              aria-label="toggle password visibility"
              color="info"
              onClick={() => {
                setSearchPhrase("");
              }}
              edge="end"
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );

  const handleSortingChange = React.useCallback(
    (event: SelectChangeEvent) => {
      UiHelpersState.setEntitySorting(event.target.value as SortingOptionsI);
    },
    [UiHelpersState]
  );
  const handleSearchScopeChange = React.useCallback(
    (event: SelectChangeEvent) => {
      if (event.target.value === "title") {
        UiHelpersState.setSearchByTitleOnly(true);
      } else if (event.target.value === "title-and-desc") {
        UiHelpersState.setSearchByTitleOnly(false);
      }
    },
    [UiHelpersState]
  );

  const wordListSorting = React.useMemo(() => {
    return (
      <Box
        sx={{
          // height: "100%",
          display: "flex",
          flexFlow: smallMobile ? "column nowrap" : isTablet ? "row nowrap" : "row nowrap",
          alignItems: "center",
          justifyContent: "flex-end",
          width: isTablet ? "100%" : "350px",
          ml: isTablet ? 0 : 2,
          height: "auto",
          fieldset: {
            borderWidth: "3px",
          },
        }}
      >
        <FormControl
          size="medium"
          variant="outlined"
          sx={{
            pr: isMobile ? 0 : isTablet ? 0 : 0,
            width: smallMobile ? "100%" : isTablet ? "50%" : "100%",
            display: "flex",
            mt: isTablet ? 2 : 0,
            mb: isTablet ? 2 : 0,
            flexFlow: smallMobile ? "column wrap" : isTablet ? "row nowrap" : "row nowrap",
            ".MuiSelect-select": {
              minWidth: "80px",
              // width: "100%",
            },
          }}
        >
          <InputLabel id="zakres-szukania">Zakres szukania</InputLabel>
          <Select
            labelId="zakres-szukania"
            id="zakres-szukania"
            value={UiHelpersState.searchByTitleOnly === true ? "title" : "title-and-desc"}
            label="Zakres szukania"
            onChange={handleSearchScopeChange}
            sx={{
              m: 0,
              mr: isMobile ? 2 : isTablet ? 2 : 4,
              width: "100%",
            }}
          >
            <MenuItem value={"title-and-desc"}>Tytuł i opis</MenuItem>
            <MenuItem value={"title"}>Tytuł</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          size="medium"
          variant="outlined"
          sx={{
            width: smallMobile ? "100%" : isTablet ? "50%" : "100%",
          }}
        >
          <InputLabel id="sorting-select">Sortowanie</InputLabel>
          <Select
            labelId="sorting-select"
            id="sorting-select"
            value={UiHelpersState.entitySorting}
            label="Sortowanie"
            onChange={handleSortingChange}
            sx={{
              m: 0,
              width: "100%",
            }}
          >
            <MenuItem value={"date"}>Najnowsze</MenuItem>
            <MenuItem value={"title"}>Alfabetycznie</MenuItem>
            <MenuItem value={"score"}>Punkty</MenuItem>
            <MenuItem value={"desc-length"}>Długość opisu</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  }, [
    smallMobile,
    isTablet,
    isMobile,
    UiHelpersState.searchByTitleOnly,
    UiHelpersState.entitySorting,
    handleSearchScopeChange,
    handleSortingChange,
  ]);

  const addButonRenderMemoized = React.useMemo(() => {
    return (
      <Button
        startIcon={<ControlPointIcon />}
        color="success"
        size="medium"
        id="go-to-add"
        variant="outlined"
        sx={{
          // maxWidth: "50px !important",
          display: "flex",
          height: "56px",
          // width: "70px",
          minWidth: "56px !important",
          mr: isMobile ? 1 : 2,
          borderWidth: "3px",
          textTransform: "none",
          fontSize: "1.2rem",
          "&:hover": {
            borderWidth: "3px",
          },
          ".MuiButton-startIcon": {
            margin: "0",

            svg: {
              fontSize: "1.4rem",
            },
          },
          // py: "14px",
          // px: 0,
        }}
        onClick={() => navigate("/dodaj")}
      />
    );
  }, [isMobile, navigate]);

  const desktopSearchBar = React.useMemo(() => {
    return (
      <>
        {addButonRenderMemoized}
        {SearchInputRender}
        {wordListSorting}
      </>
    );
  }, [SearchInputRender, addButonRenderMemoized, wordListSorting]);

  const mobileSearchBar = React.useMemo(() => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexFlow: "column nowrap",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexFlow: "row nowrap",
            width: "100%",
            m: 1,
            height: "100%",
          }}
        >
          {addButonRenderMemoized}
          {SearchInputRender}
        </Box>
        {wordListSorting}
      </Box>
    );
  }, [SearchInputRender, addButonRenderMemoized, wordListSorting]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: smallMobile ? "column" : "row",
        height: isTablet ? "auto" : "56px",
        width: "100%",
      }}
    >
      {isTablet ? mobileSearchBar : desktopSearchBar}
    </Box>
  );
};
