import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useUsersStore } from "@/stores/users-store";
import { AdditionalUIStoreI, AddStoreI, EntityStoreI, UsersStoreI } from "@/types";

// eslint-disable-next-line
export const Error404 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const entityState = useEntityStore((state: EntityStoreI) => state);
  const usersState = useUsersStore((state: UsersStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);

  useEffect(() => {
    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      404 Error - Przekierowywanie w trakcie
    </Box>
  );
};

export default Error404;
