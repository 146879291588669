import create from "zustand";
import { persist } from "zustand/middleware";

import { EntityI, EntityObject, EntityStoreI } from "@/types";

// const LSvalue = JSON.parse(localStorage.getItem("entity-storage") as string)?.state ?? {};

export const useEntityStore = create<EntityStoreI>()(
  persist(
    (set) =>
      ({
        entities: [],
        filteredEntities: [],
        isLoading: true,
        addEntity: (entity: EntityI) => {
          set((state) => {
            const newEntities = [...state.entities, entity];
            return {
              entities: newEntities,
              filteredEntities: newEntities,
            };
          });
        },
        setIsLoading: (isLoading: boolean) => set({ isLoading }),
        setEntities: (newEntities: EntityI[]) => set(() => ({ entities: [...newEntities] })),
        setFilteredEntities: (newEntities: EntityI[]) =>
          set(() => ({ filteredEntities: [...newEntities] })),
        removeAllEntities: () => set(() => ({ entities: [] })),
        queryTrigger: false,
        triggerQueryRefetch: () => set((prev) => ({ queryTrigger: !prev.queryTrigger })),
      } as EntityStoreI),
    {
      name: "entity-storage",
      getStorage: () => localStorage,
    }
  )
);
