import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DatabaseReference, ref, remove } from "firebase/database";
import * as React from "react";

import { useNotifications } from "@/components/utils/useNotifications";
// import { useEntityStore } from "@/stores/entities-store";
import { EntityI, EntityStoreI } from "@/types";
import { db } from "@/config/firebase";

interface RemoveEntityDialogI {
  entity: EntityI;
  state: boolean;
  onSucess: () => void;
  onClose: () => void;
}
const RemoveEntityDialog = ({ entity, state, onSucess, onClose }: RemoveEntityDialogI) => {
  // const entityState = useEntityStore((state: EntityStoreI) => state);

  const { success, error } = useNotifications();

  const normalRemoveEntity = (id: string) => {
    const postRef: DatabaseReference = ref(db, "entitiesDocs/" + id);
    remove(postRef)
      .then(() => {
        console.log("data succefully removed");
        success("Wielosłów usunięty");
        onSucess();
      })
      .catch((err) => {
        console.log(err);
        error("Wystąpił błąd podczas usuwania wielosłowia.");
      });
  };

  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (state) {
      handleClickOpen();
    } else {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const removeEntity = () => {
    normalRemoveEntity(entity.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box sx={{ fontSize: "18px" }}>
          Zamierzasz <strong style={{ fontWeight: 900, fontSize: "22px" }}>usunąć</strong> ten
          wielosłów.
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component={"div"}>
          <Box sx={{ fontSize: "16px" }}>Czy jesteś tego pewien?</Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between", px: 3, mb: 1 }}>
        <Button
          startIcon={<HighlightOffIcon />}
          variant="outlined"
          color="info"
          size="small"
          id="cancel-removal"
          onClick={handleClose}
          sx={{ fontSize: "11px", fontWeight: "500" }}
        >
          Nie, Anuluj
        </Button>
        <Button
          startIcon={<DataSaverOnIcon />}
          variant="outlined"
          color="error"
          id="confirm-removal"
          onClick={() => removeEntity()}
          size="small"
          autoFocus
          sx={{ fontSize: "11px", fontWeight: "500" }}
        >
          Tak, Usuwam
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveEntityDialog;
