import LogoutIcon from "@mui/icons-material/Logout";
import Person4Icon from "@mui/icons-material/Person4";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import React, { useContext, useMemo } from "react";
// import Avatar from "react-avatar";

import {
  scaleArray,
  stringToColor,
  stringAvatar,
  tagsTooltips,
  tagsMultipliers,
} from "@/components/utils/helpers";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";

import { useUsersStore } from "@/stores/users-store";
import Logout from "@/components/auth/Logout";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import {
  AdditionalUIStoreI,
  EntityI,
  EntityStoreI,
  TagsStoreI,
  AddStoreI,
  UsersObjI,
  UsersStoreI,
} from "@/types";

// import CloudIcon from "@mui/icons-material/Cloud";

export const WieloTooltip = ({
  tag,
  placement = "top",
  children,
}: {
  tag: string;
  placement:
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
  children: React.ReactElement<any, any>;
}) => {
  const content = `${tagsMultipliers[tag]!}`;
  const title = tagsTooltips[tag];

  const MultiplierRenderer = useMemo(() => {
    return (
      <Box
        sx={{
          fontSize: "11px",
          position: "absolute",
          right: "-8px",
          top: "-18px",
          color: "#fff",
          width: "56px",
          height: "56px",
          backgroundColor: "info.dark",
          borderRadius: "50%",

          fontWeight: 500,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: 0.6,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: 12,
            fontWeight: 500,
            display: "flex",
            // pr: 1,
            // mr: 0,
          }}
          component="div"
        >
          <span style={{ fontWeight: 500, fontSize: "50% !important" }}>x</span>
          {content}
        </Typography>
      </Box>
    );
  }, [content]);
  //  Spread the props to the underlying DOM element.
  const tooltipContent = useMemo(() => {
    return (
      <Box sx={{}}>
        <Card
          sx={{
            // boxSizing: "content-box",
            width: "100%",
            display: "flex",
            // minWidth: 245,
          }}
        >
          <CardHeader
            sx={{
              p: 1.5,
              // pr: 0,
              py: 1,
              width: "100%",
              display: "flex",
            }}
            // title={
            //   <>
            //     <Typography
            //       variant="h6"
            //       component={"div"}
            //       sx={{
            //         fontSize: 13,
            //         fontWeight: 700,
            //         textAlign: "center",
            //         textTransform: "uppercase",
            //         alignItems: "center",
            //         justifyContent: "space-between",
            //         color: "info.main",
            //         display: "flex",
            //         // minWidth: 240,
            //         width: "100%",
            //       }}
            //       gutterBottom
            //       noWrap
            //     >
            //       {/* <Box>{tag}</Box> */}
            //       <Box sx={{ width: "100%", display: "flex" }}>
            //         <Typography
            //           variant="h6"
            //           sx={{
            //             fontSize: 12,
            //             fontWeight: 500,
            //             display: "flex",
            //             width: "100%",
            //           }}
            //           component="div"
            //         >
            //           {title}
            //         </Typography>
            //       </Box>
            //     </Typography>
            //     {/* <Divider sx={{ mb: 0.5 }} /> */}
            //   </>
            // }
            subheader={
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                  flexFlow: "row nowrap",
                  width: "100%",
                  // pr: 6.5,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                  component="div"
                >
                  {title}
                </Typography>
                {/* <Typography
                  variant="h6"
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                    display: "flex",
                    // pl: 1,
                    // ml: "auto",
                    // mr: 0,
                    // pr: 4,
                  }}
                  component="div"
                >
                  {" - "} {MultiplierRenderer}
                </Typography> */}
                {/* <span style={{ marginLeft: "4px", marginRight: "4px" }}>-</span> */}

                {/* {MultiplierRenderer} */}
              </Box>
            }
          />
        </Card>
      </Box>
    );
  }, [title]);

  return (
    <Box
      sx={{
        // width: "100%",
        ".wielo-tooltip": {
          width: "100%",
          display: "flex",
        },
        // "> .MuiBox-root": {
        //   width: "100%",
        //   display: "flex",
        // },
      }}
    >
      <Tooltip classes={{ tooltip: "wielo-tooltip" }} title={tooltipContent} placement={placement}>
        {children}
      </Tooltip>
    </Box>
  );
};
