import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "@/config/firebase";

interface Props {
  children: React.ReactNode;
}

const AuthChecker = ({ children }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth?.currentUser) {
      navigate("/zaloguj");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, auth]);

  return <>{children}</>;
};

export default AuthChecker;
