import TagIcon from "@mui/icons-material/Tag";
import { Box, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { blueGrey, orange } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useTagsStore } from "@/stores/tags-store";
import { AdditionalUIStoreI, AddStoreI, EntityStoreI, TagsStoreI } from "@/types";
import { tagsMultipliers, tagsTooltips } from "@/components/utils/helpers";
import { useNotifications } from "@/components/utils/useNotifications";
import { WieloTooltip } from "@/components/wielo-tooltip";
import { Theme, useTheme } from "@mui/material/styles";
export const AddTags = () => {
  const { success, error } = useNotifications();
  // const [localMainValue, setLocalMainValue] = useState<string>("");
  // const entityState = useEntityStore((state: EntityStoreI) => state);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  // const addState = useAddStore((state: AddStoreI) => state);

  const tagsList = tagsState.detectedTags.map((x, i) => (
    <motion.div
      key={`${x}-${i}`}
      initial={{ translateY: 50, opacity: 0 }}
      animate={{ translateY: 0, opacity: 1 }}
      transition={{
        duration: 0.3,
        ease: "backInOut",
      }}
      layout
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexFlow: "row nowrap",
      }}
    >
      <WieloTooltip
        tag={x}
        placement="top"
        children={
          <Button
            // startIcon={<TagIcon />}
            // color="secondary"
            variant="contained"
            disableRipple={true}
            sx={{
              mr: 2,
              cursor: "help",
              overflow: "hidden",
              pr: 6,
              mb: 1,
              mt: 1,
              backgroundColor: UiHelpersState.colorMode === "light" ? "#fff" : "primary.main",
              color: UiHelpersState.colorMode === "light" ? "primary.main" : "#fff",
              border: UiHelpersState.colorMode === "light" ? "1px solid #000" : "1px solid #fff",
              "&:hover": {
                color: "#fff",
              },
            }}
            className={`add-tag-${x}`}
          >
            <Typography
              noWrap={true}
              component="div"
              variant="h6"
              sx={{ fontSize: "12px" }}
              className="single-add-tag"
            >
              <span>{x}</span>

              <Typography
                noWrap={true}
                component="div"
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  display: "inline-flex",
                  fontSize: "12px",
                  color: UiHelpersState.colorMode === "light" ? "primary.main" : "#fff",
                  mr: 1,
                }}
              >
                <Box
                  sx={{
                    background: blueGrey[400],
                    borderRadius: "50% 0 0 50%",
                    width: "40px",
                    height: "40px",
                    position: "absolute",
                    top: "-12px",
                    right: "-16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "30px",
                    color: "#fff",
                  }}
                >
                  <motion.div
                    initial={{ scale: 3, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{
                      duration: 0.3,
                      delay: 0.3,
                      ease: "backInOut",
                    }}
                    style={{
                      display: "flex",
                      height: "24px",
                      left: "-6px",
                      position: "relative",
                    }}
                  >
                    <Box>
                      <span style={{ fontSize: "10px" }}>{" x "}</span>
                      <span style={{ fontSize: "12px" }}> {tagsMultipliers[x]}</span>
                    </Box>
                  </motion.div>
                </Box>
              </Typography>
            </Typography>
          </Button>
        }
      />
    </motion.div>
  ));

  const memoizedtagsList = useMemo(() => tagsList, [tagsList]);

  return (
    <Box
      id="add-tags-container"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexFlow: isMobile || isTablet ? "row wrap" : "row nowrap",
        overflowX: "auto",
        overflowY: "hidden",
        flex: 1,
        height: "62px",
        pr: 6,
        mr: !isMobile && !isTablet ? 6 : 0,
      }}
    >
      {tagsState.detectedTags.length > 0 && memoizedtagsList}
    </Box>
  );
};
