import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Helmet } from "react-helmet";
import { AddEdit } from "@/components/add-edit";
import { AddHeaderActions } from "@/components/add-header-actions";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useTagsStore } from "@/stores/tags-store";
import { AdditionalUIStoreI, AddStoreI, EntityStoreI, TagsStoreI } from "@/types";
import { points, tagsMultipliers } from "@/components/utils/helpers";
import { SectionHeader } from "@/components/section-header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
// import { generateScore } from "@/components/utils/generateWordsScore";
export const ChangeLog = () => {
  const entityState = useEntityStore((state: EntityStoreI) => state);
  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);

  return (
    <>
      <Helmet>
        <title>Wielosłowie | Dziennik zmian</title>
      </Helmet>
      <Box sx={{ px: 3, pb: 4 }}>
        <SectionHeader title="Dziennik zmian" />
        <Timeline position="alternate">
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>Eat</TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>Code</TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>Sleep</TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
            </TimelineSeparator>
            <TimelineContent>Repeat</TimelineContent>
          </TimelineItem>
        </Timeline>
      </Box>
    </>
  );
};
