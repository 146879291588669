import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthContainer from "@/components/auth/AuthContainer";
import Logo from "@/assets/logo-full.png";
import { ToastContainer, toast } from "react-toastify";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNotifications } from "@/components/utils/useNotifications";

import { Helmet } from "react-helmet";
function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Wszystkie prawa zastrzeżone © "}
      <Link color="inherit" href="https://wielosłowie.pl/">
        Wielosłowie.pl
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const pathname = window.location.pathname;
// const notify = () =>
// toast({
//   autoClose: 5000,
//   hideProgressBar: false,
//   closeOnClick: true,
//   type: "success",
//   title: "Zarejestrowano pomyślnie",
// });

export const SignInUp = () => {
  const { success, info, error } = useNotifications();
  const auth = getAuth();
  let navigate = useNavigate();
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: "https://xn--wielosowie-f0b.pl/",
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: "com.example.ios",
    // },
    // android: {
    //   packageName: "com.example.android",
    //   installApp: true,
    //   minimumVersion: "12",
    // },
    // dynamicLinkDomain: "example.page.link",
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [isRegisterPage, setIsRegisterPage] = React.useState(pathname.includes("zarejestruj"));

  const [loginValue, setLoginValue] = React.useState<string>("");
  const [passwordValue, setPasswordValue] = React.useState<string>("");

  const manualLoginHandler = () => {
    info(`Trwa Logowanie.`);

    signInWithEmailAndPassword(auth, loginValue, passwordValue)
      .then(() => {
        navigate("/");
        setTimeout(() => {
          success(`Logowanie udane.`);
        }, 300);
      })
      .catch((e) => {
        error(`Bład podczas logowania...`);
        error(e.message);
      });
  };

  const manualRegisterHandler = async () => {
    info(`Tworzenie nowego użytkownikiwa.`);

    createUserWithEmailAndPassword(auth, loginValue, passwordValue)
      .then(() => {
        success(`Rejestracja udana. Logowanie...`);
        setTimeout(() => {
          navigate("/");
        }, 1500);
      })
      .catch((e) => {
        error(`Bład podczas rejestracji...`);
        error(e.message);
      });
  };

  // get action from URL
  const action: string = searchParams.get("action") || "login";

  // used to set initial state
  let indexFromUrl = 0;
  if (action === "register") {
    indexFromUrl = 1;
  }

  // handle Tab Panel
  const [isLoading, setIsLoading] = React.useState(true);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const loginValueChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setLoginValue(event.target.value);

  const [passValidationError, setPassValidationError] = React.useState(false);

  const passwordValueChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;

    if (val.length < 8) {
      setPassValidationError(true);
    } else {
      setPassValidationError(false);
    }
    setPasswordValue(event.target.value);
  };

  // const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  // const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   // setSnackbarOpen(false);
  // };

  // const RouteToIndexPage = () => {
  //   setTimeout(() => {
  //     navigate("/");
  //   }, 4000);

  //   return <></>;
  // };

  return (
    <ThemeProvider theme={theme}>
      {isRegisterPage ? (
        <Helmet>
          <title>Wielosłowie | Zarejestruj się</title>
        </Helmet>
      ) : (
        <Helmet>
          <title>Wielosłowie | Zaloguj się</title>
        </Helmet>
      )}
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress disableShrink={true} thickness={4} />
        </Box>
      ) : (
        <Container component="main" maxWidth="xs">
          <CssBaseline />

          <Box
            sx={{
              marginTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
              <img
                title="Wielosłowie"
                src={Logo}
                style={{ width: "100%", marginTop: "32px" }}
                alt="Wielosłowie"
              />
            </Box>
            <Typography component="h1" variant="h5">
              {isRegisterPage ? "Rejestracja" : "Logowanie"}
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="login"
                label="Adres e-mail"
                value={loginValue}
                onChange={loginValueChangeHandler}
                name="email"
                type={"email"}
                autoComplete="email"
                autoFocus
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                value={passwordValue}
                onChange={passwordValueChangeHandler}
                label={passValidationError ? "Conajmniej 8 znaków" : "Hasło"}
                type="password"
                id="password"
                error={passValidationError}
                autoComplete="current-password"
              />

              {!isRegisterPage && (
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Pamiętaj mnie"
                />
              )}
              <Button
                type="submit"
                fullWidth
                id={isRegisterPage ? "registerButton" : "loginButton"}
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={isRegisterPage ? manualRegisterHandler : manualLoginHandler}
              >
                <Typography variant="inherit">
                  {isRegisterPage ? "Zarejestruj się" : "Zaloguj się"}
                </Typography>
              </Button>
              <Grid container>
                <Grid item xs>
                  <AuthContainer
                    googleText={
                      isRegisterPage
                        ? "Zarejestruj się za pomocą Google"
                        : "Zaloguj się za pomocą Google"
                    }
                  />
                </Grid>
                {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
                <Grid item>
                  <Link href={isRegisterPage ? "/zaloguj" : "/zarejestruj"} variant="body2">
                    {isRegisterPage
                      ? "Masz już konto? Zaloguj się tutaj!"
                      : "Nie masz jeszcze konta? Zarejestruj się tutaj!"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      )}
    </ThemeProvider>
  );
};
