import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { AddEdit } from "@/components/add-edit";
import Wrapper from "@/screens/Wrapper";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useTagsStore } from "@/stores/tags-store";
import { AdditionalUIStoreI, AddStoreI, EntityStoreI, TagsStoreI, EntityI } from "@/types";
import { AddHeaderActions } from "@/components/add-header-actions";

import { Helmet } from "react-helmet";
import {
  generateTags,
  hashify,
  tagsMultipliers,
  trimWhiteSpace,
  titleWithoutDotOrComma,
} from "@/components/utils/helpers";
export const Edit = () => {
  // const { success, error } = useNotifications();
  const location = useLocation();

  const entityState = useEntityStore((state: EntityStoreI) => state);
  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);

  const [editedEntityId] = useState<string>(location.pathname.split("/")[2]);

  // Component Cleanup
  useEffect(() => {
    UiHelpersState.setEditMode(true);

    return () => {
      UiHelpersState.setEditMode(false);
      addState.setEntityToAddOrEdit(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (entityState.entities.length > 0 && editedEntityId) {
      const foundEntity = entityState.entities.find((x) => x.id === editedEntityId);

      if (foundEntity) {
        addState.setEntityToAddOrEdit(foundEntity);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityState.entities, editedEntityId]);

  return UiHelpersState.editMode ? (
    <>
      <Helmet>
        <title>Wielosłowie | Edytuj</title>
      </Helmet>
      <AddEdit />
    </>
  ) : (
    <i />
  );
};
