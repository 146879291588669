import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloudIcon from "@mui/icons-material/Cloud";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MailIcon from "@mui/icons-material/Mail";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpIcon from "@mui/icons-material/Help";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { useSwipeable, SwipeEventData } from "react-swipeable";
import React, { useContext, useRef, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CommandPaletteController } from "@/components/command-palette";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { TagsList } from "@/components/tags-list";
import { random, sortEntities } from "@/components/utils/helpers";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useUsersStore } from "@/stores/users-store";
import LinearProgress from "@mui/material/LinearProgress";
import Logo from "@/assets/logo-full.png";
import {
  AdditionalUIStoreI,
  AddStoreI,
  EntityI,
  EntityStoreI,
  UsersObjI,
  UsersStoreI,
  NavItemI,
} from "@/types";
import { MainAppBar } from "./MainAppBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import AuthChecker from "@/components/auth/AuthChecker";
import routes from "@/config/routes";
import BarChartIcon from "@mui/icons-material/BarChart";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { onValue, orderByChild, Query, query, ref } from "firebase/database";
import PullToRefresh from "react-simple-pull-to-refresh";
import { db, auth } from "@/config/firebase";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

import {
  motion,
  MotionConfig,
  AnimatePresence,
  LayoutGroup,
  useReducedMotion,
  AnimationControls,
} from "framer-motion";
// import CloudIcon from "@mui/icons-material/Cloud";
const drawerWidth = 240;

interface WrapperProps {
  mainContent?: JSX.Element;
  componentNav?: JSX.Element;
}

// eslint-disable-next-line
const Wrapper = ({ mainContent, componentNav }: WrapperProps) => {
  // const { scrollYProgress } = useScroll();

  // const scrollRef = useRef(null);
  const location = useLocation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isTabletForSwiping = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const entityState = useEntityStore((state: EntityStoreI) => state);
  const usersState = useUsersStore((state: UsersStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);
  const swipeHandlersAppContent = useSwipeable({
    onSwipedRight: (eventData: SwipeEventData) => {
      console.log(eventData);
      const targetEl = eventData?.event?.target as HTMLDivElement;
      if (targetEl) {
        if (targetEl.closest(".chart-container")) {
          return;
        }
      }

      isTabletForSwiping &&
        UiHelpersState.mobileMenuOpen === false &&
        UiHelpersState.setMobileMenuOpen(true);
    },
    onSwipedLeft: (eventData: SwipeEventData) => {
      isTabletForSwiping &&
        UiHelpersState.mobileMenuOpen === true &&
        UiHelpersState.setMobileMenuOpen(false);
    },
  });
  const swipeHandlersBackdrop = useSwipeable({
    onSwipedLeft: (eventData: SwipeEventData) => {
      isTabletForSwiping &&
        UiHelpersState.mobileMenuOpen === true &&
        UiHelpersState.setMobileMenuOpen(false);
    },
  });

  useEffect(() => {
    const entitiesNormalQuery: Query = query(
      ref(db, "entitiesDocs"),
      orderByChild("title")
      // limitToFirst(15)
    );

    onValue(entitiesNormalQuery, (snapshot) => {
      const data: EntityI[] = [];
      snapshot.forEach(function (child) {
        const val: EntityI = child.val();
        data.push(val);
      });
      if (data?.length > 0) {
        entityState.setEntities(sortEntities(data, UiHelpersState.entitySorting));
        // entityState.setEntities([...data]);
      }
    });

    const usersNormalRef = ref(db, "users");
    onValue(usersNormalRef, (snapshot) => {
      const data: UsersObjI = snapshot.val();
      if (Object.keys(data)?.length > 0) {
        usersState.setUsers(data);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser?.uid]);

  const navigate = useNavigate();
  useEffect(() => {
    isMobile && UiHelpersState.setReducedMotion(true);
    UiHelpersState.setLastModifiedEntityId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const theme = useTheme();

  const container = document.body;
  const animateLogo = useCallback(() => {
    return {
      scale: [1, 0.7, 1.31, 0.8, 1],
      y: [0, -7, 6, -4, 0],
      opacity: [1, 1, 0.8, 0.5, 1],
      x: [0, 3, -5, 3, 0],
      skew: ["0deg", `-${random(1, 9)}deg`, `${random(1, 9)}deg`, `-${random(1, 9)}deg`, "0deg"],
      skewX: ["0deg", `-${random(1, 9)}deg`, `${random(1, 9)}deg`, `-${random(1, 9)}deg`, "0deg"],
      skewY: ["0deg", `-${random(1, 9)}deg`, `${random(1, 9)}deg`, `-${random(1, 9)}deg`, "0deg"],
      filter: ["contrast(1)", "contrast(1)", "contrast(1.5)", "contrast(1)", "contrast(1)"],
    };
  }, []);
  const logoRender = useMemo(() => {
    return (
      <motion.div
        animate={animateLogo() as unknown as AnimationControls}
        transition={{
          duration: 1,
          ease: "easeInOut",
          delay: 25,
          times: [0, 0.2, 0.5, 0.7, 1],
          repeat: Infinity,
          repeatDelay: 125,
        }}
        style={{
          height: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignContent: "cenetr", justifyContent: "center" }}>
          <img title="Wielosłowie" src={Logo} style={{ width: "80%" }} alt="Wielosłowie" />
        </Box>
      </motion.div>
    );
  }, [animateLogo]);

  const MenuItemOnClick = useCallback(
    (item: any) => {
      navigate(item.link);
      UiHelpersState.setMobileMenuOpen(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [UiHelpersState]
  );

  const mainNavMenu = useMemo(() => {
    const navItems: NavItemI[] = [
      {
        title: "dodaj",
        link: "/dodaj",
        defaultIcon: <AddCircleIcon color="success" />,
        selectedIcon: <AddCircleOutlineIcon color="success" />,
      },
      {
        title: "lista",
        link: "/",
        defaultIcon: <ViewListIcon color="success" />,
        selectedIcon: <ListAltIcon color="success" />,
      },
      {
        title: "chmura",
        link: "/chmura",
        defaultIcon: <CloudIcon color="success" />,
        selectedIcon: <CloudQueueIcon color="success" />,
      },
      {
        title: "statystyki",
        link: "/statystyki",
        defaultIcon: <InsertChartIcon color="success" />,
        selectedIcon: <BarChartIcon color="success" />,
      },
      {
        title: "zasady",
        link: "/zasady",
        defaultIcon: <HelpIcon color="success" />,
        selectedIcon: <HelpOutlineIcon color="success" />,
      },
      // {
      //   title: "Dziennik zmian",
      //   link: "/dziennik-zmian",
      //   defaultIcon: <ChangeCircleIcon color="success" />,
      //   selectedIcon: <PublishedWithChangesIcon color="success" />,
      // },
    ];

    return (
      <List>
        {navItems.map((item: NavItemI, index: number) => (
          <ListItem
            className={location.pathname === item.link.replaceAll(" ", "-") ? "is-selected" : ""}
            onClick={() => MenuItemOnClick(item)}
            key={item.title + "-" + index}
            disablePadding
            sx={{
              ".MuiListItemIcon-root": {
                minWidth: "40px",
              },
              "&.is-selected": {
                backgroundColor: "rgba(0,0,0,0.1)",
                borderLeft: "3px solid",
                borderColor: "primary.main",
                color: "primary.main",
                "& .MuiListItemIcon-root": {
                  color: "primary.main",
                },
              },
            }}
          >
            <ListItemButton id={`menu-${item.title}`}>
              <ListItemIcon>
                {location.pathname.includes(item.title.toLowerCase().replaceAll(" ", "-")) ||
                (item.title === "lista" && location.pathname === "/")
                  ? item.defaultIcon
                  : item.selectedIcon}
              </ListItemIcon>
              <ListItemText sx={{ textTransform: "capitalize" }} primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  }, [MenuItemOnClick, location.pathname]);

  const DrawerInsights = useMemo(() => {
    return (
      <Box
        sx={{
          overflowX: "hidden",
          pt: {
            xs: 7,
            sm: 8,
            lg: 0,
          },
        }}
      >
        {isTabletForSwiping && <Divider sx={{ width: "100%" }} />}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflowX: "hidden",
            mr: "auto",
            minHeight: "172px",

            overflow: "hidden",
          }}
        >
          {logoRender}
        </Box>
        <Divider />
        {mainNavMenu}
        <Divider />
        <TagsList />
      </Box>
    );
  }, [isTabletForSwiping, logoRender, mainNavMenu]);

  const handleRefresh = () => new Promise((resolve) => resolve(window.location.reload()));

  const drawerCloseHandlerCallback = useCallback(() => {
    UiHelpersState.setMobileMenuOpen(false);
  }, [UiHelpersState]);

  const NavAndDrawer = useMemo(() => {
    const drawerStructure = (
      <Box
        component="nav"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 2,
          width: { lg: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={UiHelpersState.mobileMenuOpen}
          onClose={drawerCloseHandlerCallback}
          ModalProps={{
            ...swipeHandlersBackdrop,
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
            ".MuiPaper-root": {
              overflowX: "hidden !important",
            },
            borderTop: "12px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          {DrawerInsights}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
          open
        >
          {DrawerInsights}
        </Drawer>
      </Box>
    );

    return (
      <Box
        sx={{
          ">.MuiPaper-root": {
            paddingRight: "0 !important",
          },
        }}
      >
        <MainAppBar />
        {drawerStructure}
      </Box>
    );
  }, [
    UiHelpersState.mobileMenuOpen,
    container,
    DrawerInsights,
    drawerCloseHandlerCallback,
    swipeHandlersBackdrop,
  ]);

  const listInnerRef = useRef();

  const [isInLoginArea, setIsInLoginArea] = React.useState(
    location.pathname.includes("zaloguj") || location.pathname.includes("zarejestruj")
  );

  useEffect(() => {
    setIsInLoginArea(
      location.pathname.includes("zaloguj") || location.pathname.includes("zarejestruj")
    );
  }, [location]);

  return (
    <PullToRefresh
      onRefresh={handleRefresh}
      isPullable={isTabletForSwiping}
      pullDownThreshold={90}
      resistance={5}
      refreshingContent={
        <Box sx={{ width: "100%" }}>
          <LinearProgress color="warning" />
        </Box>
      }
      pullingContent={
        <Box
          sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <Typography
            variant="body1"
            component="span"
            sx={{
              display: "block",
              fontWeight: 600,
              fontSize: "14px",
              textTransform: "uppercase",
              py: 3,
            }}
          >
            ⬇️ Pociągnij w dół, aby odświeżyć. ⬇️
          </Typography>
        </Box>
      }
    >
      <Box sx={{ flexGrow: 1, height: "100%", overflowX: "hidden" }} {...swipeHandlersAppContent}>
        {!location.pathname.includes("zaloguj") &&
          !location.pathname.includes("zarejestruj") &&
          NavAndDrawer}
        <Box
          className="App"
          // onScroll={onScroll}
          ref={listInnerRef}
          sx={{
            height: "100%",
            width: {
              lg: isInLoginArea ? "100%" : `calc(100% - 240px)`,
            },
            ml: {
              lg: isInLoginArea ? "0px" : "240px",
            },
            p: isInLoginArea
              ? "0 !important"
              : {
                  xs: "56px 0 0 0 !important",
                  sm: "65px 0 0 0 !important",
                },
            // overflowY: "auto",
            // scrollBehavior: "smooth",
          }}
        >
          <Box
            id="app-scroll-container"
            sx={{
              height: "100%",
              overflowY: "auto",
              // minHeight: "calc( 100vh - 65px )",
              scrollBehavior: "smooth",
            }}
          >
            <Container
              sx={{
                m: "0 !important",
                height: "100%",
                width: "100%",
                px: isMobile ? "0 !important" : "0 !important",
              }}
              maxWidth={
                isInLoginArea
                  ? false
                  : UiHelpersState.maxWidth === "full"
                  ? "xl"
                  : UiHelpersState.maxWidth === "wide"
                  ? "lg"
                  : UiHelpersState.maxWidth === "normal"
                  ? "md"
                  : "lg"
              }
            >
              <Box
                sx={{ width: "100%", height: "100%", display: "flex", flexFlow: "column nowrap" }}
              >
                {/* <MotionConfig reducedMotion={UiHelpersState.reducedMotion ? "always" : "user"}> */}
                <Routes>
                  {routes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={
                        route.protected ? (
                          <AuthChecker>
                            <route.component />
                          </AuthChecker>
                        ) : (
                          <route.component />
                        )
                      }
                    />
                  ))}
                </Routes>
                {/* </MotionConfig> */}
              </Box>
            </Container>
          </Box>
        </Box>
        {!isMobile && <CommandPaletteController />}
      </Box>
    </PullToRefresh>
  );
};

export default Wrapper;
