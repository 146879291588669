import { blueGrey, green, grey, cyan, deepOrange, amber } from "@mui/material/colors";
import {
  Theme,
  PaletteOptions,
  TypographyVariantsOptions,
  Components,
  Breakpoints,
} from "@mui/material/styles";
import { createTheme, adaptV4Theme } from "@mui/material/styles";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";

const bodyFonts: string = ["Lato", "Roboto", "Arial", "sans-serif"].join(",");
const headingFonts: string = ["Ubuntu", "Open Sans", "Arial", "sans-serif"].join(",");

const typographyCfg: TypographyVariantsOptions = {
  h1: {
    fontFamily: headingFonts,
  },
  h2: {
    fontFamily: headingFonts,
  },
  h3: {
    fontFamily: headingFonts,
  },
  h4: {
    fontFamily: headingFonts,
  },
  h5: {
    fontFamily: headingFonts,
  },
  h6: {
    fontFamily: headingFonts,
  },
  body1: {
    fontFamily: bodyFonts,
  },
  body2: {
    fontFamily: bodyFonts,
  },
  fontFamily: bodyFonts,
};

const componentsCfg: Components = {
  MuiToggleButton: {
    defaultProps: {
      sx: {
        px: 2,
        fontWeight: "bold",
      },
      size: "small",
    },
  },
};

const breakpointsCfg: Breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
});

export const appThemeLight = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: blueGrey[500],
      light: blueGrey[300],
      dark: blueGrey[700],
      contrastText: "#fff",
    },
    secondary: {
      main: grey[600],
      light: grey[300],
      dark: grey[900],
      contrastText: "#fff",
    },
    tetriary: {
      main: green[600],
      light: green[400],
      dark: green[800],
      contrastText: "#fff",
    },
    background: {
      default: "#e0f2f1",
      paper: "#fff",
    },
  },
  components: componentsCfg,
  typography: typographyCfg,
  breakpoints: breakpointsCfg,
});

export const appThemeDark = createTheme({
  palette: {
    primary: {
      main: blueGrey[600],
    },
    secondary: {
      main: grey[700],
    },
    tetriary: {
      main: amber[700],
    },
    mode: "dark",
  },
  components: componentsCfg,
  typography: typographyCfg,
  breakpoints: breakpointsCfg,
});
