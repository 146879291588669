import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Helmet } from "react-helmet";
import { AddEdit } from "@/components/add-edit";
import { AddHeaderActions } from "@/components/add-header-actions";
import Box from "@mui/material/Box";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useTagsStore } from "@/stores/tags-store";
import { AdditionalUIStoreI, AddStoreI, EntityStoreI, TagsStoreI } from "@/types";
import { auth } from "@/config/firebase";
import { signOut } from "firebase/auth";
import Logout from "@/components/auth/Logout";
import CommandPalette, { Command } from "react-command-palette";
import { useNavigate } from "react-router-dom";

// import { generateScore } from "@/components/utils/generateWordsScore";
export const CommandPaletteController = () => {
  const entityState = useEntityStore((state: EntityStoreI) => state);
  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);
  let navigate = useNavigate();
  const commands: Command[] = [
    {
      name: "Dodaj nowy wielosłów",
      color: "success",
      id: 0,
      command() {
        navigate(`/dodaj`);
      },
    },
    {
      name: "Nawiguj na listę",
      color: "success",
      id: 1,
      command() {
        navigate(`/`);
      },
    },
    {
      name: "Nawiguj na chmurę",
      color: "success",
      id: 2,
      command() {
        navigate(`/chmura`);
      },
    },
    {
      name: "Nawiguj na statystyki",
      color: "success",
      id: 3,
      command() {
        navigate(`/statystyki`);
      },
    },
    {
      name: "Nawiguj na zasady gry",
      color: "success",
      id: 3,
      command() {
        navigate(`/zasady`);
      },
    },
    {
      name: "Nawiguj na Twój profil",
      color: "success",
      id: 4,
      command() {
        navigate(`/profil/${UiHelpersState.user?.uid}`);
      },
    },
    {
      name: "Wyloguj",
      color: "success",
      id: 4,
      command() {
        signOut(auth)
          .then(() => {
            navigate(`/zaloguj`);
          })
          .catch((error) => {
            console.error(error);
          });
      },
    },
  ];

  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        mt: 3,
        width: "100%",
        height: "100%",
        display: open ? "flex" : "none",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0,0,0,0.1)",
      }}
    >
      <CommandPalette
        display="modal"
        hotKeys={"ctrl+shift+p"}
        onAfterOpen={() => setOpen(true)}
        onRequestClose={() => setOpen(false)}
        closeOnSelect={true}
        open={false}
        placeholder="Szukaj polecenia..."
        commands={commands}
      />
    </Box>
  );
};
