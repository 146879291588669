/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import { orange } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { CreateNewPart } from "@/components/create-part";
import { useNotifications } from "@/components/utils/useNotifications";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useTagsStore } from "@/stores/tags-store";
import { trimWhiteSpace } from "@/components/utils/helpers";
import { AdditionalUIStoreI, AddStoreI, EntityI, EntityStoreI, TagsStoreI } from "@/types";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { useNavigate } from "react-router-dom";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
export const AddForm = () => {
  const navigate = useNavigate();
  // const { user } = useUserData();
  const { success, error } = useNotifications();

  const descriptionRef = React.useRef<HTMLInputElement>(null);
  const mainInputRef = React.useRef<HTMLInputElement>(null);
  const entityState = useEntityStore((state: EntityStoreI) => state);
  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);
  const [localMainValue, setLocalMainValue] = useState<string>("");
  const [localDescriptionValue, setLocalDescriptionValue] = useState<string>("");

  //* THIS FIRES ONLY ONCE
  useEffect(() => {
    if (UiHelpersState.editMode && addState.entityToAddOrEdit) {
      setLocalMainValue(String(addState.entityToAddOrEdit?.title) + ".");
      setLocalDescriptionValue(String(addState.entityToAddOrEdit?.description));
    }
  }, [UiHelpersState.editMode, addState.entityToAddOrEdit]);

  const isProperlyEnded =
    trimWhiteSpace(localMainValue).endsWith(addState.delimeter) ||
    trimWhiteSpace(localMainValue).endsWith(".");

  const setParts = () => {
    addState.cleanParts();
    const sanitizedLocalMainValueArr = trimWhiteSpace(localMainValue)
      .replaceAll(".", "")
      .split(addState.delimeter)
      .filter((x) => x.length > 0);

    sanitizedLocalMainValueArr.forEach((x, i) => {
      if (trimWhiteSpace(x).length > 0) {
        addState.addNewPart({ ...CreateNewPart(trimWhiteSpace(x)) });
      }
    });
  };

  // sets parts on
  useEffect(() => {
    if (localMainValue.length > 0 && isProperlyEnded) {
      setParts();
    }
  }, [UiHelpersState.editMode, localMainValue]);

  // set local score
  useEffect(() => {
    if (addState.lastPressedKey === "Backspace") {
      if (
        trimWhiteSpace(localMainValue).endsWith(addState.delimeter) &&
        addState.parts.length - 1 >= 0
      ) {
        addState.removeLastPart(addState.parts[addState.parts.length - 1]);
      }
    }
    if (localMainValue?.length > 0) {
      addState.setTitle(localMainValue);
    }
    // if (localDescriptionValue?.length > 0) {
    addState.setDescription(localDescriptionValue);
    // }
  }, [localMainValue, localDescriptionValue]);

  const descriptionUpdateHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalDescriptionValue(e.target.value);
  }, []);

  const cleadAddEntityData = () => {
    addState.setEntityToAddOrEdit(null);
    addState.cleanParts();
    addState.setPossibleDuplicates([]);
    addState.setTotalScore(0);
    addState.setTitle("");
    addState.setTotalScoreWithMultipliers(0);
    tagsState.removeAllDetectedTags();
    tagsState.setDetectedTags([]);

    setLocalMainValue("");
    setLocalDescriptionValue("");
    if (descriptionRef.current) {
      descriptionRef.current.value = "";
    }
    if (mainInputRef.current) {
      mainInputRef.current.value = "";
    }
  };

  useEffect(() => {
    if (!UiHelpersState.editMode) {
      cleadAddEntityData();
    }
  }, [UiHelpersState.editMode]);

  useEffect(() => {
    return () => {
      cleadAddEntityData();
    };
  }, []);

  const mainInputKeyDownHandler = useCallback(
    (e: React.KeyboardEvent) => {
      const code = e?.code?.toLowerCase();

      if (code === "backspace") {
        addState.setLastPressedKey("Backspace");
      } else {
        addState.setLastPressedKey("");
      }

      if (code.includes("digit")) {
        return e.preventDefault();
      }
    },
    [addState]
  );

  const mainInputChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length === 0) {
        cleadAddEntityData();
      }
      setLocalMainValue(e.target.value);
    },
    [setLocalMainValue, cleadAddEntityData]
  );

  const onDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      descriptionUpdateHandler(e);
    },
    [descriptionUpdateHandler]
  );

  const memoizedInputsRender = useMemo(
    () => (
      <>
        <Grid item xs={12} md={12}>
          <Box sx={{ display: "flex", width: "100%", flexFlow: "row nowrap" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Box sx={{ display: "flex", flexFlow: "row nowrap", alignItems: "center" }}>
                  <TextField
                    defaultValue={addState.entityToAddOrEdit?.title}
                    key={addState.entityToAddOrEdit?.title}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      "&:hover": {
                        fieldset: {
                          borderColor: `${orange[700]} !important`,
                        },
                      },
                      width: "100%",
                      fieldset: {
                        transition: "300ms",
                        borderWidth: "3px !important",
                        borderColor: `${orange[500]} !important`,
                      },
                    }}
                    autoFocus={true}
                    margin="dense"
                    id="main-input"
                    label={`Oddziel przecinkami i zakończ kropką, np. "zarobił, za, robił."`}
                    type="text"
                    autoComplete="off"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    placeholder="Twój najsprytniejszy wielosłów! :) "
                    onChange={mainInputChangeHandler}
                    inputRef={mainInputRef}
                    // inputProps={{
                    //   onBlur: triggerWordScoreCalculation,
                    // }}
                    onKeyDown={mainInputKeyDownHandler}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box sx={{ display: "flex", width: "100%", mt: 2, mb: 4 }}>
            <TextField
              key={addState.entityToAddOrEdit?.description}
              InputLabelProps={{ shrink: true }}
              sx={{
                width: "100%",
                fieldset: {
                  borderWidth: "3px",
                },
              }}
              id="main-description"
              label="Opis (opcjonalne)"
              multiline
              variant="outlined"
              color="secondary"
              autoComplete="off"
              rows={2}
              onChange={onDescriptionChange}
              inputRef={descriptionRef}
              defaultValue={addState.entityToAddOrEdit?.description}
              placeholder="Krótkie wyjaśnienie, któro pozwoli wyzbyć się duplikatów."
            />
          </Box>
        </Grid>
      </>
    ),
    [UiHelpersState.editMode, addState.entityToAddOrEdit]
  );
  return <Grid container>{memoizedInputsRender}</Grid>;
};
