import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Helmet } from "react-helmet";
import { AddEdit } from "@/components/add-edit";
import { AddHeaderActions } from "@/components/add-header-actions";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useTagsStore } from "@/stores/tags-store";
import { AdditionalUIStoreI, AddStoreI, EntityStoreI, TagsStoreI } from "@/types";
import { points, tagsMultipliers } from "@/components/utils/helpers";
import { SectionHeader } from "@/components/section-header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme, useTheme } from "@mui/material/styles";
import { WieloTooltip } from "@/components/wielo-tooltip";
// import { generateScore } from "@/components/utils/generateWordsScore";
export const Rules = () => {
  const entityState = useEntityStore((state: EntityStoreI) => state);
  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  // divide Object.values(points) into 4 arrays

  // Object.values(points).map((tag) => {

  // const pointsValues = useMemo(() => {
  //   const arr = Object.values(points);
  //   const arr1 = arr.slice(0, 4);
  //   const arr2 = arr.slice(4, 8);
  //   const arr3 = arr.slice(8, 12);
  //   const arr4 = arr.slice(12, 16);
  //   return [arr1, arr2, arr3, arr4];
  // }, []);

  // const [pointGroupLetters, setPointGroupLetters] = useState<string[]>([]);

  // divide points into to 4 smaller objects

  // console.log("points", points);
  // const pointsGroupsGenertor = useCallback(() => {
  //   const groups = [];
  //   const pointsLength = Object.keys(points).length;
  //   const groupLength = Math.floor(pointsLength / 4);
  //   for (let i = 0; i < pointsLength; i += groupLength) {
  //     groups.push(Object.entries(points).slice(i, i + groupLength));
  //   }

  //   if (groups.length > 0) {
  //     setPointGroupLetters(groups.map((group) => group[0][0]));
  //   }

  //   return groups;
  // }, []);

  // useEffect(() => {
  //   pointsGroupsGenertor();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // console.log("pointsValues", pointsGroupsGenertor());
  return (
    <>
      <Helmet>
        <title>Wielosłowie | Zasady</title>
      </Helmet>
      <Box sx={{ px: isMobile ? 2 : 3, pb: 4 }}>
        <SectionHeader title="Zasady" />
        Wielosłowie to gra językowa, polegająca na znajdowaniu ukrytych znaczeń i połączeń w już
        znanych nam słowach języka polskiego.
        <br />
        <br />
        Wielosłowiem nazywamy zbiór słów, oddzielonych przecinkiem i zakończonych kropką. (np.
        „zarobił, za, robił”). Wielosłowia możemy podzielić na dwie główne grupy:
        <ul>
          <li>
            <span>
              Wielosłowie składające się z tych samych słów, posiadających jednak różne znaczenia.
              (np. „miga, miga, miga, miga”)
            </span>
          </li>
          <li>
            <span>
              Wielosłowie, w którym jedno słowo jest „matką”, a reszta słów składa się z rozbitych
              części słowa „matki” (np. „wycieczka, wy, cieczka, wycie, czka” – każda ze
              składniowych części tworzy osobne słowo).
            </span>
          </li>
        </ul>
        Wielosłowie można uznać za kompletny jedynie wtedy, gdy możemy do niego utworzyć logiczny
        opis, przedstawiający przykładowe użycie naszego wielosłowia w poprawnym zdaniu (służy do
        tego pole Opis).
        <br />
        <br />
        <u>
          Opis jest poprawny wtedy, kiedy wszystkie elementy wielosłowia zostaną użyte w zdaniu
          zgodnie z zasadami:
        </u>
        <ul style={{ marginBottom: "40px" }}>
          <li>
            <span>
              Słowa nie są rozdzielone żadnymi przyimkami, spójnikami itp., czyli muszą być
              bezpośrednio koło siebie (przykład niepoprawny: „wygody, <u>a</u>, wy, gody”)
            </span>
          </li>
          <li>
            <span>
              Cząstkowe słowa z „matki” muszą występować koło siebie w takiej samej kolejności, jak
              występują w „matce” (np. „mamona, mamo, na”; przykład niepoprawny: „mamona, na,
              mamo”).
            </span>
          </li>
          <li>
            <span>
              Nie ma znaczenia kolejność występowania słowa „matki” względem pozostałych słów (może
              być jako pierwsza, w środku lub jako ostatnia, np. „maska, rami, maskarami, mas,
              karami”, „maskarami, maska, rami, mas, karami”, „maska, rami, mas, karami,
              maskarami”).
            </span>
          </li>
          <li>
            <span>Wszystkie słowa z wielosłowia tworzą jedno lub kilka zdań.</span>
          </li>
          <li>
            <span>
              Można używać wszelkich znaków interpunkcyjnych rozdzielających słowa, które pomogą
              nadać odpowiedni charakter opisowi (dialog, pytania, okrzyk, narracja).
            </span>
          </li>
          <li>
            <span>
              Konstrukcja opisu musi mieć jasny, czytelny styl, szyk wyrazów powinien być zgodny z
              polszczyzną, choć dopuszcza się użycie większej swobody, jak np. szyku przestawnego
              lub inwersji.
            </span>
          </li>
        </ul>
        <div style={{ display: " none" }}>
          <SectionHeader title="Punktacja" />
          <Grid container spacing={2}>
            <Grid item md={12} lg={12}>
              <Typography variant="h6" sx={{ my: 2, fontSize: "16px" }}>
                Za każde słowo w wielosłowiu, otrzymujemy punkty bazując na częstotliwości
                występowania danej litery w języku polskim (punktacja podobna do Scrabble):
              </Typography>

              <TableContainer component={Paper}>
                <Table size="small" aria-label="Punktacja literowa">
                  <TableHead>
                    <TableRow>
                      <TableCell>Litera</TableCell>
                      <TableCell align="right">Punkty</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(points).map((row) => (
                      <TableRow
                        key={row[0]}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row[0]}
                        </TableCell>
                        <TableCell align="right">{row[1] as number}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item md={12} lg={12}>
              <Typography variant="h6" sx={{ my: 2, fontSize: "16px" }}>
                Dodatkowo, za różne relacje między słowami w wielosłowiu, otrzymujemy dodatkowe
                mnożniki, w postaci tagów:
              </Typography>
              <Box>
                {
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="Punktacja ltierowa">
                      <TableHead>
                        <TableRow>
                          <TableCell>Tag</TableCell>
                          <TableCell align="right">Mnożnik punktowy</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(tagsMultipliers).map((row) => (
                          <TableRow
                            key={row[0]}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              <WieloTooltip
                                key={row[0]}
                                tag={row[0]}
                                placement="top"
                                children={<Box sx={{ display: "flex" }}>#{row[0]}</Box>}
                              />
                            </TableCell>
                            <TableCell align="right">{row[1] as number}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
              </Box>
            </Grid>
          </Grid>
        </div>
        <SectionHeader title="Przykłady" />
        <Grid container spacing={2}>
          <Grid item md={6} lg={12}>
            Consectetur exercitation dolor officia ea esse velit incididunt ea nostrud ex commodo do
            eiusmod. In enim mollit ad laboris duis amet id veniam deserunt nisi fugiat ut. Laborum
            qui tempor officia veniam ut sunt consectetur velit commodo dolore eiusmod. Proident
            ullamco ad ullamco magna irure ex Lorem in reprehenderit sint tempor adipisicing. Enim
            mollit aute cupidatat eu aliqua aliquip ullamco cillum est est id amet. Esse excepteur
            est elit cillum aute anim. Cupidatat esse cillum proident veniam quis ullamco sunt nulla
            nostrud deserunt. Ad officia culpa consectetur mollit non. Est incididunt eu consectetur
            eu sunt ex. Adipisicing sunt ad tempor fugiat. Reprehenderit ipsum id dolore sit irure.
            Nulla qui sint magna mollit eiusmod. Commodo magna sunt qui proident officia ut sit est.
            Do aute excepteur minim elit pariatur consectetur do reprehenderit deserunt proident
            esse. Aute irure voluptate adipisicing adipisicing. Incididunt reprehenderit velit velit
            consectetur aliqua commodo voluptate duis commodo. Proident ullamco consectetur ipsum
            elit pariatur reprehenderit cupidatat sit do nostrud ea Lorem elit. Laborum consectetur
            laboris elit aliqua ipsum. Adipisicing est duis ex minim cillum ea. Quis elit pariatur
            sunt sit Lorem anim. Lorem commodo non ullamco adipisicing commodo. Deserunt esse eu
            Lorem est Lorem eu excepteur in minim in. Adipisicing sunt occaecat elit magna
            adipisicing deserunt proident ut commodo ut quis non anim non.
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
