import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useTheme, Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { Route, Link, Routes, useLocation } from "react-router-dom";
import { useTagsStore } from "@/stores/tags-store";
import { TagsStoreI, AdditionalUIStoreI } from "@/types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { WieloTooltip } from "@/components/wielo-tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
export const TagsList = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const smallerThanMd = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const tagsState = useTagsStore((state: TagsStoreI) => state);

  const memoizedHandleSetTagAsInactive = React.useCallback(
    (availableTag: string) => {
      tagsState.removeTagAsActive(availableTag);
      UiHelpersState.setCurrentPaginationPage(1);
    },
    [UiHelpersState, tagsState]
  );
  const memoizedHandleSetTagAsActive = React.useCallback(
    (availableTag: string) => {
      tagsState.setTagAsActive(availableTag);
      UiHelpersState.setCurrentPaginationPage(1);
    },
    [UiHelpersState, tagsState]
  );
  const memoizedHandleRemoveAllActiveTags = React.useCallback(() => {
    tagsState.removeAllActiveTags();
    UiHelpersState.setCurrentPaginationPage(1);
  }, [UiHelpersState, tagsState]);

  const availableTagsSortedByTagsFrequency = React.useMemo(
    () =>
      tagsState.availableTags.sort(
        (a, b) => tagsState.tagsFrequency[a] - tagsState.tagsFrequency[b]
      ),
    [tagsState.availableTags, tagsState.tagsFrequency]
  );

  const tagCounter = React.useCallback(
    (tag: string) => {
      return (
        tagsState.tagsFrequency[tag] > 0 && (
          <Typography variant="inherit" sx={{ ml: "auto" }}>
            ({tagsState.tagsFrequency[tag]})
          </Typography>
        )
      );
    },
    [tagsState.tagsFrequency]
  );

  const MemoizedTagsList = () => {
    return (
      <Stack
        direction="column"
        id="main-tags-list"
        spacing={2}
        sx={{
          pt: 1,
          px: 2,
          overflowX: "hidden",
          flexFlow: "column nowrap",
          width: "100%",
          mb: 4,
        }}
      >
        {availableTagsSortedByTagsFrequency &&
          availableTagsSortedByTagsFrequency.map((availableTag: string, i: number) => {
            let returnedChip = <></>;

            tagsState.activeTags.some(
              (activeTag) => activeTag.toLowerCase() === availableTag.toLowerCase()
            )
              ? (returnedChip = (
                  <Tooltip
                    title={`Wyłącz tag ${availableTag}`}
                    placement={isMobile ? "top" : "right"}
                    key={`${availableTag}`}
                  >
                    <Button
                      variant="contained"
                      startIcon={<RemoveCircleIcon />}
                      color="secondary"
                      className={availableTag + " main-tag is-active"}
                      onClick={() => memoizedHandleSetTagAsInactive(availableTag)}
                      sx={{
                        color: theme.palette.mode === "dark" ? "error.main" : "#fff",
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Typography noWrap={true} variant="inherit" sx={{ pr: 0, fontSize: "12px" }}>
                        {availableTag}
                      </Typography>
                      {tagCounter(availableTag)}
                    </Button>
                  </Tooltip>
                ))
              : (returnedChip = (
                  <WieloTooltip
                    tag={availableTag}
                    placement={isMobile ? "top" : "right"}
                    key={`${availableTag}-n`}
                    children={
                      <Button
                        startIcon={<AddCircleOutlineIcon />}
                        color="secondary"
                        variant="outlined"
                        className={availableTag + " main-tag"}
                        onClick={() => memoizedHandleSetTagAsActive(availableTag)}
                        sx={{
                          color: theme.palette.mode === "dark" ? "#fff" : "primary.main",
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        <Typography
                          noWrap={true}
                          variant="inherit"
                          sx={{ pr: 0, fontSize: "12px" }}
                        >
                          {availableTag}
                        </Typography>
                        {tagCounter(availableTag)}
                      </Button>
                    }
                  />
                ));

            return returnedChip;
          })}
        {/* // <Button
          //   key={`wyczyść filtry`}
          //   component="button"
          //   startIcon={<PlaylistRemoveIcon />}
          //   variant={`${tagsState.activeTags.length === 0 ? "outlined" : "contained"}`}
          //   color="error"
          //   onClick={memoizedHandleRemoveAllActiveTags}
          //   sx={{
          //     pointerEvents: tagsState.activeTags.length === 0 ? "none" : "auto",
          //     opacity: tagsState.activeTags.length === 0 ? 0.5 : 1,

          //     display: "flex",
          //     width: "100%",
          //     alignItems: "center",
          //     justifyContent: "space-evenly",
          //   }}
          // >
          //   <Typography noWrap={true} variant="inherit" color={"warning"}>
          //     wyczyść filtry
          //   </Typography>
          // </Button> */}
      </Stack>
    );
  };

  return location?.pathname === "/" ? (
    <Box sx={{ flexFlow: "column nowrap", my: 1 }}>
      <List
        sx={{
          color: "error.main",
        }}
      >
        <ListItem
          onClick={tagsState.activeTags.length > 0 ? memoizedHandleRemoveAllActiveTags : undefined}
          className={
            tagsState.activeTags?.length > 0
              ? "main-tags-list--active"
              : "main-tags-list--not-active"
          }
          sx={{
            p: 0,
            mb: 0,
            minHeight: "48px",
            "&:hover&.main-tags-list--active": {
              cursor: "pointer",
              color: "error.dark",
              svg: {
                color: "error.dark",
              },
              backgroundColor: "rgba(0,0,0,0.1)",
              borderLeft: "3px solid",
              borderColor: "primary.main",
              "& .MuiListItemIcon-root": {
                color: "error.main",
              },
              "div, span": {
                cursor: "inherit",
              },
            },
            color: "error.light",
            svg: {
              color: "error.light",
            },
            "div, span": {
              cursor: "default",
            },
          }}
        >
          <ListItemIcon sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {tagsState.activeTags?.length > 0 ? (
              <PlaylistRemoveIcon />
            ) : (
              <PlaylistAddCheckOutlinedIcon />
            )}
          </ListItemIcon>
          <ListItemText
            primary={
              tagsState.activeTags.length > 0
                ? `Wyczyść filtry (${tagsState.activeTags?.length})`
                : "Filtrowanie listy:"
            }
          >
            <Typography
              noWrap={true}
              variant="h6"
              component="div"
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                my: 2,
              }}
            ></Typography>
          </ListItemText>
        </ListItem>
      </List>
      <MemoizedTagsList />
    </Box>
  ) : (
    <></>
  );
};
