import GoogleIcon from "@mui/icons-material/Google";
import { Box, Button, Typography } from "@mui/material";
import { signInWithPopup } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useNotifications } from "@/components/utils/useNotifications";
import { auth, Providers } from "@/config/firebase";
import Center from "@/components/utils/Center";

interface Props {
  googleText: string;
}

const AuthContainer = ({ googleText }: Props) => {
  const { success, info, error } = useNotifications();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);

  const signInWithGoogle = () => {
    setDisabled(true);
    signInWithPopup(auth, Providers.google)
      .then(() => {
        navigate("/");
        success("Zalogowano pomyślnie.");
        setDisabled(false);
      })
      .catch((e) => {
        error("Wystąpił problem z logowaniem.");
        setErrorMessage(e.code + ": " + e.message);
        setDisabled(false);
      });
  };

  return (
    <Center height={"auto"}>
      <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
        <Button
          startIcon={<GoogleIcon sx={{ color: "#fff" }} />}
          size="large"
          color="info"
          disabled={disabled}
          variant="contained"
          onClick={signInWithGoogle}
          fullWidth={true}
        >
          <Typography variant="inherit" noWrap={true}>
            {googleText}
          </Typography>
        </Button>
      </Box>
      <Typography sx={{ mt: 2 }} color={"red"}>
        {errorMessage}
      </Typography>
    </Center>
  );
};

export default AuthContainer;
