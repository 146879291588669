import create from "zustand";
import { persist } from "zustand/middleware";
import { User } from "firebase/auth";
import { UsersStoreI, UsersObjI } from "@/types";

export const useUsersStore = create<UsersStoreI>()(
  persist(
    (set, get) =>
      ({
        users: {},
        setUsers: (users: UsersObjI) => set(() => ({ users })),
        getUserNameById: (id: string) => {
          const users: UsersObjI | undefined = get().users;
          // if (users) {
          //   console.log(users);
          //   console.log(users[id], id);
          // }
          return users[id]?.displayName ?? "Bład";
        },
        relevantUsers: [
          {
            id: "yIdYNkip6NYSABLpw3yz36q2ad73",
            prettyName: "Kiwi",
          },
          {
            id: "f1AWKN0VNDQcacyAvGLsEWHOckA2",
            prettyName: "Orzeł",
          },
        ],
        totalScoreCounter: 0,
        setTotalScoreCounter: (totalScoreCounter: number) => set(() => ({ totalScoreCounter })),
      } as UsersStoreI),

    {
      name: "uiHelpers-storage",
      getStorage: () => localStorage,
    }
  )
);
