import { lazily } from "react-lazily";

// const { WordList } = lazily(() => import(/* webpackChunkName: 'WordList' */ "@/screens/WordList"));
// const { WordCloud } = lazily(
//   () => import(/* webpackChunkName: 'WordCloud' */ "@/screens/WordCloud")
// );
// const { SignInUp } = lazily(() => import(/* webpackChunkName: 'SignInUp' */ "@/screens/SignInUp"));
// const { Add } = lazily(() => import(/* webpackChunkName: 'Add' */ "@/screens/Add"));
// const { Edit } = lazily(() => import(/* webpackChunkName: 'Edit' */ "@/screens/Edit"));
// const { Profile } = lazily(() => import(/* webpackChunkName: 'Profile' */ "@/screens/Profile"));
// const { Stats } = lazily(() => import(/* webpackChunkName: 'Stats' */ "@/screens/Stats"));
// const { Error404 } = lazily(() => import(/* webpackChunkName: '404' */ "@/screens/404"));
// const { Rules } = lazily(() => import(/* webpackChunkName: '404' */ "@/screens/Rules"));
// const { ChangeLog } = lazily(() => import(/* webpackChunkName: '404' */ "@/screens/ChangeLog"));

import { WordList } from "@/screens/WordList";
import { WordCloud } from "@/screens/WordCloud";
import { SignInUp } from "@/screens/SignInUp";
import { Add } from "@/screens/Add";
import { Edit } from "@/screens/Edit";
import { Profile } from "@/screens/Profile";
import { Stats } from "@/screens/Stats";
import { Error404 } from "@/screens/404";
import { Rules } from "@/screens/Rules";
import { ChangeLog } from "@/screens/ChangeLog";
// import Details from "@/screens/Details";

interface RouteType {
  path: string;
  component: any;
  name: string;
  protected: boolean;
}

const routes: RouteType[] = [
  {
    path: "/zaloguj",
    component: SignInUp,
    name: "Ekran Logowania",
    protected: false,
  },
  {
    path: "/zarejestruj",
    component: SignInUp,
    name: "Ekran Rejestracji",
    protected: false,
  },
  {
    path: "/zasady",
    component: Rules,
    name: "Zasady gry",
    protected: true,
  },
  {
    path: "/dziennik-zmian",
    component: ChangeLog,
    name: "Dziennik zmian",
    protected: true,
  },
  {
    path: "/dodaj",
    component: Add,
    name: "Dodaj nowy wielosłów",
    protected: true,
  },
  {
    path: "/",
    component: WordList,
    name: "Lista",
    protected: true,
  },
  {
    path: "/chmura",
    component: WordCloud,
    name: "Chmura Słów",
    protected: true,
  },
  {
    path: "/statystyki",
    component: Stats,
    name: "Statystyki",
    protected: true,
  },
  {
    path: "/edytuj/:id",
    component: Edit,
    name: "Wielosłów - edycja",
    protected: true,
  },
  {
    path: "/profil/:id",
    component: Profile,
    name: "Wielosłów - profil",
    protected: true,
  },
  {
    path: "*",
    component: Error404,
    name: "Error 404",
    protected: false,
  },
];

export default routes;
