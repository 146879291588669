import { parentPort } from "worker_threads";
import create from "zustand";
import { AddStoreI, EntityPartI, EntityI, PossibleDuplicate } from "@/types";
import stringSimilarity from "string-similarity";
import { useTagsStore } from "./tags-store";
import {
  generateTags,
  hashify,
  tagsMultipliers,
  trimWhiteSpace,
  titleWithoutDotOrComma,
} from "@/components/utils/helpers";

export const useAddStore = create<AddStoreI>()(
  (set, get) =>
    ({
      parts: [],
      addNewPart: (part: EntityPartI) =>
        set((prev: AddStoreI) => ({
          parts: [...prev.parts, part],
        })),
      removeLastPart: (payloadPart: EntityPartI) => {
        let parts: EntityPartI[] = get().parts;
        const found = parts.find((part) => part.id === payloadPart.id);
        if (found) {
          parts = parts.filter((part) => part.id !== found.id);
        }

        return set((prev: AddStoreI) => ({
          parts: parts ? [...parts] : [],
        }));
      },
      cleanParts: () => set(() => ({ parts: [] })),
      updatePart: (part: EntityPartI) => {
        return set(() => {
          const cachedParts: EntityPartI[] = [...get().parts];
          // cachedParts.forEach((x) => {
          //   if (x.id === part.id) {
          //     x.sjpDictionaryDataPresent = part.sjpDictionaryDataPresent;
          //     x.pwnCrawlerDataPresent = part.pwnCrawlerDataPresent;
          //   }
          // });

          return {
            parts: [...cachedParts],
          };
        });
      },
      delimeter: ",",
      title: "",
      entityToAddOrEdit: null,
      addingDisabled: false,
      setAddingDisabled: (addingDisabled: boolean) => set({ addingDisabled }),
      setEntityToAddOrEdit: (entity: EntityI | null) => set(() => ({ entityToAddOrEdit: entity })),
      // setEntityToAddOrEditTags: (tags: string[]) =>
      //   set(() => ({
      //     entityToAddOrEdit: {
      //       ...(get().entityToAddOrEdit as EntityI),
      //       tags: [...tags],
      //     },
      //   })),
      dopuszczalneLength: 0,
      setDopuszczalneLength: (dopuszczalneLength: number) => set({ dopuszczalneLength }),
      setDetectedTagsInTagsStore: () => {
        const title = get().title;
        const partsLength = get().parts.length;
        const generatedTags = generateTags(title);
        // console.log("get().dopuszczalneLength", get().dopuszczalneLength);
        // console.log("partsLength", partsLength);
        const allAreDopuszczalne = get().dopuszczalneLength === partsLength;

        if (title?.length > 0) {
          if (allAreDopuszczalne) {
            useTagsStore.getState().setDetectedTags([...generatedTags, "idealne-do-gier"]);
          } else {
            useTagsStore.getState().setDetectedTags([...generatedTags, "sjp-fail"]);
          }
        }
      },
      setTitle: (str: string) => set(() => ({ title: str })),
      description: "",
      setDescription: (str: string) => set(() => ({ description: str })),
      totalScoreWithMultipliers: 0,
      setTotalScoreWithMultipliers: (totalScoreWithMultipliers: number) =>
        set(() => ({ totalScoreWithMultipliers })),
      totalScore: 0,
      setTotalScore: (totalScore: number) => set(() => ({ totalScore })),
      multipliers: [],
      setMultipliers: (multipliers: number[]) => set(() => ({ multipliers })),

      lastPressedKey: "",
      setLastPressedKey: (lastPressedKey: string) => set(() => ({ lastPressedKey })),
      possibleDuplicates: [],
      setPossibleDuplicates: (possibleDuplicates: PossibleDuplicate[]) =>
        set(() => ({ possibleDuplicates })),
      // setParts: (newParts: EntityPartI[]) => set(() => ({ parts: [...newParts] })),
      // removePart: (part: EntityPartI) =>
      //   set((prev: AddStoreI) => ({
      //     parts: [...prev.parts.filter((x) => x.id !== part.id)],
      //   })),

      clearStore: () => set({}, true),
    } as AddStoreI)
);
