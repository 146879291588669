import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import React, { useEffect, useMemo, useCallback } from "react";
import CountUp from "react-countup";
import { useLocation } from "react-router-dom";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useUsersStore } from "@/stores/users-store";
import { AdditionalUIStoreI, EntityI, EntityStoreI, UsersStoreI } from "@/types";
import { onValue, orderByChild, Query, query, ref } from "firebase/database";
import { db } from "@/config/firebase";

export const TotalScoreCounter = () => {
  const entities = useEntityStore((state: EntityStoreI) => state.entities);
  const usersState = useUsersStore((state: UsersStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);

  useEffect(() => {
    return () => {
      usersState.setTotalScoreCounter(Number(0));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [cEnded, setCEnded] = React.useState(false);
  const countDownEndedHandler = useCallback(() => {
    setCEnded(true);
  }, [setCEnded]);

  const easingFn = useCallback((t: number, b: number, c: number, d: number) => {
    var ts = (t /= d) * t;
    var tc = ts * t;
    return b + c * (tc * ts + -5 * ts * ts + 10 * tc + -10 * ts + 5 * t);
  }, []);

  const memoizedEntitiesCountUp = useMemo(() => {
    // @ts-ignore

    return (
      <motion.div
        initial={{ translateY: -50, opacity: 0 }}
        animate={{ translateY: 0, opacity: 1 }}
        transition={{
          delay: 1.5,
          duration: 0.4,
          repeat: 0,
          ease: "backInOut",
        }}
      >
        <Typography
          variant="inherit"
          component="span"
          sx={{
            transition: "1s ease-out",
            fontWeight: 600,
            fontSize: cEnded ? "16px" : "14px",
            color: cEnded ? "text.primary" : "text.secondary",
          }}
        >
          <CountUp
            duration={3.5}
            preserveValue={true}
            end={Object.keys(entities).length}
            decimals={0}
            suffix=" wielosłowia"
            easingFn={easingFn}
            onEnd={countDownEndedHandler}
          />
        </Typography>
      </motion.div>
    );
  }, [cEnded, countDownEndedHandler, easingFn, entities]);

  const memoizedPointsCountUp = useMemo(() => {
    // @ts-ignore

    return (
      <motion.div
        initial={{ translateY: -50, opacity: 0 }}
        animate={{ translateY: 0, opacity: 1 }}
        transition={{
          delay: 1.5,
          duration: 0.4,
          repeat: 0,
          ease: "backInOut",
        }}
      >
        <Typography
          variant="inherit"
          component="span"
          sx={{
            transition: "1s ease-out",
            fontWeight: 600,
            fontSize: cEnded ? "16px" : "14px",
            color: cEnded ? "text.primary" : "text.secondary",
          }}
        >
          <CountUp
            duration={3.5}
            preserveValue={true}
            end={usersState.totalScoreCounter}
            decimals={0}
            suffix=" pkt."
            easingFn={easingFn}
            onEnd={countDownEndedHandler}
          />
        </Typography>
      </motion.div>
    );
  }, [cEnded, countDownEndedHandler, easingFn, usersState.totalScoreCounter]);

  return (
    <Breadcrumbs
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: "100%",
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          // mr: 2,
          height: "100%",
          cursor: "help",
        }}
      >
        <Tooltip title="Suma wielosłowów w bazie">
          <Typography component="span" variant="body2" sx={{ fontWeight: "600" }}>
            {memoizedEntitiesCountUp}
          </Typography>
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          // mr: 2,
          height: "100%",
          cursor: "help",
        }}
      >
        <Tooltip title="Suma wszystkich Twoich punktów">
          <Typography component="span" variant="body2" sx={{ fontWeight: "600" }}>
            {memoizedPointsCountUp}
          </Typography>
        </Tooltip>
      </Box>
    </Breadcrumbs>
  );
};
