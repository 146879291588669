import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { useAddStore } from "@/stores/add-store";
import { useUiHelpersStore } from "@/stores/additional-ui-store";
import { useEntityStore } from "@/stores/entities-store";
import { useTagsStore } from "@/stores/tags-store";
import { random } from "@/components/utils/helpers";
import { useUsersStore } from "@/stores/users-store";
import {
  AdditionalUIStoreI,
  AddStoreI,
  EntityI,
  EntityPartI,
  UsersStoreI,
  EntityStoreI,
  TagsStoreI,
  WordCloudDBDataI,
  CalendarActivityI,
  PieDatumI,
  tagsFrequencyObjectI,
  SortingOptionsI,
} from "@/types";
import { CalendarDatum } from "@nivo/calendar";
import { Datum } from "@nivo/line";
import { Word } from "react-wordcloud";
import { onValue, orderByChild, Query, query, ref } from "firebase/database";
import { db } from "@/config/firebase";
import { Serie, DatumValue } from "@nivo/line";
import { RadialBarSerie, RadialBarDatum } from "@nivo/radial-bar";

export const useChartHelpers = () => {
  const entityState = useEntityStore((state: EntityStoreI) => state);
  const tagsState = useTagsStore((state: TagsStoreI) => state);
  const UiHelpersState = useUiHelpersStore((state: AdditionalUIStoreI) => state);
  const addState = useAddStore((state: AddStoreI) => state);
  const usersState = useUsersStore((state: UsersStoreI) => state);

  // local data

  const [totalPointsPerUser, setTotalPointsPerUser] = useState(0);
  const [last7daysScore, setLast7daysScore] = useState<Serie[] | null>(null);
  const [entitiesAddedPerDay, setEntitiesAddedPerDay] = useState<Datum[] | null>(null);
  const [entitiesTotalPerDay, setEntitiesTotalPerDay] = useState<Datum[] | null>(null);
  const [entitiesAddedPerWeek, setEntitiesAddedPerWeek] = useState<Datum[] | null>(null);
  const [entitiesTotalPerWeek, setEntitiesTotalPerWeek] = useState<Datum[] | null>(null);
  const [entitiesAddedPerMonth, setEntitiesAddedPerMonth] = useState<Datum[] | null>(null);
  const [entitiesTotalPerMonth, setEntitiesTotalPerMonth] = useState<Datum[] | null>(null);
  const [pieDatum, setPieDatum] = useState<PieDatumI[] | null>(null);
  const [cloudWords, setCloudWords] = useState<Word[] | null>(null);
  const [cloudSyllables, setCloudSyllables] = useState<Word[] | null>(null);
  const [calendarDatum, setCalendarDatum] = useState<CalendarDatum[] | null>(null);
  const [calendarDatumEarliest, setCalendarDatumEarliest] = useState<string | null>(null);
  const [calendarDatumLatest, setCalendarDatumLatest] = useState<string | null>(null);
  const [tagsRadialDatum, setTagsRadialDatum] = useState<RadialBarSerie<RadialBarDatum>[] | null>(
    null
  );

  // const formatDate = (date: Date | string) => {
  //   const dateObj = new Date(date);
  //   const year = dateObj.getFullYear();
  //   const month = dateObj.getMonth() + 1;
  //   const day = dateObj.getDate();
  //   return `${year}-${month}-${day}`;
  // };

  // const frequency = useCallback((arr: EntityPartI[]) => {
  //   const a = arr.reduce((acc: any, curr: any) => {
  //     if (typeof acc[curr?.text] == "undefined") {
  //       acc[curr?.text] = 1;
  //     } else {
  //       acc[curr?.text] += 1;
  //     }
  //     return acc;
  //   }, {});
  //   return a;
  // }, []);

  // const calculateDateFrequency = useCallback((enities: EntityI[], date: string) => {
  //   if (enities?.length > 0) {
  //     let count = 0;
  //     enities.forEach((entity) => {
  //       if (formatDate(entity.createdAt as Date) === formatDate(date)) {
  //         count++;
  //       }
  //     });
  //     return count;
  //   }
  // }, []);

  const calcTagsRadialDatum = () => {
    const tagsDataQuery: Query = query(ref(db, `chartData/tagsData/`));

    onValue(tagsDataQuery, (snapshot) => {
      const tagsData: RadialBarSerie<RadialBarDatum>[] | undefined = snapshot.val();

      if (tagsData) {
        setTagsRadialDatum(tagsData);

        const allUsersdata = tagsData.find((datum: RadialBarSerie<RadialBarDatum>) => {
          return datum.id === "all-users";
        });
        if (allUsersdata) {
          const tagsData: { [key: string]: number } = {};
          allUsersdata.data.forEach((datum) => {
            tagsData[datum.x] = datum.y;
          });
          // sort tagsData based on value
          const sorted = Object.entries(tagsData)
            .sort(([, a], [, b]) => a - b)
            .reduce(
              (r, [k, v]) => ({
                ...r,
                [k]: v,
              }),
              {}
            );

          tagsState.setTagsFrequency(sorted);
        }
      }
    });
  };

  useEffect(() => {
    let searchedDatumId: "all-users" | "Kiwi" | "Orzeł" = "all-users";
    const tagsData: { [key: string]: number } = {};
    if (UiHelpersState.entityFiltering === "user-kiwi") {
      searchedDatumId = "Kiwi";
    } else if (UiHelpersState.entityFiltering === "user-orzeł") {
      searchedDatumId = "Orzeł";
    }

    const allUsersdata = tagsRadialDatum?.find((datum: RadialBarSerie<RadialBarDatum>) => {
      return datum.id === searchedDatumId;
    });

    allUsersdata?.data.forEach((datum) => {
      tagsData[datum.x] = datum.y;
    });

    // sort tagsData based on value
    const sorted = Object.entries(tagsData)
      .sort(([, a], [, b]) => a - b)
      .reduce(
        (r, [k, v]) => ({
          ...r,
          [k]: v,
        }),
        {}
      );

    tagsState.setTagsFrequency(sorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UiHelpersState.entityFiltering, tagsRadialDatum]);

  const calcCalendarDatum = () => {
    const calendarDataQuery: Query = query(
      ref(db, `chartData/userActivity/${UiHelpersState.user?.uid}`)
    );

    onValue(calendarDataQuery, (snapshot) => {
      const CalendarActivityData: CalendarActivityI | undefined = snapshot.val();

      if (CalendarActivityData) {
        setCalendarDatum(CalendarActivityData.data);
        setCalendarDatumEarliest(CalendarActivityData.earliestDate);
        setCalendarDatumLatest(CalendarActivityData.latestDate);
      }
    });
  };

  const calcTotalScorePerUser = () => {
    const totalPointsQuery: Query = query(
      ref(db, `chartData/totalPoints/${UiHelpersState.user?.uid}`)
    );

    onValue(totalPointsQuery, (snapshot) => {
      const data: number | undefined = snapshot.val();
      if (data) {
        usersState.setTotalScoreCounter(Number(data.toFixed(1)));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const calclast7DaysScore = () => {
    const last7DaysQuery: Query = query(
      ref(db, `chartData/last7DaysScore/${UiHelpersState.user?.uid}`)
    );

    onValue(last7DaysQuery, (snapshot) => {
      const sevenDaysData: number[] | undefined = snapshot.val();

      if (sevenDaysData) {
        const last7daysScoreSerie: Serie[] = [
          {
            id: "score",
            data:
              (sevenDaysData?.map((score: number, index: number) => {
                return {
                  x: index,
                  y: score,
                };
              }) as Datum[]) ?? [],
          },
        ];
        setLast7daysScore(last7daysScoreSerie);
      }
    });
  };

  const calcPerDayDatums = () => {
    const perDayQuery: Query = query(ref(db, `chartData/perDayAddedAndTotal`));

    onValue(perDayQuery, (snapshot) => {
      const data: Record<string, Record<string, Datum[]>> | undefined = snapshot.val();
      if (data) {
        setEntitiesAddedPerDay(data.perDay?.added);
        setEntitiesTotalPerDay(data.perDay?.total);
        setEntitiesAddedPerWeek(data.perWeek?.added);
        setEntitiesTotalPerWeek(data.perWeek?.total);
        setEntitiesAddedPerMonth(data.perMonth?.added);
        setEntitiesTotalPerMonth(data.perMonth?.total);
      }
    });
  };

  const calcPieDatum = () => {
    const pieDatumQuery: Query = query(ref(db, "chartData/perUserScore"));

    onValue(pieDatumQuery, (snapshot) => {
      const data: PieDatumI[] | undefined = snapshot.val();
      if (data) {
        setPieDatum(data);
      }
    });
  };

  const CalcWordsAndSyllables = () => {
    const wordCloudDataQuery: Query = query(ref(db, "chartData/wordCloud"));

    onValue(wordCloudDataQuery, (snapshot) => {
      const data: WordCloudDBDataI | undefined = snapshot.val();
      if (data) {
        setCloudWords(data.words);
        setCloudSyllables(data.syllables);
      }
    });
  };

  // useEffect
  useEffect(() => {
    // console.time("chart data calc time");
    if (entityState.entities?.length > 0) {
      calcTotalScorePerUser();
      calclast7DaysScore();
      CalcWordsAndSyllables();
      calcPerDayDatums();
      calcPieDatum();
      calcCalendarDatum();
      calcTagsRadialDatum();
    }
    // console.timeEnd("chart data calc time");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityState.entities]);

  return {
    calendarDatumEarliest,
    calendarDatumLatest,
    calendarDatum,
    entitiesAddedPerDay,
    entitiesTotalPerDay,
    entitiesAddedPerWeek,
    entitiesTotalPerWeek,
    entitiesAddedPerMonth,
    entitiesTotalPerMonth,
    totalPointsPerUser,
    last7daysScore,
    cloudWords,
    cloudSyllables,
    pieDatum,
    tagsRadialDatum,
  };
};
