import create from "zustand";
import { persist } from "zustand/middleware";
import { TagsStoreI } from "@/types";

export const useTagsStore = create<TagsStoreI>()(
  (set) =>
    ({
      activeTags: [],
      tagsFrequency: {},
      setTagsFrequency: (tagsFrequency) => set({ tagsFrequency }),
      availableTags: [],
      detectedTags: [],
      setDetectedTags: (tags: string[]) => set(() => ({ detectedTags: [...tags] })),
      removeAllDetectedTags: () => set(() => ({ detectedTags: [] })),
      addDetectedTag: (tag: string) =>
        set((prev) => ({ detectedTags: [...prev.detectedTags, tag] })),
      removeDetectedTag: (tag: string) =>
        set((prev) => ({ detectedTags: [...prev.detectedTags].filter((x) => x === tag) })),
      setAvailableTags: (tags: string[]) => set(() => ({ availableTags: [...tags] })),
      setTagAsActive: (tag: string) =>
        set((prev: TagsStoreI) => ({ activeTags: [...prev.activeTags, tag] })),
      removeAllActiveTags: () => set(() => ({ activeTags: [] })),
      removeTagAsActive: (tag: string) =>
        set((prev: TagsStoreI) => ({ activeTags: prev.activeTags.filter((x) => x !== tag) })),
    } as TagsStoreI)
);
