import "tippy.js/animations/scale.css";
import "tippy.js/dist/tippy.css";

import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { toast, ToastOptions } from "react-toastify";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
export const useNotifications = () => {
  const Container = (props: any) => <div>{props.children}</div>;

  const info = (val: string, options?: ToastOptions) =>
    toast(
      <Container>
        <Typography sx={{ color: "#0288d1", pl: 1 }}>{val}</Typography>
      </Container>,
      {
        icon: <InfoIcon sx={{ color: "#0288d1 !important" }} />,
        type: "info",
        ...options,
      }
    );
  const success = (val: string, options?: ToastOptions) =>
    toast(
      <Container>
        <Typography sx={{ color: "#2e7d32", pl: 1 }}>{val}</Typography>
      </Container>,
      {
        icon: <CheckCircleIcon sx={{ color: "#2e7d32 !important" }} />,
        type: "success",
        ...options,
      }
    );
  const error = (val: string, options?: ToastOptions) =>
    toast(
      <Container>
        <Typography sx={{ color: "#d32f2f", pl: 1 }}>{val}</Typography>
      </Container>,
      {
        icon: <ErrorIcon sx={{ color: "#d32f2f !important" }} />,
        type: "error",
        ...options,
      }
    );

  return { success, info, error };
};
